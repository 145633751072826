import { checkIsBusinessUser, getUserData } from "selectors/user";
import { createSelector } from "reselect";
import { getActiveUsersData } from "selectors/organizations";
import DataConstants from "const/DataConstants";
import moment from "moment";

const { TASK_STATUSES } = DataConstants;

const STATUSES_ORDER = [
  TASK_STATUSES.TO_DO,
  TASK_STATUSES.IN_PROGRESS,
  TASK_STATUSES.ON_HOLD,
  TASK_STATUSES.COMPLETED
];

export const getTasksRawData = ({ tasks: { data } }) => data.filter(({ type }) => !type || type === "simple");

export const getTasksDataWithTargetUser = createSelector(
  [
    getTasksRawData,
    getActiveUsersData,
    checkIsBusinessUser
  ],
  (data, users) => {
    return data.map(({ createdBy, targetUser, type, ...restData }) => {
      const createdByUserData = users.find(({ id }) => id === createdBy) || null;

      const targetUserData = users.find(({ id }) => id === targetUser) || null;

      return {
        ...restData,
        type,
        createdBy: createdByUserData,
        targetUser: targetUserData
      };
    });
  }
);

export const checkTasksFetching = ({ tasks: { fetchingData } }) => fetchingData;

export const getTasksData = createSelector(
  [getTasksDataWithTargetUser, getUserData],
  (tasks, user) => {
    return tasks.sort((
      { targetUser: targetUserA, status: statusA, dueDate: dueDateA = moment.unix(0), startDate: startDateA = moment.unix(0) },
      { targetUser: targetUserB, status: statusB, dueDate: dueDateB = moment.unix(0), startDate: startDateB = moment.unix(0) }
    ) => {
      return (
        (STATUSES_ORDER.indexOf(statusA) - STATUSES_ORDER.indexOf(statusB))
        || (Number(((targetUserB && targetUserB.id) || "") === user.id)
        - Number(((targetUserA && targetUserA.id) || "") === user.id))
        || (moment.utc(dueDateA).diff(moment.utc(dueDateB)))
        || (moment.utc(startDateA).diff(moment.utc(startDateB)))
      );
    });
  }
);

export const getActiveTasksData = createSelector(
  [
    getTasksData,
    getUserData,
    checkIsBusinessUser
  ],
  (tasks, user, businessUser) => {
    return tasks.filter(({ targetUser, status }) =>
      (status !== TASK_STATUSES.COMPLETED) && ((targetUser?.id === user.id) || (!targetUser && !businessUser)));
  }
);
