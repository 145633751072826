import Css from "./style.module.scss";

import React from "react";
import classNames from "classnames";

const Badge = ({ className, value, children, counter, ...restProps }) => (
  <span {...restProps} className={classNames(Css.badge, className, counter && Css.counter)}>
    {children || value}
  </span>
);

export default React.memo(Badge);
