import { createSelector } from "reselect";
import { getActiveOrganization } from "selectors/organizations";
import DataConstants from "const/DataConstants";

const { CONTACT_SUB_TYPES: { VENDOR, CUSTOMER } } = DataConstants;

export const checkContactsFetching = ({ contacts: { fetchingData } }) => fetchingData;

export const getVendorsTags = ({ contacts: { vendorsTags } }) => vendorsTags;

export const getVendorsCategories = ({ contacts: { vendorsCategories } }) => vendorsCategories;

export const getVendorsClasses = ({ contacts: { vendorsClasses } }) => vendorsClasses;

export const getVendorsLocations = ({ contacts: { vendorsLocations } }) => vendorsLocations;

export const getVendorsProjects = ({ contacts: { vendorsProjects } }) => vendorsProjects;

export const getVendorsTaxRates = ({ contacts: { vendorsTaxRates } }) => vendorsTaxRates;

export const getContactsData = ({ contacts: { data } }) => data;

export const getCustomersData = createSelector(
  getContactsData,
  (contacts) => contacts.filter(({ subType }) => subType === CUSTOMER)
);

export const getVendorsData = createSelector(
  getContactsData,
  (contacts) => contacts.filter(({ subType }) => subType === VENDOR)
);

export const getContactsSortedData = createSelector(
  getContactsData,
  (contacts) => {
    return [...contacts]
      .sort(({ name: nameA, subType: subTypeA = "" }, { name: nameB, subType: subTypeB = "" }) => {
        return subTypeA.localeCompare(subTypeB) || nameA.localeCompare(nameB);
      });
  }
);

export const getContactsSubTypeVendorsSortedData = createSelector(
  getContactsSortedData,
  (contacts) => contacts.filter(({ subType }) => !subType || subType === VENDOR)
);

export const getContactsSubTypeCustomerSortedData = createSelector(
  getContactsSortedData,
  (contacts) => contacts.filter(({ subType }) => !subType || subType === CUSTOMER)
);

export const getContactsUniqueCountries = createSelector(
  getContactsData,
  getActiveOrganization,
  (contacts, activeOrganization) => [
    ...new Set([...contacts.map(({ countryCode }) => countryCode), activeOrganization.countryCode])
  ]
);
