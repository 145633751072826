import CustomReportsActions from "actions/CustomReportsActions";

const {
  FETCH_CUSTOM_REPORTS_LIST_START,
  FETCH_CUSTOM_REPORTS_LIST_DONE,
  FETCH_CUSTOM_REPORT_START,
  FETCH_CUSTOM_REPORT_DONE,
  CREATE_CUSTOM_REPORT_START,
  CREATE_CUSTOM_REPORT_DONE,
  UPDATE_CUSTOM_REPORT_START,
  UPDATE_CUSTOM_REPORT_DONE,
  REMOVE_CUSTOM_REPORT_START,
  REMOVE_CUSTOM_REPORT_DONE
} = CustomReportsActions;

const initialState = {
  stats: {},
  data: []
};

export default (state = initialState, action) => {
  const { type, payload = {} } = action;

  switch (type) {
    case FETCH_CUSTOM_REPORTS_LIST_START: {
      const { clearList, backgroundUpdate } = payload;

      return {
        ...state,
        ...(clearList ? { stats: {}, data: [] } : {}),
        ...(backgroundUpdate ? {} : { fetchingData: true })
      };
    }
    case FETCH_CUSTOM_REPORT_START:
    case CREATE_CUSTOM_REPORT_START:
    case UPDATE_CUSTOM_REPORT_START:
    case REMOVE_CUSTOM_REPORT_START: {
      const { backgroundUpdate } = payload;

      return { ...state, ...(backgroundUpdate ? {} : { fetchingData: true }) };
    }

    case FETCH_CUSTOM_REPORTS_LIST_DONE: {
      const { data, stats } = payload;

      return { ...state, data, stats, fetchingData: false };
    }

    case FETCH_CUSTOM_REPORT_DONE: {
      return { ...state, fetchingData: false };
    }

    case CREATE_CUSTOM_REPORT_DONE: {
      const { report } = payload;

      return {
        ...state,
        data: [...state.data, report],
        fetchingData: false
      };
    }

    case UPDATE_CUSTOM_REPORT_DONE: {
      const { report } = payload;

      return {
        ...state,
        data: state.data.map((item) => item.id === report.id ? report : item),
        fetchingData: false
      };
    }

    case REMOVE_CUSTOM_REPORT_DONE: {
      const { reportId } = payload;

      return {
        ...state,
        data: state.data.filter((item) => item.id !== reportId),
        fetchingData: false
      };
    }

    default:
      return state;
  }
};
