import Css from "lib/common/TableNew/style.module.scss";

import React, { useCallback, useContext } from "react";
import TableCell from "lib/common/TableNew/lib/TableCell";
import TableContext from "lib/common/TableNew/TableContext";
import classNames from "classnames";

const SORT_ORDER_ASC = "asc";

const SORT_ORDER_DESC = "desc";

const TableHead = ({ children, className, accessor, hide = false, show = !hide, ...restProps }) => {
  const { sortBy, sortOrder, onSortChange } = useContext(TableContext);

  const handleClick = useCallback(() => {
    if (!accessor || !onSortChange) return;
    if (accessor !== sortBy) {
      onSortChange({ sortBy: accessor, sortOrder: SORT_ORDER_ASC });
    } else if (sortOrder === SORT_ORDER_ASC) {
      onSortChange({ sortBy: accessor, sortOrder: SORT_ORDER_DESC });
    } else {
      onSortChange({ sortBy: null, sortOrder: null });
    }
  }, [accessor, sortBy, sortOrder, onSortChange]);

  if (!show) return null;

  const active = sortBy && sortBy === accessor;

  return (
    <TableCell
      {...restProps}
      className={classNames(Css.tableHead, {
        [Css.sortable]: accessor && onSortChange,
        [Css.desc]: active && sortOrder === SORT_ORDER_DESC,
        [Css.active]: active
      }, className)}
      onClick={handleClick}>
      <div>{children}</div>
    </TableCell>
  );
};

export default TableHead;
