import { getTextsData } from "selectors/texts";
import { getUserData } from "selectors/user";
import { mainApi } from "api";
import { toast } from "react-toastify";
import MainApiRoutes from "const/MainApiRoutes";
import Utils from "utils/Utils";

export default class UserActions {
  static EDIT_USER_START = "user/EDIT_USER_START";

  static EDIT_USER_DONE = "user/EDIT_USER_DONE";

  static EDIT_USER_ERROR = "user/EDIT_USER_ERROR";

  static editUser(payload) {
    const {
      firstName,
      lastName,
      discoverySource,
      pushToken = null,
      backgroundUpdate = false,
      silentUpdate = false
    } = payload;

    return async(dispatch, getState) => {
      dispatch({ type: UserActions.EDIT_USER_START, payload: { backgroundUpdate } });

      const { messages, errors } = getTextsData(getState());

      const { id: userId, email } = getUserData(getState());

      const { USERS } = MainApiRoutes;

      const updatedUser = await mainApi.patch(
        `${USERS}/${userId}`,
        null,
        {
          ...(firstName ? { firstName } : {}),
          ...(lastName ? { lastName } : {}),
          ...(pushToken ? { pushToken } : {}),
          ...(discoverySource === undefined ? {} : { discoverySource })
        }
      );

      if (updatedUser.id) {
        dispatch({
          type: UserActions.EDIT_USER_DONE,
          payload: {
            ...updatedUser,
            ...(discoverySource === undefined ? {} : { discoverySourceAsked: true })
          }
        });
        if (!silentUpdate) toast.success(Utils.replaceTextVars(messages.userEdited, { email }));

        return updatedUser;
      }
      dispatch({ type: UserActions.EDIT_USER_ERROR });
      if (!silentUpdate) toast.error(Utils.replaceTextVars(errors.whileEditingUser, { email }));

      return null;
    };
  }

  static verifyEmail(userId) {
    return (dispatch, getState) => {
      const { messages, errors } = getTextsData(getState());

      const { MAIN_API_URL } = Utils.getProcessEnvVars();

      const { USERS } = MainApiRoutes;

      fetch(`${MAIN_API_URL}${USERS}/verify/${encodeURIComponent(userId)}`).then((response) => {
        if (response.ok) toast.success(messages.verificationEmailSent);
        else toast.error(errors.whileSendingVerificationEmail);
      });
    };
  }
}
