import Css from "lib/common/TableNew/style.module.scss";

import React from "react";
import classNames from "classnames";

const TableCell = ({ children, className, hide = false, show = !hide, ...restProps }) => {
  if (!show) return null;

  return (
    <div {...restProps} className={classNames(Css.tableCell, className)}>
      {children}
    </div>
  );
};

export default TableCell;
