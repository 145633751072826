import TextsActions from "actions/TextsActions";

const {
  SET_APP_LANGUAGE,
  FETCH_TEXTS_START,
  FETCH_TEXTS_DONE,
  FETCH_TEXTS_ERROR
} = TextsActions;

const initialState = {
  fetchingData: false,
  language: null,
  data: {}
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_APP_LANGUAGE:
      return { ...state, language: payload.language };

    case FETCH_TEXTS_START:
      return { ...state, fetchingData: true };

    case FETCH_TEXTS_DONE: {
      const { language, textsData } = payload;

      return { ...state, fetchingData: false, data: { ...state.data, [language]: textsData } };
    }
    case FETCH_TEXTS_ERROR:
      return { ...state, fetchingData: false };

    default:
      return state;
  }
};
