import AuditActions from "actions/AuditActions";
import CommentsActions from "actions/CommentsActions";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import Utils from "utils/Utils";

const { COMMENT_TARGET_TYPES } = DataConstants;

const {
  FETCH_AUDIT_LIST_START,
  FETCH_AUDIT_LIST_DONE,
  FETCH_AUDIT_LIST_ERROR,
  MARK_AS_REVISED_START,
  MARK_AS_REVISED_DONE,
  MARK_AS_REVISED_ERROR,
  DELETE_DUPLICATES_START,
  DELETE_DUPLICATES_DONE,
  DELETE_DUPLICATES_ERROR,
  UNUSUAL_BULK_UPDATE_START,
  UNUSUAL_BULK_UPDATE_DONE,
  UNUSUAL_BULK_UPDATE_ERROR,
  UNUSUAL_MARK_AS_REVISED_START,
  UNUSUAL_MARK_AS_REVISED_DONE,
  UNUSUAL_MARK_AS_REVISED_ERROR,
  SET_COMPANY_FILTERS,
  CLEAR_AUDIT_LIST
} = AuditActions;

const { MARK_ALL_AS_READ_DONE, ADD_NEW_COMMENT_START, FETCH_COMMENTS_DONE } = CommentsActions;

const initialState = {
  stats: {},
  data: [],
  filters: Utils.storageJsonValue(Constants.LS_KEYS.AUDIT_FILTERS) || {}
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_COMPANY_FILTERS: {
      const { selectedBusinessId, filters } = payload;

      const prev = state.filters[selectedBusinessId] || {};

      return {
        ...state,
        filters: {
          ...state.filters,
          [selectedBusinessId]: { ...prev, ...filters }
        }
      };
    }
    case MARK_AS_REVISED_START:
    case DELETE_DUPLICATES_START:
    case UNUSUAL_BULK_UPDATE_START:
    case UNUSUAL_MARK_AS_REVISED_START:
      return { ...state, fetchingData: true };

    case FETCH_AUDIT_LIST_START: {
      const { clearList, backgroundUpdate } = payload;

      return {
        ...state,
        ...((clearList || payload.currentRoute !== state.currentRoute)
          ? { data: [], dataHash: null } : {}),
        ...(clearList ? { stats: {} } : {}),
        ...(backgroundUpdate ? {} : { fetchingData: true })
      };
    }

    case FETCH_AUDIT_LIST_DONE: {
      const { dataHash, data, stats, count, backgroundUpdate, currentSection, currentRoute } = payload;

      return {
        ...state,
        count,
        currentSection,
        currentRoute,
        stats: { ...state.stats, [currentSection]: stats || {} },
        ...(!dataHash || dataHash !== state.dataHash ? {
          data,
          dataHash: dataHash || null
        } : {}),
        ...(backgroundUpdate ? {} : { fetchingData: false })
      };
    }

    case MARK_AS_REVISED_DONE:
      return { ...state, fetchingData: false };

    case CLEAR_AUDIT_LIST:
      return { ...state, dataHash: null, data: [] };

    case DELETE_DUPLICATES_DONE:
    case UNUSUAL_BULK_UPDATE_DONE:
    case UNUSUAL_MARK_AS_REVISED_DONE:
      return { ...state, fetchingData: false };

    case FETCH_AUDIT_LIST_ERROR:
    case MARK_AS_REVISED_ERROR:
    case DELETE_DUPLICATES_ERROR:
    case UNUSUAL_BULK_UPDATE_ERROR:
    case UNUSUAL_MARK_AS_REVISED_ERROR:
      return { ...state, fetchingData: false };

    case MARK_ALL_AS_READ_DONE:
      return {
        ...state,
        data: state.data.map((item) => ({
          ...item,
          comments: item.comments && { ...item.comments, unread: 0 }
        }))
      };

    case ADD_NEW_COMMENT_START: {
      const { targetType, targetId, text, createdBy } = payload;

      if (targetType !== COMMENT_TARGET_TYPES.AUDIT) return state;

      return {
        ...state,
        data: Utils.arrayUpdateItemById(state.data, targetId, (item) => {
          const { all = 0, ...rest } = item.comments || {};

          return {
            ...item,
            comments: { ...rest, all: all + 1 },
            lastComment: { text, createdBy }
          };
        })
      };
    }

    case FETCH_COMMENTS_DONE: {
      const { targetType, targetId } = payload;

      if (targetType !== COMMENT_TARGET_TYPES.AUDIT) return state;

      return {
        ...state,
        data: Utils.arrayUpdateItemById(state.data, targetId, (item) => {
          return { ...item, comments: { ...item.comments, unread: 0 } };
        })
      };
    }

    default:
      return state;
  }
};
