import CommonCss from "lib/common/style.module.scss";

import * as Yup from "yup";
import { Button, FormInput, FormSelect, InputGroup, InputGroupAddon, InputGroupText } from "shards-react";
import { FiAlertCircle } from "react-icons/fi";
import { getActiveOrganization } from "selectors/organizations";
import { getContactsData, getContactsUniqueCountries } from "selectors/contacts";
import { getCurrentXeroOrganizationId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import Constants from "const/Constants";
import ContactsActions from "actions/ContactsActions";
import Countries from "const/Countries";
import CountryStates from "const/CountryStates";
import DataConstants from "const/DataConstants";
import React, { useCallback, useEffect, useMemo, useReducer } from "react";
import Utils from "utils/Utils";
import moment from "moment";

const ACTION_TYPES = {
  INPUT_CHANGE: "inputChange",
  PREFILL: "prefill",
  RESET: "reset",
  UPDATE: "update"
};

const INPUT_FIELD_NAMES = {
  PREFILL_ID_NUMBER: "prefillIdNumber",
  TYPE: "type",
  SUB_TYPE: "subType",
  NAME: "name",
  ID_NUMBER: "idNumber",
  VAT_ID: "vatId",
  COUNTRY_CODE: "countryCode",
  STATE: "state",
  CITY: "city",
  STREET: "street",
  ZIP_CODE: "zipCode",
  PHONE: "phone",
  EMAIL: "email"
};

const EMAIL_VALIDATION_SCHEMA = Yup.string().trim().email();

const initState = ({ czCountry, contactData = {}, prefillData = {} }) => {
  return {
    type: contactData.type || (czCountry ? "" : DataConstants.CONTACT_TYPES.LEGAL_PERSON),
    subType: contactData.subType || prefillData.subType || "",
    countryCode: contactData.countryCode || prefillData.countryCode || "",
    name: contactData.name || prefillData.name || "",
    idNumber: contactData.idNumber || prefillData.idNumber || "",
    vatId: contactData.vatId || prefillData.vatId || "",
    phone: contactData.phone || prefillData.phone || "",
    email: contactData.email || prefillData.email || "",
    state: contactData.state || prefillData.state || "",
    city: contactData.city || prefillData.city || "",
    street: contactData.street || prefillData.street || "",
    zipCode: contactData.zipCode || prefillData.zipCode || "",
    prefillIdNumber: prefillData.idNumber || prefillData.vatId || ""
  };
};

const reducer = (state, { type, payload }) => {
  const { INPUT_CHANGE, PREFILL, RESET, UPDATE } = ACTION_TYPES;

  if (type === INPUT_CHANGE) {
    const [field, value] = payload;

    if (field === INPUT_FIELD_NAMES.VAT_ID) {
      return { ...state, vatId: value, type: state.type || DataConstants.CONTACT_TYPES.LEGAL_PERSON };
    }
    if (field === INPUT_FIELD_NAMES.COUNTRY_CODE) {
      return { ...state, countryCode: value, state: "" };
    }

    return { ...state, [field]: value };
  }
  if (type === PREFILL) return { ...state, ...payload };
  if (type === RESET) return Object.keys(state).reduce((aggregator, key) => ({ ...aggregator, [key]: "" }), {});
  if (type === UPDATE) return initState({ ...payload, contactData: state });

  return state;
};

const useContactBlock = ({ vendorId, prefillData, disabled, disabledSubTypeInput }) => {
  const dispatch = useDispatch();

  const { uiTexts, messages, countries } = useSelector(getTextsData);

  const activeOrganization = useSelector(getActiveOrganization);

  const recentCountries = useSelector(getContactsUniqueCountries);

  const contactsData = useSelector(getContactsData);

  const xeroBusiness = !!(useSelector(getCurrentXeroOrganizationId));

  const contactData = useMemo(() => {
    return vendorId && contactsData.find(({ id }) => id === vendorId);
  }, [vendorId, contactsData]);

  const [localState, localDispatch] = useReducer(reducer, { contactData, prefillData }, initState);

  const czCountry = activeOrganization.countryCode === Countries.CZ;

  const {
    prefillIdNumber,
    type,
    subType,
    name,
    idNumber,
    vatId,
    countryCode,
    state,
    city,
    street,
    zipCode,
    phone,
    email
  } = localState;

  const actions = useMemo(() => ({
    prefill: (newState) => localDispatch({ type: ACTION_TYPES.PREFILL, payload: newState }),
    reset: () => localDispatch({ type: ACTION_TYPES.RESET }),
    update: (payload) => localDispatch({ type: ACTION_TYPES.UPDATE, payload })
  }), []);

  const handlePrefillButtonClick = useCallback(async() => {
    const prefillResult = await dispatch(ContactsActions.lookupContactData(prefillIdNumber));

    if (prefillResult) actions.prefill({
      ...prefillResult,
      type: DataConstants.CONTACT_TYPES.LEGAL_PERSON,
      subType: (prefillResult.subType || "").toString(),
      countryCode: (prefillResult.countryCode || "").toString(),
      idNumber: (prefillResult.idNumber || "").toString(),
      vatId: (prefillResult.vatId || "").toString(),
      phone: (prefillResult.phone || "").toString(),
      zipCode: (prefillResult.zipCode || "").toString(),
      prefillIdNumber: ""
    });
  }, [prefillIdNumber, actions, dispatch]);

  const handleInputChange = useCallback(({ target: { name: fieldName, value } }) => {
    return localDispatch({ type: ACTION_TYPES.INPUT_CHANGE, payload: [fieldName, value] });
  }, []);

  const contactTypesList = useMemo(() => {
    const { LEGAL_PERSON, NATURAL_PERSON } = DataConstants.CONTACT_TYPES;

    return [
      { value: LEGAL_PERSON, label: uiTexts.legalPerson },
      { value: NATURAL_PERSON, label: uiTexts.naturalPerson }
    ];
  }, [uiTexts]);

  const contactSubTypesList = useMemo(() => {
    const { VENDOR, CUSTOMER } = DataConstants.CONTACT_SUB_TYPES;

    return [
      { value: VENDOR, label: uiTexts.vendor },
      { value: CUSTOMER, label: uiTexts.customer }
    ];
  }, [uiTexts]);

  const countriesOptions = useMemo(() => {
    const countriesList = Object.entries(countries);

    const renderOptions = (data) => {
      return data
        .sort(([, valueA], [, valueB]) => valueA.localeCompare(valueB))
        .map(([key, value]) => <option key={key} value={key}>{`${value} (${key})`}</option>);
    };

    const recentCountriesOptions = countriesList.filter(([key]) => recentCountries.includes(key));

    return (
      <>
        <optgroup label={uiTexts.recent}>
          {renderOptions(recentCountriesOptions)}
        </optgroup>
        <optgroup label={uiTexts.other}>
          {renderOptions(countriesList.filter(([key]) => !recentCountries.includes(key)))}
        </optgroup>
      </>
    );
  }, [uiTexts, countries, recentCountries]);

  const usStatesOptions = useMemo(() => {
    const statesList = Object.values(CountryStates.US);

    return statesList.map((value) => <option key={value} value={value}>{value}</option>);
  }, []);

  const warningMessage = useMemo(() => {
    if (contactData && (contactData.unreliableVatPayerSince || (contactData.vatId && contactData.vatPayer === false))) {
      return (
        <b>
          {contactData.unreliableVatPayerSince
            ? <>
              <FiAlertCircle className={CommonCss.warningText} />
              <span>
                {Utils.replaceTextVars(
                  messages.unreliableVatPayer,
                  {
                    vatId: contactData.vatId,
                    unreliableVatPayerSince: moment.utc(contactData.unreliableVatPayerSince)
                      .format(Constants.DATETIME_FORMATS.DATE_TEXT)
                  }
                )}
              </span>
            </>
            : <>
              <FiAlertCircle className={CommonCss.negativeText} />
              <span>{Utils.replaceTextVars(messages.invalidVatId, { vatId: contactData.vatId })}</span>
            </>}
        </b>
      );
    }

    return null;
  }, [messages, contactData]);

  const prefillInputGroup = useMemo(() => (
    <InputGroup disabled={disabled}>
      <InputGroupAddon type="prepend">
        <InputGroupText><span>{uiTexts.id}</span></InputGroupText>
      </InputGroupAddon>
      <FormInput
        name={INPUT_FIELD_NAMES.PREFILL_ID_NUMBER}
        placeholder={uiTexts.enterId}
        value={prefillIdNumber}
        onChange={handleInputChange} />
      <InputGroupAddon type="append">
        <Button size="sm" disabled={!prefillIdNumber} onClick={handlePrefillButtonClick}>
          {uiTexts.prefill}
        </Button>
      </InputGroupAddon>
    </InputGroup>
  ), [uiTexts, disabled, prefillIdNumber, handleInputChange, handlePrefillButtonClick]);

  const typeSelectGroup = useMemo(() => (
    <InputGroup>
      <InputGroupAddon type="prepend">
        <InputGroupText><span>{uiTexts.type}</span></InputGroupText>
      </InputGroupAddon>
      <FormSelect
        name={INPUT_FIELD_NAMES.TYPE}
        placeholder={type ? undefined : ""}
        value={type}
        invalid={!type}
        disabled={disabled}
        onChange={handleInputChange}>
        {!type && <option value="">{uiTexts.selectType}</option>}
        {contactTypesList.map(({ value, label }) => {
          return <option key={value} value={value}>{label}</option>;
        })}
      </FormSelect>
    </InputGroup>
  ), [uiTexts, type, disabled, contactTypesList, handleInputChange]);

  const subTypeSelectGroup = useMemo(() => (
    <InputGroup>
      <InputGroupAddon type="prepend">
        <InputGroupText><span>{uiTexts.type}</span></InputGroupText>
      </InputGroupAddon>
      <FormSelect
        name={INPUT_FIELD_NAMES.SUB_TYPE}
        placeholder={subType || xeroBusiness ? undefined : ""}
        value={subType}
        invalid={!subType && !xeroBusiness}
        disabled={disabled || disabledSubTypeInput || vendorId}
        onChange={handleInputChange}>
        {(!subType || xeroBusiness) && <option value="">
          {xeroBusiness
            ? `${uiTexts.all} (${uiTexts.vendor.toLowerCase()}/${uiTexts.customer.toLowerCase()})`
            : uiTexts.selectType}
        </option>}
        {contactSubTypesList.map(({ value, label }) => {
          return <option key={value} value={value}>{label}</option>;
        })}
      </FormSelect>
    </InputGroup>
  ), [
    uiTexts,
    subType,
    vendorId,
    disabled,
    disabledSubTypeInput,
    xeroBusiness,
    contactSubTypesList,
    handleInputChange
  ]);

  const nameInputGroup = useMemo(() => (
    <InputGroup>
      <InputGroupAddon type="prepend">
        <InputGroupText><span>{uiTexts.name}</span></InputGroupText>
      </InputGroupAddon>
      <FormInput
        name={INPUT_FIELD_NAMES.NAME}
        placeholder={uiTexts.enterContactName}
        value={name}
        invalid={!name}
        disabled={disabled}
        onChange={handleInputChange} />
    </InputGroup>
  ), [uiTexts, name, disabled, handleInputChange]);

  const idNumberInputGroup = useMemo(() => (
    <InputGroup>
      <InputGroupAddon type="prepend">
        <InputGroupText><span>{uiTexts.businessId}</span></InputGroupText>
      </InputGroupAddon>
      <FormInput
        name={INPUT_FIELD_NAMES.ID_NUMBER}
        placeholder={uiTexts.enterBusinessId}
        value={idNumber}
        disabled={disabled}
        onChange={handleInputChange} />
    </InputGroup>
  ), [uiTexts, idNumber, disabled, handleInputChange]);

  const vatIdInputGroup = useMemo(() => (
    <InputGroup>
      <InputGroupAddon type="prepend">
        <InputGroupText><span>{uiTexts.vatId}</span></InputGroupText>
      </InputGroupAddon>
      <FormInput
        name={INPUT_FIELD_NAMES.VAT_ID}
        placeholder={uiTexts.enterVatId}
        value={vatId}
        invalid={!!(vatId && contactData && contactData.vatPayer === false)}
        disabled={disabled}
        onChange={handleInputChange} />
    </InputGroup>
  ), [uiTexts, vatId, disabled, contactData, handleInputChange]);

  const countrySelectGroup = useMemo(() => (
    <InputGroup>
      <InputGroupAddon type="prepend">
        <InputGroupText><span>{uiTexts.country}</span></InputGroupText>
      </InputGroupAddon>
      <FormSelect
        name={INPUT_FIELD_NAMES.COUNTRY_CODE}
        placeholder={countryCode ? undefined : ""}
        value={countryCode}
        disabled={disabled}
        onChange={handleInputChange}>
        {!countryCode && <option value="">{uiTexts.selectCountry}</option>}
        {countriesOptions}
      </FormSelect>
    </InputGroup>
  ), [uiTexts, countryCode, disabled, countriesOptions, handleInputChange]);

  const stateSelectGroup = useMemo(() => (
    <InputGroup>
      <InputGroupAddon type="prepend">
        <InputGroupText><span>{uiTexts.state}</span></InputGroupText>
      </InputGroupAddon>
      {countryCode === Countries.US
        ? <FormSelect
          name={INPUT_FIELD_NAMES.STATE}
          placeholder={state ? undefined : ""}
          value={state}
          disabled={disabled}
          onChange={handleInputChange}>
          {!state && <option value="">{uiTexts.selectState}</option>}
          {usStatesOptions}
        </FormSelect>
        : <FormInput
          name={INPUT_FIELD_NAMES.STATE}
          placeholder={uiTexts.enterStateName}
          value={state}
          disabled={disabled}
          onChange={handleInputChange} />}
    </InputGroup>
  ), [uiTexts, countryCode, state, disabled, usStatesOptions, handleInputChange]);

  const cityInputGroup = useMemo(() => (
    <InputGroup>
      <InputGroupAddon type="prepend">
        <InputGroupText><span>{uiTexts.city}</span></InputGroupText>
      </InputGroupAddon>
      <FormInput
        name={INPUT_FIELD_NAMES.CITY}
        placeholder={uiTexts.enterCityName}
        value={city}
        disabled={disabled}
        onChange={handleInputChange} />
    </InputGroup>
  ), [uiTexts, city, disabled, handleInputChange]);

  const streetInputGroup = useMemo(() => (
    <InputGroup>
      <InputGroupAddon type="prepend">
        <InputGroupText><span>{uiTexts.street}</span></InputGroupText>
      </InputGroupAddon>
      <FormInput
        name={INPUT_FIELD_NAMES.STREET}
        placeholder={uiTexts.enterStreetName}
        value={street}
        disabled={disabled}
        onChange={handleInputChange} />
    </InputGroup>
  ), [uiTexts, street, disabled, handleInputChange]);

  const zipCodeInputGroup = useMemo(() => (
    <InputGroup>
      <InputGroupAddon type="prepend">
        <InputGroupText><span>{uiTexts.zipCode}</span></InputGroupText>
      </InputGroupAddon>
      <FormInput
        name={INPUT_FIELD_NAMES.ZIP_CODE}
        placeholder={uiTexts.enterZipCode}
        value={zipCode}
        disabled={disabled}
        onChange={handleInputChange} />
    </InputGroup>
  ), [uiTexts, zipCode, disabled, handleInputChange]);

  const phoneInputGroup = useMemo(() => (
    <InputGroup>
      <InputGroupAddon type="prepend">
        <InputGroupText><span>{uiTexts.phone}</span></InputGroupText>
      </InputGroupAddon>
      <FormInput
        name={INPUT_FIELD_NAMES.PHONE}
        placeholder={uiTexts.enterPhoneNumber}
        value={phone}
        disabled={disabled}
        onChange={handleInputChange} />
    </InputGroup>
  ), [uiTexts, phone, disabled, handleInputChange]);

  const emailInputGroup = useMemo(() => (
    <InputGroup>
      <InputGroupAddon type="prepend">
        <InputGroupText><span>{uiTexts.email}</span></InputGroupText>
      </InputGroupAddon>
      <FormInput
        name={INPUT_FIELD_NAMES.EMAIL}
        placeholder={uiTexts.enterEmail}
        value={email}
        invalid={!EMAIL_VALIDATION_SCHEMA.isValidSync(email)}
        disabled={disabled}
        onChange={handleInputChange} />
    </InputGroup>
  ), [uiTexts, email, disabled, handleInputChange]);

  useEffect(() => {
    actions.update({ contactData, prefillData, czCountry });
  }, [contactData, prefillData, czCountry, actions]);

  return {
    actions,
    state: localState,
    ui: {
      warningMessage,
      prefillInputGroup,
      nameInputGroup,
      idNumberInputGroup,
      vatIdInputGroup,
      countrySelectGroup,
      stateSelectGroup,
      cityInputGroup,
      streetInputGroup,
      zipCodeInputGroup,
      phoneInputGroup,
      emailInputGroup,
      typeSelectGroup: czCountry ? typeSelectGroup : subTypeSelectGroup
    }
  };
};

export default useContactBlock;
