import * as ConnectedReactRouter from "connected-react-router";

export default () => {
  let lastPathname;

  return (next) => (action) => {
    const { type, payload } = action;

    if (type === ConnectedReactRouter.LOCATION_CHANGE) {
      const { location, location: { pathname } } = payload;

      const newAction = {
        ...action,
        payload: {
          ...payload,
          location: {
            ...location,
            lastPathname
          }
        }
      };

      lastPathname = pathname;

      return next(newAction);
    }

    return next(action);
  };
};
