import CommonCss from "lib/common/style.module.scss";

import Css from "./style.module.scss";

import { Button } from "shards-react";
import { FiAlertTriangle, FiCreditCard, FiRefreshCw } from "react-icons/fi";
import { getLinkExpirationsInfoData } from "selectors/institutions";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import AutoTooltip from "lib/common/AutoTooltip";
import Constants from "const/Constants";
import ModalWindow from "lib/common/ModalWindow";
import React, { useCallback, useMemo } from "react";
import TableNew, { TableCell, TableHead, TableRow } from "lib/common/TableNew";
import classNames from "classnames";
import moment from "moment";

const ReconnectAccountModal = ({ onClose }) => {
  const { uiTexts, messages } = useSelector(getTextsData);

  const linkExpirationsInfoData = useSelector(getLinkExpirationsInfoData);

  const modalWindowConfig = useMemo(() => ({
    headerText: uiTexts.bankConnectionIssues,
    okButtonText: uiTexts.add
  }), [uiTexts]);

  const handleButtonClick = useCallback(({ currentTarget: { dataset: { id } } }) => {
    onClose(id);
  }, [onClose]);

  return (
    <ModalWindow
      className={Css.reconnectAccountModal}
      bodyClassName={Css.bodyClassName}
      iconComponent={FiCreditCard}
      config={modalWindowConfig}
      onClose={onClose}>
      <p>
        <FiAlertTriangle className={CommonCss.warningText} />
        <span>{messages.institutionsReconnectWindowMessage}</span>
      </p>
      <TableNew theme="flat">
        <TableRow>
          <TableHead className={Css.statusCell}>{uiTexts.status}</TableHead>
          <TableHead className={Css.businessNameCell}>{uiTexts.businessName}</TableHead>
          <TableHead className={Css.bankNameCell}>{uiTexts.bankName}</TableHead>
          <TableHead className={Css.actionCell}>{uiTexts.actions}</TableHead>
        </TableRow>
        {linkExpirationsInfoData.map((data) => {
          const { linkId, expired, disconnected, institutionName, businessName, expiresAt } = data;

          return (
            <TableRow key={linkId}>
              <TableCell className={Css.statusCell}>
                <div className={classNames(Css.text, (disconnected || expired) && Css.negative)}>
                  {expired ? uiTexts.expired.toUpperCase()
                    : (disconnected ? uiTexts.disconnected.toUpperCase()
                      : `${uiTexts.expires}: ${moment.utc(expiresAt).format(Constants.DATETIME_FORMATS.DATE_TEXT)}`)}
                </div>
              </TableCell>
              <TableCell className={Css.businessNameCell}>
                <AutoTooltip>{businessName}</AutoTooltip>
              </TableCell>
              <TableCell className={Css.bankNameCell}>
                <AutoTooltip>{institutionName}</AutoTooltip>
              </TableCell>
              <TableCell className={Css.actionCell}>
                <Button
                  size="sm"
                  theme={(expired || disconnected) ? "danger" : "warning"}
                  data-id={linkId}
                  title={expired || disconnected ? uiTexts.reconnect : uiTexts.renew}
                  className={Css.reconnectButton}
                  onClick={handleButtonClick}>
                  <FiRefreshCw />
                </Button>
              </TableCell>
            </TableRow>
          );
        })}
      </TableNew>
    </ModalWindow>
  );
};

export default React.memo(ReconnectAccountModal);
