import { getModalWindowConfig } from "selectors/ui";
import { useSelector } from "react-redux";
import AppModal from "./lib/AppModal";
import Portal from "nlib/ui/Portal";
import React from "react";

const ModalContainer = () => {
  const modalWindowConfig = useSelector(getModalWindowConfig);

  return modalWindowConfig ? (
    <Portal>
      <AppModal {...modalWindowConfig} />
    </Portal>
  ) : null;
};

export default React.memo(ModalContainer);
