import { getActiveOrganization } from "selectors/organizations";
import { getAllBusinessesData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { mainApi } from "api";
import { toast } from "react-toastify";
import BusinessesActions from "actions/BusinessesActions";
import IntegrationServices from "const/IntegrationServices";
import MainApiRoutes from "const/MainApiRoutes";
import UiActions from "actions/UiActions";
import Utils from "utils/Utils";

export default class IntegrationsActions {
  static DOWNLOAD_QBWC_START = "integrations/DOWNLOAD_QBWC_START";

  static DOWNLOAD_QBWC_DONE = "integrations/DOWNLOAD_QBWC_DONE";

  static DOWNLOAD_QBWC_ERROR = "integrations/DOWNLOAD_QBWC_ERROR";

  static BUILD_CONSENT_URL_START = "integrations/BUILD_CONSENT_URL_START";

  static BUILD_CONSENT_URL_DONE = "integrations/BUILD_CONSENT_URL_DONE";

  static BUILD_CONSENT_URL_ERROR = "integrations/BUILD_CONSENT_URL_ERROR";

  static INTEGRATE_BUSINESSES_START = "integrations/INTEGRATE_BUSINESSES_START";

  static INTEGRATE_BUSINESSES_DONE = "integrations/INTEGRATE_BUSINESSES_DONE";

  static INTEGRATE_BUSINESSES_ERROR = "integrations/INTEGRATE_BUSINESSES_ERROR";

  static SYNC_ACCOUNTS_START = "integrations/SYNC_ACCOUNTS_START";

  static SYNC_ACCOUNTS_DONE = "integrations/SYNC_ACCOUNTS_DONE";

  static SYNC_ACCOUNTS_ERROR = "integrations/SYNC_ACCOUNTS_ERROR";

  static SYNC_CLASSES_START = "integrations/SYNC_CLASSES_START";

  static SYNC_CLASSES_DONE = "integrations/SYNC_CLASSES_DONE";

  static SYNC_CLASSES_ERROR = "integrations/SYNC_CLASSES_ERROR";

  static SYNC_ITEMS_START = "integrations/SYNC_ITEMS_START";

  static SYNC_ITEMS_DONE = "integrations/SYNC_ITEMS_DONE";

  static SYNC_ITEMS_ERROR = "integrations/SYNC_ITEMS_ERROR";

  static SYNC_TAX_RATES_START = "integrations/SYNC_TAX_RATES_START";

  static SYNC_TAX_RATES_DONE = "integrations/SYNC_TAX_RATES_DONE";

  static SYNC_TAX_RATES_ERROR = "integrations/SYNC_TAX_RATES_ERROR";

  static SYNC_LOCATIONS_START = "integrations/SYNC_LOCATIONS_START";

  static SYNC_LOCATIONS_DONE = "integrations/SYNC_LOCATIONS_DONE";

  static SYNC_LOCATIONS_ERROR = "integrations/SYNC_LOCATIONS_ERROR";

  static SYNC_CONTACTS_START = "integrations/SYNC_CONTACTS_START";

  static SYNC_CONTACTS_DONE = "integrations/SYNC_CONTACTS_DONE";

  static SYNC_CONTACTS_ERROR = "integrations/SYNC_CONTACTS_ERROR";

  static SYNC_PROJECTS_START = "integrations/SYNC_PROJECTS_START";

  static SYNC_PROJECTS_DONE = "integrations/SYNC_PROJECTS_DONE";

  static SYNC_PROJECTS_ERROR = "integrations/SYNC_PROJECTS_ERROR";

  static SYNC_TRANSACTIONS_START = "integrations/SYNC_TRANSACTIONS_START";

  static SYNC_TRANSACTIONS_DONE = "integrations/SYNC_TRANSACTIONS_DONE";

  static SYNC_TRANSACTIONS_ERROR = "integrations/SYNC_TRANSACTIONS_ERROR";

  static downloadQbwcFile() {
    return async(dispatch, getState) => {
      dispatch({ type: IntegrationsActions.DOWNLOAD_QBWC_START });

      const { INTEGRATIONS, DOWNLOAD } = MainApiRoutes;

      const { errors } = getTextsData(getState());

      const path = `${INTEGRATIONS}/${IntegrationServices.QUICK_BOOKS_DESKTOP.value}${DOWNLOAD}/qbwc`;

      const { link } = await mainApi.get(path);

      if (link) {
        Utils.downloadContent(link);
        dispatch({ type: IntegrationsActions.DOWNLOAD_QBWC_DONE });

        return true;
      }

      dispatch({ type: IntegrationsActions.DOWNLOAD_QBWC_ERROR });
      toast.error(errors.whileDownloadingFile);

      return false;
    };
  }

  static buildConsentUrl(integrationService) {
    return async(dispatch, getState) => {
      dispatch({ type: IntegrationsActions.BUILD_CONSENT_URL_START, payload: {} });

      const { INTEGRATIONS, CONSENT_URL } = MainApiRoutes;

      const { DEFAULT_APP_URL } = Utils.getProcessEnvVars();

      const { errors } = getTextsData(getState());

      const redirectUrl = Utils.checkIsDevMode() ? window.location.origin : DEFAULT_APP_URL;

      const integration = await mainApi.post(
        `${INTEGRATIONS}/${integrationService}${CONSENT_URL}`,
        null,
        { redirectUrl }
      );

      if (integration.consentUrl) {
        dispatch({ type: IntegrationsActions.BUILD_CONSENT_URL_DONE, payload: { consentUrl: integration.consentUrl } });

        return integration.consentUrl;
      }
      dispatch({ type: IntegrationsActions.BUILD_CONSENT_URL_ERROR });
      toast.error(errors.whileBuildingConsentUrl);

      return null;
    };
  }

  static integrateBusinesses(integrationService, showList = false, selectedItems = null, extraParams = {}, silentUpdate = true) {
    return async(dispatch, getState) => {
      dispatch({ type: IntegrationsActions.INTEGRATE_BUSINESSES_START, payload: {} });

      const { INTEGRATIONS, INTEGRATE } = MainApiRoutes;

      const { DEFAULT_APP_URL } = Utils.getProcessEnvVars();

      const { uiTexts, messages, errors } = getTextsData(getState());

      const { limits = {} } = getActiveOrganization(getState());

      const businessesData = getAllBusinessesData(getState());

      const callbackUrl = window.location.href;

      const redirectUrl = Utils.checkIsDevMode() ? window.origin : DEFAULT_APP_URL;

      const { results: businesses, failedBusinesses, ...restResponseData } = await mainApi.post(
        `${INTEGRATIONS}/${integrationService}${INTEGRATE}`,
        null,
        { showList, selectedItems, callbackUrl, redirectUrl, ...extraParams }
      );

      if (Array.isArray(businesses) && Array.isArray(failedBusinesses)) {
        dispatch({ type: IntegrationsActions.INTEGRATE_BUSINESSES_DONE, payload: { businesses: showList ? [] : businesses } });
        if (!showList) {
          if (businesses.length) {
            const totalCount = businesses.length + failedBusinesses.length;

            dispatch(BusinessesActions.selectBusiness(businesses[businesses.length - 1].id));
            if (!silentUpdate) {
              if (totalCount === 1) toast.success(messages.businessIntegrated);
              else {
                toast.success(
                  Utils.replaceTextVars(
                    messages.businessesIntegrated,
                    { successCount: businesses.length, totalCount }
                  )
                );
              }
            }
          } else if (!silentUpdate) {
            toast.info(messages.businessesNotAdded);
          }
          if (failedBusinesses.length) {
            const failedBusinessNamesString = failedBusinesses
              .map((businessName, index) => `${index + 1}. ${businessName}`).join("\n");

            const serviceBusinessesData = businessesData.filter((businessData) => {
              return businessData.extraData && businessData.extraData.integrationService === integrationService;
            });

            const businessesLimitExceeded = limits[integrationService]
              && serviceBusinessesData.length >= limits[integrationService];

            const serviceName = Utils.capitalizeText(integrationService);

            const errorDetails = businessesLimitExceeded
              ? Utils.replaceTextVars(
                errors.integrationLimitExceeded,
                { limit: limits[integrationService], serviceName }
              )
              : (`${Utils.replaceTextVars(errors.requiredDataMissing, { serviceName })}\n\n${failedBusinessNamesString}`);

            await dispatch(UiActions.showModal(
              `${errors.whileIntegratingBusinesses}.\n\n${errorDetails}`,
              uiTexts.error,
              false,
              null
            ));
          }
        }

        return { businesses, ...restResponseData };
      }
      dispatch({ type: IntegrationsActions.INTEGRATE_BUSINESSES_ERROR });
      if (!showList) toast.error(errors.whileIntegratingBusinesses);

      return {};
    };
  }

  static syncAccounts(integrationService, businessIds, backgroundUpdate = false, silentUpdate = false, fullSync = false) {
    return async(dispatch, getState) => {
      dispatch({ type: IntegrationsActions.SYNC_ACCOUNTS_START, payload: { backgroundUpdate } });

      const { INTEGRATIONS, SYNC_ACCOUNTS } = MainApiRoutes;

      const { messages } = getTextsData(getState());

      const { ok } = await mainApi.post(
        `${INTEGRATIONS}/${integrationService}${SYNC_ACCOUNTS}`, null, { businessIds, fullSync }
      );

      if (ok) {
        dispatch({ type: IntegrationsActions.SYNC_ACCOUNTS_DONE });
        if (!silentUpdate) toast.success(messages.accountsSynced);

        return true;
      }
      dispatch({ type: IntegrationsActions.SYNC_ACCOUNTS_ERROR });

      return false;
    };
  }

  static syncClasses(integrationService, businessIds, backgroundUpdate = false, silentUpdate = false, fullSync = false) {
    return async(dispatch, getState) => {
      dispatch({ type: IntegrationsActions.SYNC_CLASSES_START, payload: { backgroundUpdate } });

      const { INTEGRATIONS, SYNC_CLASSES } = MainApiRoutes;

      const { messages } = getTextsData(getState());

      const { ok } = await mainApi.post(
        `${INTEGRATIONS}/${integrationService}${SYNC_CLASSES}`, null, { businessIds, fullSync }
      );

      if (ok) {
        dispatch({ type: IntegrationsActions.SYNC_CLASSES_DONE });
        if (!silentUpdate) toast.success(messages.classesSynced);

        return true;
      }
      dispatch({ type: IntegrationsActions.SYNC_CLASSES_ERROR });

      return false;
    };
  }

  static syncItems(integrationService, businessIds, backgroundUpdate = false, silentUpdate = false, fullSync = false) {
    return async(dispatch, getState) => {
      dispatch({ type: IntegrationsActions.SYNC_ITEMS_START, payload: { backgroundUpdate } });

      const { INTEGRATIONS, SYNC_ITEMS } = MainApiRoutes;

      const { messages } = getTextsData(getState());

      const { ok } = await mainApi.post(
        `${INTEGRATIONS}/${integrationService}${SYNC_ITEMS}`, null, { businessIds, fullSync }
      );

      if (ok) {
        dispatch({ type: IntegrationsActions.SYNC_ITEMS_DONE });
        if (!silentUpdate) toast.success(messages.itemsSynced);

        return true;
      }
      dispatch({ type: IntegrationsActions.SYNC_ITEMS_ERROR });

      return false;
    };
  }

  static syncTaxRates(integrationService, businessIds, backgroundUpdate = false, silentUpdate = false, fullSync = false) {
    return async(dispatch, getState) => {
      dispatch({ type: IntegrationsActions.SYNC_TAX_RATES_START, payload: { backgroundUpdate } });

      const { INTEGRATIONS, SYNC_TAX_RATES } = MainApiRoutes;

      const { messages } = getTextsData(getState());

      const { ok } = await mainApi.post(
        `${INTEGRATIONS}/${integrationService}${SYNC_TAX_RATES}`, null, { businessIds, fullSync }
      );

      if (ok) {
        dispatch({ type: IntegrationsActions.SYNC_TAX_RATES_DONE });
        if (!silentUpdate) toast.success(messages.taxRatesSynced);

        return true;
      }
      dispatch({ type: IntegrationsActions.SYNC_TAX_RATES_ERROR });

      return false;
    };
  }

  static syncLocations(integrationService, businessIds, backgroundUpdate = false, silentUpdate = false, fullSync = false) {
    return async(dispatch, getState) => {
      dispatch({ type: IntegrationsActions.SYNC_LOCATIONS_START, payload: { backgroundUpdate } });

      const { INTEGRATIONS, SYNC_LOCATIONS } = MainApiRoutes;

      const { messages } = getTextsData(getState());

      const { ok } = await mainApi.post(
        `${INTEGRATIONS}/${integrationService}${SYNC_LOCATIONS}`, null, { businessIds, fullSync }
      );

      if (ok) {
        dispatch({ type: IntegrationsActions.SYNC_LOCATIONS_DONE });
        if (!silentUpdate) toast.success(messages.locactionsSynced);

        return true;
      }
      dispatch({ type: IntegrationsActions.SYNC_LOCATIONS_ERROR });

      return false;
    };
  }

  static syncContacts(integrationService, businessIds, backgroundUpdate = false, silentUpdate = false, fullSync = false) {
    return async(dispatch, getState) => {
      dispatch({ type: IntegrationsActions.SYNC_CONTACTS_START, payload: { backgroundUpdate } });

      const { INTEGRATIONS, SYNC_VENDORS } = MainApiRoutes;

      const { messages } = getTextsData(getState());

      const { ok } = await mainApi.post(
        `${INTEGRATIONS}/${integrationService}${SYNC_VENDORS}`, null, { businessIds, fullSync }
      );

      if (ok) {
        dispatch({ type: IntegrationsActions.SYNC_CONTACTS_DONE });
        if (!silentUpdate) {
          toast.success(integrationService === IntegrationServices.QUICK_BOOKS.value
            ? messages.contactsAndProjectsSynced
            : messages.contactsSynced);
        }

        return true;
      }
      dispatch({ type: IntegrationsActions.SYNC_CONTACTS_ERROR });

      return false;
    };
  }

  static syncProjects(integrationService, businessIds, backgroundUpdate = false, silentUpdate = false, fullSync = false) {
    return async(dispatch, getState) => {
      dispatch({ type: IntegrationsActions.SYNC_PROJECTS_START, payload: { backgroundUpdate } });

      const { INTEGRATIONS, SYNC_PROJECTS } = MainApiRoutes;

      const { messages } = getTextsData(getState());

      const { ok } = await mainApi.post(
        `${INTEGRATIONS}/${integrationService}${SYNC_PROJECTS}`, null, { businessIds, fullSync }
      );

      if (ok) {
        dispatch({ type: IntegrationsActions.SYNC_PROJECTS_DONE });
        if (!silentUpdate) toast.success(messages.projectsSynced);

        return true;
      }
      dispatch({ type: IntegrationsActions.SYNC_PROJECTS_ERROR });

      return false;
    };
  }

  static syncTransactions(integrationService, businessIds, backgroundUpdate = false, silentUpdate = false, fullSync = false) {
    return async(dispatch, getState) => {
      dispatch({ type: IntegrationsActions.SYNC_TRANSACTIONS_START, payload: { backgroundUpdate } });

      const { INTEGRATIONS, SYNC_TRANSACTIONS } = MainApiRoutes;

      const { messages } = getTextsData(getState());

      const { ok } = await mainApi.post(
        `${INTEGRATIONS}/${integrationService}${SYNC_TRANSACTIONS}`, null, { businessIds, fullSync }
      );

      if (ok) {
        dispatch({ type: IntegrationsActions.SYNC_TRANSACTIONS_DONE });
        if (!silentUpdate) toast.success(messages.transactionsSynced);

        return true;
      }
      dispatch({ type: IntegrationsActions.SYNC_TRANSACTIONS_ERROR });

      return false;
    };
  }
}
