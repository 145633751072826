import Css from "./style.module.scss";

import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "shards-react";
import { FiCheckSquare, FiSquare } from "react-icons/fi";
import { bind } from "decko";
import React, { PureComponent, useEffect, useRef } from "react";
import Utils from "utils/Utils";
import classNames from "classnames";

const ADDON_TYPES = {
  PREPEND: "prepend",
  APPEND: "append"
};

const HALF_DIVIDER = 2;

const DropdownMenuCustom = (props) => {
  const {
    noItemsTitle,
    right,
    dropup,
    prepend,
    options,
    selectedValue,
    addonType,
    multiple,
    selectedIndexes,
    disableSelected,
    firstSelectedIndex,
    clickEventName,
    menuClassName,
    itemClassName,
    onItemClick
  } = props;

  const wrapperRef = useRef();

  useEffect(() => {
    const activeItem = wrapperRef.current.querySelector(".active");

    if (!activeItem) return;

    const containerRect = wrapperRef.current.getBoundingClientRect();

    const itemRect = activeItem.getBoundingClientRect();

    if ((containerRect.top + containerRect.height - itemRect.height) < itemRect.top) {
      wrapperRef.current.scrollTop = (itemRect.top + (itemRect.height / HALF_DIVIDER))
        - containerRect.top - (containerRect.height / HALF_DIVIDER);
    }
  }, []);

  return (
    <DropdownMenu
      right={right || addonType === ADDON_TYPES.APPEND}
      className={classNames(Css.menu, menuClassName, dropup && Css.dropUpMenu)}>
      {prepend}
      <div ref={wrapperRef}>
        {options.map(({ value, label, divider, disabled: disabledItem, title, hidden }, index) => {
          const key = (value || "") + index;

          if (hidden) return null;

          if (divider) return <DropdownItem key={key} divider />;

          const active = selectedValue === value;

          return (
            <DropdownItem
              key={key}
              data-index={index}
              data-multiple={multiple ? "" : undefined}
              toggle={!multiple}
              title={noItemsTitle ? undefined : title}
              disabled={disabledItem || (!multiple && disableSelected && index === firstSelectedIndex)}
              className={classNames(Css.item, !multiple && active && Css.active, active && "active", itemClassName)}
              {...{ [clickEventName]: onItemClick }}>
              {multiple
                ? <>{selectedIndexes.includes(index) ? <FiCheckSquare /> : <FiSquare />}<span>{label}</span></>
                : label}
            </DropdownItem>
          );
        })}
      </div>
    </DropdownMenu>
  );
};

export default class DropDownSelect extends PureComponent {
  constructor(props) {
    super(props);

    const { multiple, initialValue, options } = this.props;

    const selectedIndex = initialValue === undefined ? 0 : options.findIndex(({ value }) => value === initialValue);

    this.state = {
      selectedIndexes: multiple ? [] : [selectedIndex > 0 ? selectedIndex : 0],
      expanded: false
    };
  }

  get controlled() {
    return this.props.expanded !== undefined;
  }

  get expanded() {
    return this.controlled ? this.props.expanded : this.state.expanded;
  }

  @bind
  toggleSelect() {
    const { onToggleState } = this.props;

    if (this.controlled && !onToggleState) return;

    this.setState(
      (prevState) => ({ expanded: !prevState.expanded }),
      () => {
        if (onToggleState) onToggleState(this.state.expanded);
      }
    );
  }

  @bind
  handleItemClick(event) {
    let { currentTarget: { dataset: { index } } } = event;

    const { multiple, options, onChange } = this.props;

    index = +index;
    if (multiple) {
      this.setState(({ selectedIndexes }) => {
        if (selectedIndexes.includes(index)) selectedIndexes = selectedIndexes.filter((selectedIndex) => index !== selectedIndex);
        else selectedIndexes = [...selectedIndexes, index];

        return { selectedIndexes };
      }, () => {
        onChange(this.state.selectedIndexes.map((selectedIndex) => options[selectedIndex].value), event);
      });
    } else {
      const selectedItem = options[index];

      if (selectedItem.unselectable) onChange(selectedItem.value, event);
      else {
        this.setState({ selectedIndexes: [index] }, () => {
          onChange(selectedItem.value, event);
        });
      }
    }
  }

  render() {
    let {
      noItemsTitle,
      options,
      disabled,
      size,
      theme,
      right,
      dropup,
      split,
      caret,
      nav,
      multiple,
      outline,
      mouseDown,
      placeholder,
      addonType,
      selectedValue,
      disableSelected,
      toggleContent,
      toggleClassName,
      menuClassName,
      itemClassName,
      className,
      prepend,
      toggleProps,
      ...restProps
    } = this.props;

    const { selectedIndexes } = this.state;

    const [firstSelectedIndex] = selectedIndexes;

    const dataProps = Object.fromEntries(Object.entries(restProps).filter(([key]) => key.indexOf("data-") === 0));

    const clickEventName = Utils.checkIsTouchDevice() || !mouseDown ? "onClick" : "onMouseDown";

    if (!toggleContent) {
      if (options) {
        if (multiple) {
          if (!selectedIndexes.length) toggleContent = placeholder;
          else if (selectedIndexes.length === 1) toggleContent = options[firstSelectedIndex].label;
          else toggleContent = `${selectedIndexes.length} / ${options.length}`;
        } else {
          toggleContent = options[firstSelectedIndex].label;
        }
      }
    }

    return (
      <Dropdown
        {...dataProps}
        open={this.expanded}
        dropup={dropup}
        group={split}
        addonType={addonType}
        className={classNames(Css.dropDownSelect, className)}
        toggle={this.toggleSelect}>
        {!!split && toggleContent}
        <DropdownToggle
          {...(split ? {} : toggleProps)}
          data-caret={caret ? "" : undefined}
          data-split={split ? "" : undefined}
          disabled={disabled}
          size={size}
          theme={theme}
          outline={outline}
          nav={nav}
          className={classNames(Css.toggle, toggleClassName)}>
          {!split && <span>{toggleContent}</span>}
        </DropdownToggle>
        {this.expanded && !!options.length && (
          <DropdownMenuCustom
            noItemsTitle={noItemsTitle}
            right={right}
            dropup={dropup}
            prepend={prepend}
            options={options}
            selectedValue={selectedValue}
            addonType={addonType}
            multiple={multiple}
            selectedIndexes={selectedIndexes}
            disableSelected={disableSelected}
            firstSelectedIndex={firstSelectedIndex}
            clickEventName={clickEventName}
            menuClassName={menuClassName}
            itemClassName={itemClassName}
            onItemClick={this.handleItemClick} />
        )}
      </Dropdown>
    );
  }
}
