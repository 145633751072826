import { createSelector } from "reselect";
import { getActiveUsersData } from "selectors/organizations";
import { getAllBusinessesData } from "selectors/businesses";

export const checkCommentsFetching = ({ comments: { fetchingData } }) => fetchingData;

export const checkUnreadCommentsFetching = ({ comments: { fetchingUnread } }) => fetchingUnread;

export const getCommentsTargetData = ({ comments: { targetData } }) => targetData;

export const getCommentsData = createSelector(
  [
    ({ comments: { data } }) => data,
    getActiveUsersData
  ],
  (data, users) => {
    return data.map(({ createdBy, createdByEmail, ...restData }) => {
      let user = users.find(({ id }) => id === createdBy) || null;

      if (!user && createdByEmail) user = { email: createdByEmail };

      return { ...restData, createdByEmail, createdBy: user };
    });
  }
);

export const getUnreadCommentsData = createSelector(
  [
    ({ comments: { unread } }) => unread,
    getAllBusinessesData,
    getActiveUsersData
  ],
  (data, businesses, users) => {
    return data.map(({ businessId, createdBy, createdByEmail, ...restData }) => {
      const business = businesses.find(({ id }) => id === businessId) || { name: null };

      let user = users.find(({ id }) => id === createdBy) || null;

      if (!user && createdByEmail) user = { email: createdByEmail };

      return { ...restData, businessId, businessName: business.name, createdByEmail, createdBy: user };
    });
  }
);
