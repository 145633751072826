import { getActiveOrganization } from "selectors/organizations";
import { getLanguage, getTextsData } from "selectors/texts";
import { getSelectedBusinessId } from "selectors/businesses";
import { mainApi } from "api";
import { toast } from "react-toastify";
import MainApiRoutes from "const/MainApiRoutes";
import UiActions from "./UiActions";

export default class InstitutionsActions {
  static FETCH_INSTITUTIONS_LIST_START = "institutions/FETCH_INSTITUTIONS_LIST_START";

  static FETCH_INSTITUTIONS_LIST_DONE = "institutions/FETCH_INSTITUTIONS_LIST_DONE";

  static FETCH_INSTITUTIONS_LIST_ERROR = "institutions/FETCH_INSTITUTIONS_LIST_ERROR";

  static FETCH_INSTITUTIONS_ACCOUNTS_LIST_START = "institutions/FETCH_INSTITUTIONS_ACCOUNTS_LIST_START";

  static FETCH_INSTITUTIONS_ACCOUNTS_LIST_DONE = "institutions/FETCH_INSTITUTIONS_ACCOUNTS_LIST_DONE";

  static FETCH_INSTITUTIONS_ACCOUNTS_LIST_ERROR = "institutions/FETCH_INSTITUTIONS_ACCOUNTS_LIST_ERROR";

  static GET_CONNECTING_LINK_START = "institutions/GET_CONNECTING_LINK_START";

  static GET_CONNECTING_LINK_DONE = "institutions/GET_CONNECTING_LINK_DONE";

  static GET_CONNECTING_LINK_ERROR = "institutions/GET_CONNECTING_LINK_ERROR";

  static COMPLETE_SETUP_START = "institutions/COMPLETE_SETUP_START";

  static COMPLETE_SETUP_DONE = "institutions/COMPLETE_SETUP_DONE";

  static COMPLETE_SETUP_ERROR = "institutions/COMPLETE_SETUP_ERROR";

  static DELETE_INSTITUTION_ACCOUNT_START = "institutions/DELETE_INSTITUTION_ACCOUNT_START";

  static DELETE_INSTITUTION_ACCOUNT_DONE = "institutions/DELETE_INSTITUTION_ACCOUNT_DONE";

  static DELETE_INSTITUTION_ACCOUNT_ERROR = "institutions/DELETE_INSTITUTION_ACCOUNT_ERROR";

  static FETCH_INSTITUTION_LINKS_START = "institutions/FETCH_INSTITUTION_LINKS_START";

  static FETCH_INSTITUTION_LINKS_DONE = "institutions/FETCH_INSTITUTION_LINKS_DONE";

  static FETCH_INSTITUTION_LINKS_ERROR = "institutions/FETCH_INSTITUTION_LINKS_ERROR";

  static FETCH_LINKS_EXPIRATION_INFO_START = "institutions/FETCH_LINKS_EXPIRATION_INFO_START";

  static FETCH_LINKS_EXPIRATION_INFO_DONE = "institutions/FETCH_LINKS_EXPIRATION_INFO_DONE";

  static FETCH_LINKS_EXPIRATION_INFO_ERROR = "institutions/FETCH_LINKS_EXPIRATION_INFO_ERROR";

  static getConnectingLink(institutionCode, businessId) {
    return async(dispatch, getState) => {
      dispatch(UiActions.togglePreloaderOverlay(true));
      dispatch({ type: InstitutionsActions.GET_CONNECTING_LINK_START });

      const { BUSINESSES, INSTITUTIONS, CONNECTION_INIT } = MainApiRoutes;

      const locale = getLanguage(getState());

      const selectedBusinessId = getSelectedBusinessId(getState());

      const { linkUrl = "" } = await mainApi.post(
        `${BUSINESSES}/${businessId || selectedBusinessId}${INSTITUTIONS}${CONNECTION_INIT}/${institutionCode}`,
        null,
        {
          locale,
          redirectUrl: window.location.origin
        }
      );

      dispatch(UiActions.togglePreloaderOverlay(false));

      if (linkUrl.length) {
        dispatch({ type: InstitutionsActions.GET_CONNECTING_LINK_DONE });

        return linkUrl;
      }
      dispatch({ type: InstitutionsActions.GET_CONNECTING_LINK_ERROR });

      return null;
    };
  }

  static completeSetup(referenceId) {
    return async(dispatch, getState) => {
      dispatch({ type: InstitutionsActions.COMPLETE_SETUP_START });

      const { BUSINESSES, INSTITUTIONS, CONNECTION_COMPLETE } = MainApiRoutes;

      const selectedBusinessId = getSelectedBusinessId(getState());

      const { ok } = await mainApi.post(
        `${BUSINESSES}/${selectedBusinessId}${INSTITUTIONS}${CONNECTION_COMPLETE}/${referenceId}`
      );

      if (ok) {
        await Promise.all([
          dispatch(InstitutionsActions.fetchInstitutionLinks()),
          dispatch(InstitutionsActions.fetchInstitutionAccounts()),
          dispatch(InstitutionsActions.fetchLinksExpirationInfo())
        ]);
        dispatch({ type: InstitutionsActions.COMPLETE_SETUP_DONE });

        return true;
      }
      dispatch({ type: InstitutionsActions.COMPLETE_SETUP_ERROR });

      return false;
    };
  }

  static fetchInstitutionsList() {
    return async(dispatch, getState) => {
      dispatch({ type: InstitutionsActions.FETCH_INSTITUTIONS_LIST_START });

      const { INSTITUTIONS } = MainApiRoutes;

      const { errors } = getTextsData(getState());

      const activeOrganization = getActiveOrganization(getState());

      const { results: data } = await mainApi.get(INSTITUTIONS, { countryCode: activeOrganization.countryCode });

      if (Array.isArray(data) && data.length) {
        dispatch({ type: InstitutionsActions.FETCH_INSTITUTIONS_LIST_DONE, payload: data });

        return data;
      }
      dispatch({ type: InstitutionsActions.FETCH_INSTITUTIONS_LIST_ERROR });
      toast.error(errors.whileLoadingBanks);

      return null;
    };
  }

  static fetchInstitutionLinks(clearList, backgroundUpdate) {
    return async(dispatch, getState) => {
      dispatch({
        type: InstitutionsActions.FETCH_INSTITUTION_LINKS_START,
        payload: { clearList, backgroundUpdate }
      });

      const { BUSINESSES, INSTITUTIONS, LINKS } = MainApiRoutes;

      const { errors } = getTextsData(getState());

      const selectedBusinessId = getSelectedBusinessId(getState());

      const { results: links } = await mainApi.get(`${BUSINESSES}/${selectedBusinessId}${INSTITUTIONS}${LINKS}`);

      if (Array.isArray(links)) {
        dispatch({ type: InstitutionsActions.FETCH_INSTITUTION_LINKS_DONE, payload: links });

        return links;
      }

      dispatch({ type: InstitutionsActions.FETCH_INSTITUTION_LINKS_ERROR });
      toast.error(errors.whileLoadingLinks);

      return null;
    };
  }

  static fetchInstitutionAccounts(clearList, backgroundUpdate) {
    return async(dispatch, getState) => {
      dispatch({
        type: InstitutionsActions.FETCH_INSTITUTIONS_ACCOUNTS_LIST_START,
        payload: { clearList, backgroundUpdate }
      });

      const { BUSINESSES, INSTITUTIONS, ACCOUNTS } = MainApiRoutes;

      const { errors } = getTextsData(getState());

      const selectedBusinessId = getSelectedBusinessId(getState());

      const { results: accounts } = await mainApi.get(`${BUSINESSES}/${selectedBusinessId}${INSTITUTIONS}${ACCOUNTS}`);

      if (Array.isArray(accounts)) {
        dispatch({ type: InstitutionsActions.FETCH_INSTITUTIONS_ACCOUNTS_LIST_DONE, payload: accounts });

        return accounts;
      }
      dispatch({ type: InstitutionsActions.FETCH_INSTITUTIONS_ACCOUNTS_LIST_ERROR });
      toast.error(errors.whileLoadingAccounts);

      return null;
    };
  }

  static fetchLinksExpirationInfo() {
    return async(dispatch, getState) => {
      dispatch({ type: InstitutionsActions.FETCH_LINKS_EXPIRATION_INFO_START });

      const { LINKS, INSTITUTIONS, EXPIRATION_INFO } = MainApiRoutes;

      const { errors } = getTextsData(getState());

      const { results } = await mainApi.get(`${INSTITUTIONS}${LINKS}${EXPIRATION_INFO}`);

      if (Array.isArray(results)) {
        dispatch({ type: InstitutionsActions.FETCH_LINKS_EXPIRATION_INFO_DONE, payload: results });

        return results;
      }
      dispatch({ type: InstitutionsActions.FETCH_LINKS_EXPIRATION_INFO_ERROR });
      toast.error(errors.whileLoadingExpirationInfo);

      return null;
    };
  }

  static deleteInstitutionAccount(accountId) {
    return async(dispatch, getState) => {
      dispatch({ type: InstitutionsActions.DELETE_INSTITUTION_ACCOUNT_START });

      const { INSTITUTIONS, ACCOUNTS } = MainApiRoutes;

      const { errors } = getTextsData(getState());

      const response = await mainApi.delete(`${INSTITUTIONS}${ACCOUNTS}/${accountId}`);

      if (response) {
        dispatch({ type: InstitutionsActions.DELETE_INSTITUTION_ACCOUNT_DONE, payload: { accountId } });

        return true;
      }
      dispatch({ type: InstitutionsActions.DELETE_INSTITUTION_ACCOUNT_ERROR });
      toast.error(errors.whileDeletingAccount);

      return false;
    };
  }
}
