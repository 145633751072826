import { getTextsData } from "selectors/texts";
import { mainApi } from "api";
import { toast } from "react-toastify";
import MainApiRoutes from "const/MainApiRoutes";

export default class EventsActions {
  static FETCH_EVENTS_BY_TYPE_START = "businesses/FETCH_EVENTS_BY_TYPE_START";

  static FETCH_EVENTS_BY_TYPE_DONE = "businesses/FETCH_EVENTS_BY_TYPE_DONE";

  static FETCH_EVENTS_BY_TYPE_ERROR = "businesses/FETCH_EVENTS_BY_TYPE_ERROR";

  static fetchEventsByType(type, itemId) {
    return async(dispatch, getState) => {
      dispatch({ type: EventsActions.FETCH_EVENTS_BY_TYPE_START, payload: { type, itemId } });

      const { errors } = getTextsData(getState());

      const { results: events } = await mainApi.get(`${MainApiRoutes.EVENTS}/${type}/${itemId}`);

      if (Array.isArray(events)) {
        dispatch({ type: EventsActions.FETCH_EVENTS_BY_TYPE_DONE, payload: { type, events, itemId } });

        return events;
      }
      dispatch({ type: EventsActions.FETCH_EVENTS_BY_TYPE_ERROR });
      toast.error(errors.whileLoadingEvents);

      return null;
    };
  }

  static fetchEventsByRoute(route, itemId) {
    return EventsActions.fetchEventsByType(route.substr(1), itemId);
  }
}
