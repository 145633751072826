export default class Async {
  static async runInSequence(tasks) {
    const result = await tasks.reduce(
      (promiseChain, currentTask) => {
        return promiseChain.then(async(chainResults) => {
          const currentResult = await currentTask();

          return [...chainResults, currentResult];
        });
      },
      Promise.resolve([])
    );

    return result;
  }

  static async delay(time) {
    await new Promise((resolve) => {
      setTimeout(resolve, time);
    });
  }

  static async waitForever() {
    await new Promise(() => {});
  }

  static async loopWhile(task, condition = true) {
    if (condition) {
      condition = await task();
      await Async.loopWhile(task, condition);
    }
  }
}
