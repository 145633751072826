import UiActions from "actions/UiActions";

export default () => {
  let modalResolvers = [];

  let customModalResolvers = [];

  return (next) => (action) => {
    const { type, payload, meta } = action;

    switch (type) {
      case UiActions.SHOW_MODAL: {
        const newAction = {
          ...action,
          payload: {
            ...payload
          }
        };

        modalResolvers = [
          ...modalResolvers,
          meta.modalResolver
        ];

        return next(newAction);
      }
      case UiActions.SHOW_CUSTOM_MODAL: {
        const newAction = {
          ...action,
          payload: {
            ...payload
          }
        };

        customModalResolvers = [
          ...customModalResolvers,
          meta.modalResolver
        ];

        return next(newAction);
      }
      case UiActions.HIDE_MODAL: {
        const modalResolver = modalResolvers.pop();

        if (modalResolver) {
          modalResolver(payload.modalResult);
        }
        break;
      }
      case UiActions.HIDE_CUSTOM_MODAL: {
        const modalResolver = customModalResolvers.pop();

        if (modalResolver) {
          modalResolver(payload.modalResult);
        }
        break;
      }
    }

    return next(action);
  };
};
