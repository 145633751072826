// Import reducers
import accounts from "reducers/accounts";
import activity from "reducers/activity";
import archive from "reducers/archive";
import audit from "reducers/audit";
import businesses from "reducers/businesses";
import comments from "reducers/comments";
import contacts from "reducers/contacts";
import customReports from "reducers/customReports";
import dashboard from "reducers/dashboard";
import documents from "reducers/documents";
import envVars from "reducers/envVars";
import events from "reducers/events";
import institutions from "reducers/institutions";
import integrations from "reducers/integrations";
import metaData from "reducers/metaData";
import organizations from "reducers/organizations";
import preAccounting from "reducers/preAccounting";
import reports from "reducers/reports";
import robotic from "reducers/robotic";
import stripe from "reducers/stripe";
import summary from "reducers/summary";
import tasks from "reducers/tasks";
import texts from "reducers/texts";
import transactions from "reducers/transactions";
import ui from "reducers/ui";
import user from "reducers/user";
import vatClassification from "reducers/vatClassification";

import * as ConnectedReactRouter from "connected-react-router";
import * as Redux from "redux";
import * as ReduxDevToolsExt from "redux-devtools-extension";
import Constants from "const/Constants";
import Utils from "utils/Utils";
import lastPathnameMiddleware from "middlewares/lastPathname";
import localStorageMiddleware from "middlewares/localStorage";
import modalResolverMiddleware from "middlewares/modalResolver";
import thunkMiddleware from "redux-thunk";

let store = null;

const getRootReducer = (history) => {
  const router = ConnectedReactRouter.connectRouter(history);

  return Redux.combineReducers({
    accounts,
    activity,
    archive,
    audit,
    businesses,
    comments,
    contacts,
    customReports,
    dashboard,
    documents,
    envVars,
    events,
    institutions,
    integrations,
    metaData,
    organizations,
    preAccounting,
    reports,
    robotic,
    router,
    stripe,
    summary,
    tasks,
    texts,
    transactions,
    ui,
    vatClassification,
    user
  });
};

export const getStore = () => {
  return store;
};

export const getState = () => {
  if (!store) return null;

  return store.getState();
};

export const createStore = ({ history }) => {
  const routerMiddleware = ConnectedReactRouter.routerMiddleware(history);

  const middlewareEnhancer = Redux.applyMiddleware(
    thunkMiddleware,
    routerMiddleware,
    modalResolverMiddleware,
    lastPathnameMiddleware,
    localStorageMiddleware
  );

  store = Redux.createStore(
    getRootReducer(history),
    {},
    Utils.checkIsProductionEnv() && !Utils.checkIsDevMode()
      ? middlewareEnhancer
      : ReduxDevToolsExt.composeWithDevTools({
        serialize: true,
        trace: true,
        traceLimit: Constants.REDUX_DEV_TOOLS_TRACE_LIMIT
      })(middlewareEnhancer)
  );

  return store;
};

export default {
  getStore,
  getState,
  createStore
};
