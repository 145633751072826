export default class BrowserEvents {
  static CLICK = "click";

  static MESSAGE = "message";

  static RESIZE = "resize";

  static SCROLL = "scroll";

  static ORIENTATION_CHANGE = "orientationchange";

  static FULLSCREEN_CHANGE = "fullscreenchange";

  static FOCUS = "focus";

  static BLUR = "blur";

  static CHANGE = "change";

  static STATE_CHANGE = "statechange";

  static ACTIVATED = "activated";

  static INPUT = "input";

  static KEY_UP = "keyup";

  static KEY_DOWN = "keydown";

  static MOUSE_UP = "mouseup";

  static MOUSE_DOWN = "mousedown";

  static BEFORE_UNLOAD = "beforeunload";
}
