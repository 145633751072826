import IntegrationsActions from "actions/IntegrationsActions";

const {
  DOWNLOAD_QBWC_START,
  BUILD_CONSENT_URL_START,
  INTEGRATE_BUSINESSES_START,
  SYNC_ACCOUNTS_START,
  SYNC_CLASSES_START,
  SYNC_ITEMS_START,
  SYNC_TAX_RATES_START,
  SYNC_LOCATIONS_START,
  SYNC_CONTACTS_START,
  SYNC_PROJECTS_START,
  SYNC_TRANSACTIONS_START,
  DOWNLOAD_QBWC_DONE,
  BUILD_CONSENT_URL_DONE,
  INTEGRATE_BUSINESSES_DONE,
  SYNC_ACCOUNTS_DONE,
  SYNC_CLASSES_DONE,
  SYNC_ITEMS_DONE,
  SYNC_TAX_RATES_DONE,
  SYNC_LOCATIONS_DONE,
  SYNC_CONTACTS_DONE,
  SYNC_PROJECTS_DONE,
  SYNC_TRANSACTIONS_DONE,
  DOWNLOAD_QBWC_ERROR,
  BUILD_CONSENT_URL_ERROR,
  INTEGRATE_BUSINESSES_ERROR,
  SYNC_ACCOUNTS_ERROR,
  SYNC_CLASSES_ERROR,
  SYNC_ITEMS_ERROR,
  SYNC_TAX_RATES_ERROR,
  SYNC_LOCATIONS_ERROR,
  SYNC_CONTACTS_ERROR,
  SYNC_PROJECTS_ERROR,
  SYNC_TRANSACTIONS_ERROR
} = IntegrationsActions;

const initialState = {
  fetchingData: false,
  fetchingIntegrations: false
};

export default (state = initialState, action) => {
  const { type, payload = {} } = action;

  switch (type) {
    case DOWNLOAD_QBWC_START:
    case BUILD_CONSENT_URL_START:
    case INTEGRATE_BUSINESSES_START:
    case SYNC_ACCOUNTS_START:
    case SYNC_CLASSES_START:
    case SYNC_ITEMS_START:
    case SYNC_TAX_RATES_START:
    case SYNC_LOCATIONS_START:
    case SYNC_CONTACTS_START:
    case SYNC_PROJECTS_START:
    case SYNC_TRANSACTIONS_START:
      return { ...state, fetchingIntegrations: true, fetchingData: !payload.backgroundUpdate };

    case DOWNLOAD_QBWC_DONE:
    case BUILD_CONSENT_URL_DONE:
    case INTEGRATE_BUSINESSES_DONE:
    case SYNC_ACCOUNTS_DONE:
    case SYNC_CLASSES_DONE:
    case SYNC_ITEMS_DONE:
    case SYNC_TAX_RATES_DONE:
    case SYNC_LOCATIONS_DONE:
    case SYNC_CONTACTS_DONE:
    case SYNC_PROJECTS_DONE:
    case SYNC_TRANSACTIONS_DONE:
    case DOWNLOAD_QBWC_ERROR:
    case BUILD_CONSENT_URL_ERROR:
    case INTEGRATE_BUSINESSES_ERROR:
    case SYNC_ACCOUNTS_ERROR:
    case SYNC_CLASSES_ERROR:
    case SYNC_ITEMS_ERROR:
    case SYNC_TAX_RATES_ERROR:
    case SYNC_LOCATIONS_ERROR:
    case SYNC_CONTACTS_ERROR:
    case SYNC_PROJECTS_ERROR:
    case SYNC_TRANSACTIONS_ERROR:
      return { ...state, fetchingIntegrations: false, fetchingData: false };

    default:
      return state;
  }
};
