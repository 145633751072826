import CommonCss from "lib/common/style.module.scss";

import Css from "./style.module.scss";

import { Button, FormTextarea } from "shards-react";
import { FiMessageCircle } from "react-icons/fi";
import { checkCommentsFetching, getCommentsData } from "selectors/comments";
import { checkSelectedBusinessHasBusinessUsers, getSelectedBusinessUsersData } from "selectors/businesses";
import { getActiveOrganization } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import { usePreviousValue } from "hooks";
import AutoCompleteInput from "lib/common/AutoCompleteInput";
import Avatar from "lib/common/Avatar";
import Comment from "./lib/Comment";
import CommentsActions from "actions/CommentsActions";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import Preloader from "lib/common/Preloader";
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import UserRoles from "const/UserRoles";
import Utils from "utils/Utils";
import classNames from "classnames";

const { MENTION_TRIGGER_CHAR } = Constants;

const {
  COMMENT_TYPES: { COMMENT, NOTE },
  COMMENT_TARGET_TYPES: { AUDIT }
} = DataConstants;

const messageDraft = {
  [COMMENT]: "",
  [NOTE]: ""
};

const CommentsBlock = ({ className, type: targetType, id: targetId, comments, onAskClient }) => {
  const dispatch = useDispatch();

  const { uiTexts, messages } = useSelector(getTextsData);

  const fetchingComments = useSelector(checkCommentsFetching);

  const commentsData = useSelector(getCommentsData);

  const usersData = useSelector(getSelectedBusinessUsersData);

  const selectedBusinessHasBusinessUsers = useSelector(checkSelectedBusinessHasBusinessUsers);

  const { businessOrganization } = useSelector(getActiveOrganization);

  const [dataLoaded, setDataLoaded] = useState(false);

  const [newComment, setNewComment] = useState({
    type: targetType === AUDIT ? NOTE : COMMENT,
    text: messageDraft[COMMENT]
  });

  const [inputFocused, setInputFocused] = useState(false);

  const inputRef = useRef();

  const commentsBlockRef = useRef();

  const inputBlockRef = useRef();

  const { all: allCommentsCount, unread: unreadCommentsCount } = comments || {};

  const previousAllCommentsCount = usePreviousValue(allCommentsCount);

  const previousUnreadCommentsCount = usePreviousValue(unreadCommentsCount);

  const reversedCommentsData = useMemo(() => [...commentsData].reverse(), [commentsData]);

  const emptyText = !newComment.text.trim();

  const askClientAvailable = !businessOrganization || selectedBusinessHasBusinessUsers;

  const autoCompleteOptions = useMemo(() => {
    return usersData
      .filter((user) => newComment.type === COMMENT || UserRoles.checkIsAccountant(user.role))
      .map(({ email, fullName }) => {
        const userName = fullName || email || uiTexts.unknown;

        return {
          value: email + (fullName === email ? "" : ` (${fullName})`),
          label: <div className={Css.label}>
            <Avatar className={Css.avatar} name={userName} />
            <span>{fullName}</span>
            {fullName !== email && (<span>{email}</span>)}
          </div>
        };
      });
  }, [newComment.type, uiTexts.unknown, usersData]);

  const usersEmails = useMemo(() => {
    return autoCompleteOptions.map(({ value }) => value);
  }, [autoCompleteOptions]);

  const addNewComment = useCallback((dontSetState) => {
    const mentioned = [...new Set(newComment.text.match(new RegExp(`${MENTION_TRIGGER_CHAR}\\S*`, "g")) || [])]
      .map((mention) => {
        const found = usersData.find(({ email }) => email === mention.slice(1));

        if (!found || (newComment.type === NOTE && !UserRoles.checkIsAccountant(found.role))) return null;

        return found.sub;
      })
      .filter(Boolean);

    messageDraft[newComment.type] = "";

    dispatch(
      CommentsActions.addNewComment(newComment.type, newComment.text.trim(), mentioned)
    );

    if (!dontSetState) setNewComment({ type: newComment.type, text: "" });
  }, [dispatch, newComment.text, newComment.type, usersData]);

  const scrollDown = useCallback(() => {
    const commentsBlockElement = commentsBlockRef.current;

    const addCommentRowElement = inputBlockRef.current;

    const commentElements = document.getElementsByClassName(Css.comment);

    const lastCommentElement = commentElements[commentElements.length - 1];

    if (lastCommentElement) {
      const conditionA = commentsBlockElement.scrollHeight > commentsBlockElement.clientHeight;

      const conditionB = lastCommentElement.getBoundingClientRect().bottom > addCommentRowElement.getBoundingClientRect().top;

      if (conditionA || conditionB) {
        commentsBlockElement.dataset.scrollable = "";
        commentsBlockElement.scrollTop = commentsBlockElement.scrollHeight;
      } else delete commentsBlockElement.dataset.scrollable;
    }
  }, []);

  const handleKeyDown = useCallback((event) => {
    if (event.key !== "Enter" || event.shiftKey || event.ctrlKey) return;
    event.preventDefault();
    addNewComment();
  }, [addNewComment]);

  const handleAddCommentButtonClick = useCallback(() => {
    addNewComment();
  }, [addNewComment]);

  const handleAddCommentAndAskButtonClick = useCallback(() => {
    addNewComment(true);
    onAskClient();
  }, [addNewComment, onAskClient]);

  const handleCommentTextChange = useCallback(({ target: { value } }) => {
    setNewComment((previousValue) => {
      messageDraft[previousValue.type] = value;

      return { ...previousValue, text: value };
    });
  }, []);

  const handleUserMention = useCallback((mention) => {
    setNewComment((previousValue) => {
      const text = `${previousValue.text.trim()} ${mention} `;

      messageDraft[previousValue.type] = text;

      return { ...previousValue, text };
    });
  }, []);

  const handleTypeSelectorClick = useCallback((event) => {
    const { type: nextType } = event.target.dataset;

    setNewComment((previousValue) => ({ ...previousValue, type: nextType, text: messageDraft[nextType] }));
  }, []);

  const handleInputBlockClick = useCallback((event) => {
    if (event.target === event.currentTarget) {
      inputRef.current.focus();
    }
  }, []);

  const handleToggleInputFocus = useCallback((focused) => {
    setInputFocused(focused);
  }, []);

  useEffect(() => {
    dispatch(CommentsActions.fetchComments(targetType, targetId, true)).then(() => {
      setDataLoaded(true);
    });

    return () => {
      dispatch(CommentsActions.fetchUnreadComments());
    };
  }, [targetId, targetType, dispatch]);

  useEffect(() => {
    if (unreadCommentsCount !== previousUnreadCommentsCount && unreadCommentsCount > 0) {
      dispatch(CommentsActions.fetchComments(targetType, targetId));
    }
  }, [targetId, targetType, unreadCommentsCount, previousUnreadCommentsCount, dispatch]);

  useEffect(() => {
    if (allCommentsCount < previousAllCommentsCount) {
      dispatch(CommentsActions.fetchComments(targetType, targetId));
    }
  }, [targetId, targetType, allCommentsCount, previousAllCommentsCount, dispatch]);

  useLayoutEffect(() => {
    if (dataLoaded && reversedCommentsData.length) {
      scrollDown();
    }
  }, [dataLoaded, reversedCommentsData, scrollDown]);

  return (
    <div className={classNames(Css.commentsBlock, newComment.type === NOTE && Css.typeNote, className)}>
      {(fetchingComments && reversedCommentsData.length === 0) || !dataLoaded
        ? <Preloader />
        : (
          <>
            {reversedCommentsData.length
              ? (
                <div className={Css.comments} ref={commentsBlockRef}>
                  {reversedCommentsData.map((comment) => (
                    <Comment
                      key={comment.id}
                      disabled={fetchingComments}
                      className={Css.comment}
                      comment={comment}
                      usersEmails={usersEmails}
                      onUserMention={handleUserMention} />
                  ))}
                </div>
              )
              : (
                <div className={classNames(CommonCss.noDataContent, Css.noContent)}>
                  <div>
                    <div><FiMessageCircle /></div>
                    <div>{uiTexts.noComments}</div>
                  </div>
                </div>
              )}
            <div
              ref={inputBlockRef}
              className={classNames(Css.input, inputFocused && Css.focused)}
              onClick={handleInputBlockClick}>
              {askClientAvailable && (
                <div className={Css.typeSelector} onClick={handleTypeSelectorClick}>
                  {targetType !== AUDIT && (
                    <div className={newComment.type === COMMENT ? Css.active : undefined} data-type={COMMENT}>
                      {uiTexts.reply}
                    </div>
                  )}
                  <div className={newComment.type === NOTE ? Css.active : undefined} data-type={NOTE}>{uiTexts.note}</div>
                </div>
              )}
              <AutoCompleteInput
                dropup
                autoFocus
                selectOnFocus={false}
                id="input"
                ref={inputRef}
                component={FormTextarea}
                triggerChar={MENTION_TRIGGER_CHAR}
                className={Css.autoCompleteInput}
                placeholder={Utils.replaceTextVars(
                  newComment.type === COMMENT ? messages.commentReplyPlaceholder : messages.commentNotePlaceholder,
                  { triggerChar: MENTION_TRIGGER_CHAR }
                )}
                value={newComment.text}
                options={autoCompleteOptions}
                onToggleFocus={handleToggleInputFocus}
                onKeyDown={handleKeyDown}
                onChange={handleCommentTextChange} />
              <div className={Css.buttons}>
                {newComment.type === COMMENT
                  && !!onAskClient
                  && askClientAvailable
                  && (
                    <Button
                      size="sm"
                      theme="light"
                      disabled={emptyText || fetchingComments}
                      onClick={handleAddCommentAndAskButtonClick}>
                      <span>{uiTexts.sendAndAskClient}</span>
                    </Button>
                  )}
                <Button
                  size="sm"
                  disabled={fetchingComments || emptyText}
                  onClick={handleAddCommentButtonClick}>
                  <span>{uiTexts.send}</span>
                </Button>
              </div>
            </div>
          </>
        )}
    </div>
  );
};

export default React.memo(CommentsBlock);
