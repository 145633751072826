import StripeActions from "actions/StripeActions";

const {
  CREATE_BILLING_PORTAL_SESSION_START,
  CREATE_BILLING_PORTAL_SESSION_DONE,
  CREATE_BILLING_PORTAL_SESSION_ERROR,
  CREATE_SETUP_CHECKOUT_SESSION_START,
  CREATE_SETUP_CHECKOUT_SESSION_DONE,
  CREATE_SETUP_CHECKOUT_SESSION_ERROR,
  FULLFILL_TRIAL_PAYMENT_START,
  FULLFILL_TRIAL_PAYMENT_DONE,
  FULLFILL_TRIAL_PAYMENT_ERROR
} = StripeActions;

const initialState = {
  fetchingData: false
};

export default (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case CREATE_BILLING_PORTAL_SESSION_START:
    case CREATE_SETUP_CHECKOUT_SESSION_START:
    case FULLFILL_TRIAL_PAYMENT_START:
      return { ...state, fetchingData: true };

    case CREATE_BILLING_PORTAL_SESSION_DONE:
    case CREATE_SETUP_CHECKOUT_SESSION_DONE:
    case FULLFILL_TRIAL_PAYMENT_DONE:
    case CREATE_BILLING_PORTAL_SESSION_ERROR:
    case CREATE_SETUP_CHECKOUT_SESSION_ERROR:
    case FULLFILL_TRIAL_PAYMENT_ERROR:
      return { ...state, fetchingData: false };

    default:
      return state;
  }
};
