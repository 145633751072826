import { createSelector } from "reselect";
import { getTextsData } from "selectors/texts";
import UiRoutes from "const/UiRoutes";
import UserRoles from "const/UserRoles";
import Utils from "utils/Utils";

export const checkAuthState = ({ user: { token } }) => !!token;

export const checkAuthCallbackState = ({ router: { location: { pathname } }, envVars: { code, state } }) => {
  return pathname === UiRoutes.MAIN && !!(code && state);
};

export const getUserData = ({ user: { data } }) => data;

export const getUserRole = ({ user: { data: { role } = {} } }) => role;

export const checkIsBusinessUser = createSelector([getUserRole], (role) => UserRoles.checkIsBusiness(role));

export const getAuthErrorDescription = (state) => {
  const { envVars: { error, error_description: errorDescription } } = state;

  const { errors } = getTextsData(state);

  return errorDescription || (error ? errors.unknown : "");
};

export const getUserRestrictions = createSelector(
  [getUserRole],
  (role) => UserRoles.getRestrictions(role).reduce((aggregator, restriction) => {
    let [section, action] = restriction.split(":");

    const [firstSectionPart, ...restSection] = section.split("-");

    section = firstSectionPart + restSection.map((value) => Utils.capitalizeText(value)).join("");

    return { ...aggregator, [`${section}${Utils.capitalizeText(action)}`]: true };
  }, {})
);
