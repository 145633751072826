import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import React from "react";
import classNames from "classnames";

const TYPES = {
  ERROR: "error",
  WARNING: "warning",
  INFO: "info",
  SUCCESS: "success"
};

const CLASS_NAMES = {
  [TYPES.ERROR]: Css.error,
  [TYPES.WARNING]: Css.warning,
  [TYPES.INFO]: Css.info,
  [TYPES.SUCCESS]: Css.success
};

const ALERT_ICONS = {
  [TYPES.ERROR]: Icons.Warning,
  [TYPES.WARNING]: Icons.WarningCircle,
  [TYPES.INFO]: Icons.Info,
  [TYPES.SUCCESS]: Icons.Check
};

const Alert = (props) => {
  const {
    type = TYPES.WARNING,
    message,
    actionComponent,
    iconComponent: IconComponent = ALERT_ICONS[type],
    className,
    onClick,
    ...restProps
  } = props;

  return (
    <div
      className={classNames(Css.alert, CLASS_NAMES[type], onClick && Css.clickable, className)}
      onClick={onClick}
      {...restProps}>
      <div className={Css.content}>
        <IconComponent className={Css.icon} />
        <div className={Css.message}>
          {message}
        </div>
        {!!actionComponent && (
          <div className={Css.action}>
            {actionComponent}
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(Alert);
