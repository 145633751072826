import Utils from "utils/Utils";
import VatClassificationActions from "actions/VatClassificationActions";

const {
  FETCH_VAT_CLASSIFICATION_LIST_START,
  FETCH_VAT_CLASSIFICATION_LIST_DONE,
  FETCH_VAT_CLASSIFICATION_LIST_ERROR,
  CREATE_VAT_CLASSIFICATION_START,
  CREATE_VAT_CLASSIFICATION_DONE,
  CREATE_VAT_CLASSIFICATION_ERROR,
  UPDATE_VAT_CLASSIFICATION_START,
  UPDATE_VAT_CLASSIFICATION_DONE,
  UPDATE_VAT_CLASSIFICATION_ERROR,
  DELETE_VAT_CLASSIFICATION_START,
  DELETE_VAT_CLASSIFICATION_DONE,
  DELETE_VAT_CLASSIFICATION_ERROR
} = VatClassificationActions;

const initialState = {
  fetchingData: false,
  data: []
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_VAT_CLASSIFICATION_LIST_START: {
      const { clearList = false, backgroundUpdate = false } = payload;

      return {
        ...state,
        fetchingData: !backgroundUpdate,
        data: clearList ? [] : state.data
      };
    }

    case FETCH_VAT_CLASSIFICATION_LIST_DONE: {
      return {
        ...state,
        fetchingData: false,
        data: payload
      };
    }

    case CREATE_VAT_CLASSIFICATION_START:
    case UPDATE_VAT_CLASSIFICATION_START:
    case DELETE_VAT_CLASSIFICATION_START:
      return { ...state, fetchingData: true };

    case CREATE_VAT_CLASSIFICATION_DONE: {
      return {
        ...state,
        fetchingData: false,
        data: [...state.data, ...payload]
      };
    }

    case UPDATE_VAT_CLASSIFICATION_DONE: {
      return {
        ...state,
        fetchingData: false,
        data: state.data.map((item) => Utils.arrayFindById(payload, "id", item.id, item))
      };
    }

    case DELETE_VAT_CLASSIFICATION_DONE: {
      const ids = payload.map(({ id }) => id);

      return {
        ...state,
        fetchingData: false,
        data: state.data.filter(({ id }) => !ids.includes(id))
      };
    }

    case FETCH_VAT_CLASSIFICATION_LIST_ERROR:
    case CREATE_VAT_CLASSIFICATION_ERROR:
    case UPDATE_VAT_CLASSIFICATION_ERROR:
    case DELETE_VAT_CLASSIFICATION_ERROR:
      return { ...state, fetchingData: false };

    default:
      return state;
  }
};
