import UiActions from "actions/UiActions";

const {
  TOGGLE_PRELOADER,
  TOGGLE_PRELOADER_OVERLAY,
  TOGGLE_MODAL_CONTENT,
  SHOW_MODAL,
  HIDE_MODAL,
  SHOW_MODAL_IMAGES,
  HIDE_MODAL_IMAGES,
  SHOW_CUSTOM_MODAL,
  HIDE_CUSTOM_MODAL,
  SET_OPENED_COMMENTS_ENTITY_ID
} = UiActions;

const initialState = {
  preloaderOverlayShown: false,
  modalContentShown: false,
  modalWindowConfig: null,
  preloaderShown: true,
  preloaderText: null,
  openedModals: [],
  modalImages: null,
  pathname: null,
  openedCommentsEntityId: null
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case TOGGLE_PRELOADER:
      return { ...state, preloaderShown: payload.state, preloaderText: payload.state ? payload.preloaderText : null };

    case TOGGLE_PRELOADER_OVERLAY:
      return { ...state, preloaderOverlayShown: payload.state };

    case TOGGLE_MODAL_CONTENT:
      return { ...state, modalContentShown: payload.state };

    case SHOW_MODAL:
      return { ...state, modalWindowConfig: payload, modalContentShown: true };

    case HIDE_MODAL:
      return { ...state, modalWindowConfig: null };

    case SHOW_MODAL_IMAGES:
      return { ...state, modalImages: payload.sources };

    case HIDE_MODAL_IMAGES:
      return { ...state, modalImages: null };

    case SHOW_CUSTOM_MODAL:
      return { ...state, openedModals: [...state.openedModals, payload] };

    case HIDE_CUSTOM_MODAL:
      return { ...state, openedModals: state.openedModals.slice(0, -1) };

    case SET_OPENED_COMMENTS_ENTITY_ID:
      return { ...state, openedCommentsEntityId: payload.entityId };

    default:
      return state;
  }
};
