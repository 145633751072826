import { getActiveOrganization, getActiveUsersData } from "selectors/organizations";
import { getSelectedBusinessId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { getUserData } from "selectors/user";
import { mainApi } from "api";
import { toast } from "react-toastify";
import MainApiRoutes from "const/MainApiRoutes";
import UiActions from "actions/UiActions";
import Utils from "utils/Utils";

export default class OrganizationsActions {
  static SET_BACKUP_INFO_START = "organizations/SET_BACKUP_INFO_START";

  static SET_BACKUP_INFO_DONE = "organizations/SET_BACKUP_INFO_DONE";

  static SET_BACKUP_INFO_ERROR = "organizations/SET_BACKUP_INFO_ERROR";

  static FETCH_ORGANIZATIONS_LIST_START = "organizations/FETCH_ORGANIZATIONS_LIST_START";

  static FETCH_ORGANIZATIONS_LIST_DONE = "organizations/FETCH_ORGANIZATIONS_LIST_DONE";

  static FETCH_ORGANIZATIONS_LIST_ERROR = "organizations/FETCH_ORGANIZATIONS_LIST_ERROR";

  static FETCH_USERS_LIST_START = "organizations/FETCH_USERS_LIST_START";

  static FETCH_USERS_LIST_DONE = "organizations/FETCH_USERS_LIST_DONE";

  static FETCH_USERS_LIST_ERROR = "organizations/FETCH_USERS_LIST_ERROR";

  static CHANGE_ACTIVE_ORGANIZATION_START = "organizations/CHANGE_ACTIVE_ORGANIZATION_START";

  static CHANGE_ACTIVE_ORGANIZATION_DONE = "organizations/CHANGE_ACTIVE_ORGANIZATION_DONE";

  static CHANGE_ACTIVE_ORGANIZATION_ERROR = "organizations/CHANGE_ACTIVE_ORGANIZATION_ERROR";

  static EDIT_USER_START = "organizations/EDIT_USER_START";

  static EDIT_USER_DONE = "organizations/EDIT_USER_DONE";

  static EDIT_USER_ERROR = "organizations/EDIT_USER_ERROR";

  static ASK_BUSINESS_USER_START = "organizations/ASK_BUSINESS_USER_START";

  static ASK_BUSINESS_USER_DONE = "organizations/ASK_BUSINESS_USER_DONE";

  static ASK_BUSINESS_USER_ERROR = "organizations/ASK_BUSINESS_USER_ERROR";

  static INVITE_USER_START = "organizations/INVITE_USER_START";

  static INVITE_USER_DONE = "organizations/INVITE_USER_DONE";

  static INVITE_USER_ERROR = "organizations/INVITE_USER_ERROR";

  static REVOKE_USER_START = "organizations/REVOKE_USER_START";

  static REVOKE_USER_DONE = "organizations/REVOKE_USER_DONE";

  static REVOKE_USER_ERROR = "organizations/REVOKE_USER_ERROR";

  static DELETE_BACKUP_INFO_START = "organizations/DELETE_BACKUP_INFO_START";

  static DELETE_BACKUP_INFO_DONE = "organizations/DELETE_BACKUP_INFO_DONE";

  static DELETE_BACKUP_INFO_ERROR = "organizations/DELETE_BACKUP_INFO_ERROR";

  static DELETE_ORGANIZATION_START = "organizations/DELETE_ORGANIZATION_START";

  static DELETE_ORGANIZATION_DONE = "organizations/DELETE_ORGANIZATION_DONE";

  static DELETE_ORGANIZATION_ERROR = "organizations/DELETE_ORGANIZATION_ERROR";

  static DELETE_ALL_INACTIVE_ORGANIZATIONS_START = "organizations/DELETE_ALL_INACTIVE_ORGANIZATIONS_START";

  static DELETE_ALL_INACTIVE_ORGANIZATIONS_DONE = "organizations/DELETE_ALL_INACTIVE_ORGANIZATIONS_DONE";

  static DELETE_ALL_INACTIVE_ORGANIZATIONS_ERROR = "organizations/DELETE_ALL_INACTIVE_ORGANIZATIONS_ERROR";

  static setBackupInfo({ folderId, folderName }) {
    return async(dispatch, getState) => {
      dispatch({ type: OrganizationsActions.SET_BACKUP_INFO_START });

      const { errors } = getTextsData(getState());

      const { id: organizationId } = getActiveOrganization(getState());

      const { ORGANIZATIONS, BACKUP_INFO } = MainApiRoutes;

      const { ok } = await mainApi.post(
        `${ORGANIZATIONS}/${organizationId}${BACKUP_INFO}`,
        null,
        { folderId, folderName }
      );

      if (ok) {
        dispatch({ type: OrganizationsActions.SET_BACKUP_INFO_DONE, payload: { folderId, folderName } });

        return true;
      }
      dispatch({ type: OrganizationsActions.SET_BACKUP_INFO_ERROR });
      toast.error(errors.unknown);

      return false;
    };
  }

  static fetchOrganizationsList(backgroundUpdate = false) {
    return async(dispatch, getState) => {
      dispatch({ type: OrganizationsActions.FETCH_ORGANIZATIONS_LIST_START, payload: { backgroundUpdate } });

      const { errors } = getTextsData(getState());

      const { results: organizations } = await mainApi.get(MainApiRoutes.ORGANIZATIONS);

      if (Array.isArray(organizations)) {
        dispatch({ type: OrganizationsActions.FETCH_ORGANIZATIONS_LIST_DONE, payload: { organizations } });

        return organizations;
      }
      dispatch({ type: OrganizationsActions.FETCH_ORGANIZATIONS_LIST_ERROR });
      toast.error(errors.whileLoadingOrganizations);

      return null;
    };
  }

  static fetchUsersList(backgroundUpdate, silentUpdate) {
    return async(dispatch, getState) => {
      dispatch({ type: OrganizationsActions.FETCH_USERS_LIST_START, payload: { backgroundUpdate } });

      const { errors } = getTextsData(getState());

      const { ORGANIZATIONS, USERS } = MainApiRoutes;

      const { results: users } = await mainApi.get(ORGANIZATIONS + USERS);

      if (Array.isArray(users)) {
        dispatch({ type: OrganizationsActions.FETCH_USERS_LIST_DONE, payload: { users } });

        return users;
      }
      dispatch({ type: OrganizationsActions.FETCH_USERS_LIST_ERROR });
      if (!silentUpdate) toast.error(errors.whileLoadingOrganizationUsers);

      return null;
    };
  }

  static changeActiveOrganization(organizationId) {
    return async(dispatch, getState) => {
      dispatch({ type: OrganizationsActions.CHANGE_ACTIVE_ORGANIZATION_START });

      const { errors } = getTextsData(getState());

      const { ORGANIZATIONS, ACTIVATE } = MainApiRoutes;

      const { ok } = await mainApi.post(`${ORGANIZATIONS}/${organizationId + ACTIVATE}`);

      if (ok) {
        dispatch({ type: OrganizationsActions.CHANGE_ACTIVE_ORGANIZATION_DONE, payload: { organizationId } });

        return organizationId;
      }
      dispatch({ type: OrganizationsActions.CHANGE_ACTIVE_ORGANIZATION_ERROR });
      toast.error(errors.whileChangingOrganization);

      return null;
    };
  }

  static editUser({
    email,
    role,
    businessIds,
    emailNotifications = false,
    smsNotifications = false,
    phone = ""
  }) {
    return async(dispatch, getState) => {
      dispatch({ type: OrganizationsActions.EDIT_USER_START });

      const { messages, errors } = getTextsData(getState());

      const { id: organizationId } = getActiveOrganization(getState());

      const usersData = getActiveUsersData(getState());

      const { id: userId } = usersData.find((user) => user.email === email);

      const { ORGANIZATIONS, USERS } = MainApiRoutes;

      const { ok } = await mainApi.patch(
        `${ORGANIZATIONS}/${organizationId}${USERS}/${userId}`,
        null,
        { role, businessIds, emailNotifications, smsNotifications, phone }
      );

      if (ok) {
        dispatch({
          type: OrganizationsActions.EDIT_USER_DONE,
          payload: { id: userId, role, businessIds, emailNotifications, smsNotifications, phone }
        });
        toast.success(Utils.replaceTextVars(messages.userEdited, { email }));

        return { role, email };
      }
      dispatch({ type: OrganizationsActions.EDIT_USER_ERROR });
      toast.error(Utils.replaceTextVars(errors.whileEditingUser, { email }));

      return null;
    };
  }

  static askBusinessUser(transactionIds, email, phone = "", comment = "", silentUpdate) {
    return async(dispatch, getState) => {
      dispatch({ type: OrganizationsActions.ASK_BUSINESS_USER_START });

      const { uiTexts, messages, errors } = getTextsData(getState());

      const { id: organizationId } = getActiveOrganization(getState());

      const businessId = getSelectedBusinessId(getState());

      const { ORGANIZATIONS, USERS, ASK_BUSINESS } = MainApiRoutes;

      const response = await mainApi.post(
        `${ORGANIZATIONS}/${organizationId}${USERS}${ASK_BUSINESS}`,
        null,
        {
          businessId,
          transactionIds,
          email,
          phone: phone || undefined,
          comment: comment || undefined,
          inviteOnly: !transactionIds || !transactionIds.length,
          callbackUrl: Utils.checkIsDefaultAppDomain() ? undefined : window.location.origin
        }
      );

      const { ok, inviteLink, emailNotifications } = response;

      if (ok) {
        dispatch({ type: OrganizationsActions.ASK_BUSINESS_USER_DONE, payload: { email } });
        if (!silentUpdate) toast.info(messages.clientWillBeNotified);

        return response;
      }
      if (!emailNotifications && inviteLink) {
        const result = await dispatch(UiActions.showModal(
          `${messages.userUnsubscribed}\n\n${inviteLink}`,
          uiTexts.copyLink,
          true,
          null,
          uiTexts.copyLink
        ));

        if (result) {
          const { clipboard } = window.navigator;

          if (clipboard && clipboard.writeText) clipboard.writeText(inviteLink);
        }
      } else {
        dispatch({ type: OrganizationsActions.ASK_BUSINESS_USER_ERROR });
        toast.error(errors.whileAskingClient);
      }

      return null;
    };
  }

  static inviteUser({
    email,
    role,
    businessIds,
    emailNotifications = false,
    smsNotifications = false,
    phone = ""
  }) {
    return async(dispatch, getState) => {
      dispatch({ type: OrganizationsActions.INVITE_USER_START });

      const { messages, errors } = getTextsData(getState());

      const { id: organizationId } = getActiveOrganization(getState());

      const { ORGANIZATIONS, USERS, INVITE } = MainApiRoutes;

      const { userId = null, invited = false } = await mainApi.post(
        `${ORGANIZATIONS}/${organizationId}${USERS}${INVITE}`,
        null,
        {
          businessIds,
          emailNotifications,
          smsNotifications,
          profile: { email, role, phone },
          callbackUrl: Utils.checkIsDefaultAppDomain() ? undefined : window.location.origin
        }
      );

      if (userId || invited) {
        dispatch({
          type: OrganizationsActions.INVITE_USER_DONE,
          payload: { id: userId, role, email, businessIds, emailNotifications, smsNotifications, phone, invited }
        });
        toast.success(Utils.replaceTextVars(messages.userInvited, { email }));

        return { userId, role, email };
      }
      dispatch({ type: OrganizationsActions.INVITE_USER_ERROR });
      toast.error(Utils.replaceTextVars(errors.whileInvitingUser, { email }));

      return null;
    };
  }

  static revokeUser(email, pendingInvite) {
    return async(dispatch, getState) => {
      dispatch({ type: OrganizationsActions.REVOKE_USER_START });

      const userData = getUserData(getState());

      const { messages, errors } = getTextsData(getState());

      const { id: organizationId } = getActiveOrganization(getState());

      const { ORGANIZATIONS, USERS, REVOKE } = MainApiRoutes;

      const { ok } = await mainApi.post(
        `${ORGANIZATIONS}/${organizationId}${USERS}${REVOKE}`,
        null,
        { profile: { email }, pendingInvite }
      );

      if (ok) {
        dispatch({ type: OrganizationsActions.REVOKE_USER_DONE, payload: { email } });
        if (email !== userData.email) toast.success(Utils.replaceTextVars(messages.userRevoked, { email }));

        return email;
      }
      dispatch({ type: OrganizationsActions.REVOKE_USER_ERROR });
      toast.error(Utils.replaceTextVars(errors.whileRevokingUser, { email }));

      return null;
    };
  }

  static deleteOrganization(organizationId) {
    return async(dispatch, getState) => {
      dispatch({ type: OrganizationsActions.DELETE_ORGANIZATION_START });

      const { errors } = getTextsData(getState());

      const { ok } = await mainApi.delete(`${MainApiRoutes.ORGANIZATIONS}/${organizationId}`);

      if (ok) {
        dispatch({ type: OrganizationsActions.DELETE_ORGANIZATION_DONE, payload: { organizationId } });

        return organizationId;
      }
      dispatch({ type: OrganizationsActions.DELETE_ORGANIZATION_ERROR });
      toast.error(errors.unknown);

      return null;
    };
  }

  static deleteBackupInfo() {
    return async(dispatch, getState) => {
      dispatch({ type: OrganizationsActions.DELETE_BACKUP_INFO_START });

      const { errors } = getTextsData(getState());

      const { id: organizationId } = getActiveOrganization(getState());

      const { ORGANIZATIONS, BACKUP_INFO } = MainApiRoutes;

      const { ok } = await mainApi.delete(`${ORGANIZATIONS}/${organizationId}${BACKUP_INFO}`);

      if (ok) {
        dispatch({ type: OrganizationsActions.DELETE_BACKUP_INFO_DONE });

        return true;
      }
      dispatch({ type: OrganizationsActions.DELETE_BACKUP_INFO_ERROR });
      toast.error(errors.unknown);

      return false;
    };
  }

  static deleteAllInactiveOrganizations() {
    return async(dispatch, getState) => {
      dispatch({ type: OrganizationsActions.DELETE_ALL_INACTIVE_ORGANIZATIONS_START });

      const { errors } = getTextsData(getState());

      const { ok } = await mainApi.delete(MainApiRoutes.ORGANIZATIONS + MainApiRoutes.ALL_INACTIVE);

      if (ok) {
        dispatch({ type: OrganizationsActions.DELETE_ALL_INACTIVE_ORGANIZATIONS_DONE });

        return true;
      }
      dispatch({ type: OrganizationsActions.DELETE_ALL_INACTIVE_ORGANIZATIONS_ERROR });
      toast.error(errors.unknown);

      return false;
    };
  }
}
