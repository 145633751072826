import { createSelector } from "reselect";
import { getActiveUsersData, getAllUsersData } from "selectors/organizations";
import { getCustomersData } from "./contacts";
import { getUserData } from "./user";
import DataConstants from "const/DataConstants";
import IntegrationServices from "const/IntegrationServices";
import UserRoles from "const/UserRoles";
import Utils from "utils/Utils";

const {
  STATUSES: { NEED_REACTION, TO_REVIEW, EXPORTED }
} = DataConstants;

const { QUICK_BOOKS_DESKTOP, QUICK_BOOKS, XERO } = IntegrationServices;

const BUSINESS_LOCK_DATE_PROPERTY = {
  [QUICK_BOOKS_DESKTOP.value]: "quickBooksBookCloseDate",
  [QUICK_BOOKS.value]: "quickBooksBookCloseDate",
  [XERO.value]: "xeroBookCloseDate"
};

export const checkBusinessesFetching = ({ businesses: { fetchingData } }) => fetchingData;

export const checkGlobalStatsFetching = ({ businesses: { fetchingGlobalStats } }) => fetchingGlobalStats;

export const checkMatchesFetching = ({ businesses: { fetchingMatches } }) => fetchingMatches;

export const getSelectedBusinessId = ({ businesses: { selectedBusinessId } }) => selectedBusinessId;

export const getMatchesData = createSelector(
  [
    ({ businesses: { matches } }) => matches,
    getUserData
  ],
  (matches, user) => {
    const businessUser = UserRoles.checkIsBusiness(user.role);

    return matches.filter(({ transaction, document }) => {
      return !businessUser || ([NEED_REACTION, TO_REVIEW].includes(transaction.status) && document.status !== EXPORTED);
    });
  }
);

export const getAllBusinessesData = createSelector(
  [
    ({ businesses: { data } }) => data,
    getUserData
  ],
  (data, user) => {
    const businessUser = UserRoles.checkIsBusiness(user.role);

    return [...data].map(({ name, legalName, ...restBusiness }) => {
      // TODO: Temp solution. Remove when TagsInput will be rewrited
      return { ...restBusiness, legalName, name: businessUser ? legalName : name.replace(/"/g, "").replace(/,/g, " ").trim() };
    });
  }
);

export const getUserBusinessesData = createSelector(
  [getAllBusinessesData, getUserData],
  (data, { businessIds = [] }) => {
    return data.filter(({ id }) => !businessIds.length || businessIds.includes(id));
  }
);

export const getSelectedBusinessData = createSelector(
  [getSelectedBusinessId, getUserBusinessesData],
  (businessId, data) => data.find(({ id }) => id === businessId) || {}
);

export const getSelectedBusinessCategories = createSelector(
  [getSelectedBusinessData],
  ({ extraData: { xeroClassification, quickBooksClassification, quickBooksDesktopClassification, zohoClassification } = {} }) => {
    return xeroClassification || quickBooksClassification || quickBooksDesktopClassification || zohoClassification || [];
  }
);

export const getSelectedBusinessItems = createSelector(
  [getSelectedBusinessData],
  ({ extraData: { xeroItems, quickBooksItems, zohoItems } = {} }) => {
    return xeroItems || quickBooksItems || zohoItems || [];
  }
);

export const getSelectedBusinessClasses = createSelector(
  [getSelectedBusinessData],
  ({ extraData: { xeroClasses, quickBooksClasses, zohoClasses } = {} }) => {
    return xeroClasses || quickBooksClasses || zohoClasses || [];
  }
);

export const getSelectedBusinessLocations = createSelector(
  [getSelectedBusinessData],
  ({ extraData: { quickBooksLocations } = {} }) => {
    return quickBooksLocations || [];
  }
);

export const getSelectedBusinessProjects = createSelector(
  [getSelectedBusinessData, getCustomersData],
  ({ extraData: { quickBooksProjects, zohoProjects, quickBooksTrackingByCustomer } = {} }, customersData) => {
    const projects = quickBooksProjects || zohoProjects || [];

    if (!quickBooksTrackingByCustomer) return projects;

    return [...projects, ...(customersData.map(({ name, extraData: { quickBooksCustomerId = "" } = {} }) => {
      const [, id] = (quickBooksCustomerId || "").split("-");

      return { id, name, customer: true };
    }))];
  }
);

export const getSelectedBusinessTaxRates = createSelector(
  [getSelectedBusinessData],
  ({ extraData: { xeroTaxRates, quickBooksTaxRates, zohoTaxRates } = {} }) => {
    return xeroTaxRates || quickBooksTaxRates || zohoTaxRates || [];
  }
);

export const getBusinessesIds = createSelector(
  [getAllBusinessesData],
  (data) => data.map(({ id }) => id)
);

export const getBusinessesNames = createSelector(
  [getAllBusinessesData],
  (data) => data.map(({ name }) => name)
);

export const getGlobalStats = Utils.createDeepEqualSelector(
  ({ businesses: { globalStats } }) => globalStats,
  (globalStats) => globalStats
);

export const getTransactionsInProgressStats = createSelector(
  [getGlobalStats],
  ({ transactions: { inProgress } = {} }) => inProgress
);

export const getSelectedBusinessUsersData = createSelector(
  [getSelectedBusinessId, getActiveUsersData],
  (businessId, users) => users.filter(({ businessIds }) => !businessIds.length || businessIds.includes(businessId))
);

export const getCurrentQuickBooksRealmId = createSelector(
  [getSelectedBusinessData],
  ({ extraData }) => extraData && extraData.quickBooksRealmId
);

export const getCurrentXeroOrganizationId = createSelector(
  [getSelectedBusinessData],
  ({ extraData }) => extraData && extraData.xeroOrganizationId
);

export const getCurrentZohoOrganizationId = createSelector(
  [getSelectedBusinessData],
  ({ extraData }) => extraData && extraData.zohoOrganizationId
);

export const getSelectedBusinessBookCloseDate = createSelector(
  [getSelectedBusinessData],
  ({ extraData = {} }) => {
    const { integrationService } = extraData;

    return extraData[BUSINESS_LOCK_DATE_PROPERTY[integrationService]];
  }
);

export const getSelectedBusinessIntegrationServiceData = createSelector(
  [getSelectedBusinessData],
  ({ extraData: { integrationService } = {} }) => {
    return IntegrationServices.getByValue(integrationService);
  }
);

export const checkSelectedBusinessHasBusinessUsers = createSelector(
  [getSelectedBusinessId, getAllUsersData],
  (businessId, users) => users.some(({ role, businessIds = [] }) => {
    return UserRoles.checkIsBusiness(role) && businessIds.includes(businessId);
  })
);

export const checkSelectedBusinessRpaMode = createSelector(
  [getSelectedBusinessData],
  ({ extraData: { uncategorizedRpaEnabled, reconciliationRpaEnabled } = {} }) =>
    uncategorizedRpaEnabled !== undefined || reconciliationRpaEnabled !== undefined
);

export const checkSelectedBusinessHasWhoAsk = createSelector(
  [
    checkSelectedBusinessRpaMode,
    getSelectedBusinessData,
    getAllUsersData,
    getSelectedBusinessId
  ],
  (
    rpaMode,
    { settings: { rpaAutoAskClient, rpaClientEmail = "" } = {} },
    usersData,
    businessId
  ) => {
    const businessUserEmails = usersData
      .filter(({ role, businessIds = [] }) => UserRoles.checkIsBusiness(role) && businessIds.includes(businessId))
      .map(({ email }) => email.toLowerCase());

    return rpaMode
      ? rpaAutoAskClient && businessUserEmails.includes(rpaClientEmail.toLowerCase())
      : !!businessUserEmails.length;
  }
);
