import DashboardActions from "actions/DashboardActions";

const {
  FETCH_DASHBOARD_DATA_START,
  FETCH_DASHBOARD_DATA_DONE,
  FETCH_DASHBOARD_DATA_ERROR
} = DashboardActions;

const initialState = {
  fetchingData: false,
  data: {}
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_DASHBOARD_DATA_START:
      return { ...state, fetchingData: true, data: {} };

    case FETCH_DASHBOARD_DATA_DONE:
      return { ...state, fetchingData: false, data: { ...state.data, ...payload.data } };

    case FETCH_DASHBOARD_DATA_ERROR:
      return { ...state, fetchingData: false };

    default:
      return state;
  }
};
