import { getStore } from "store";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";
import Async from "utils/Async";
import Constants from "const/Constants";
import Errors from "const/Errors";
import MainApiActions from "actions/MainApiActions";
import RestApi from "api/RestApi";
import Utils from "utils/Utils";

const NOTIFICATION_AUTO_CLOSE_DELAY = 300000;

const ERROR_MESSAGE_LENGTH_LIMIT = 100;

const JSON_PRETTIFY_TAB_SIZE = 2;

export default class MainApi extends RestApi {
  static requests = {};

  token = null;

  fatalError = false;

  sequentialErrorsCount = 0;

  async getBlob(path, urlParams) {
    const headers = { Authorization: `Bearer ${this.token}` };

    const response = await fetch(
      `${this.apiUrl}${path}${urlParams ? `?${Utils.objectToQueryString(urlParams)}` : ""}`,
      { headers }
    );

    if (response.ok) {
      const responseBlob = await response.blob();

      return responseBlob;
    }

    return null;
  }

  makeRequest(method, path, urlParams, payload, fileField) {
    const requestKey = method + path;

    const requestUuid = uuid();

    const requestPromise = (async() => {
      const concurrentRequestPromises = Object.values(MainApi.requests)
        .filter(([concurrentRequestKey]) => concurrentRequestKey === requestKey)
        .map(([, concurrentRequestPromise]) => concurrentRequestPromise);

      if (concurrentRequestPromises.length) await Promise.all(concurrentRequestPromises);

      let result;

      if (this.fatalError) await Async.waitForever();
      try {
        if (this.sequentialErrorsCount === Constants.MAX_API_SEQUENTIAL_ERRORS) throw Errors.TOO_MANY_ERRORS;
        if (!this.token) throw Errors.SESSION_EXPIRED;

        const headers = { Authorization: `Bearer ${this.token}` };

        let requestData;

        if (fileField) {
          requestData = new FormData();
          requestData.append(fileField, payload);
        } else {
          requestData = JSON.stringify(payload);
          headers["Content-Type"] = "application/json";
        }

        const searchString = urlParams ? Utils.objectToQueryString(urlParams) : null;

        const response = await fetch(
          `${this.apiUrl}${path}${searchString ? `?${searchString}` : ""}`,
          {
            method,
            headers,
            body: requestData
          }
        );

        if (response.status === Constants.HTTP_STATUSES.UNAUTHORIZED) throw Errors.SESSION_EXPIRED;

        let responseJson;

        if (response.ok) {
          responseJson = await response.json();
          if (!Object.keys(responseJson).length) responseJson = { ok: true };
        }
        if (!response.ok) {
          if (Utils.checkIsStagingEnv() || Utils.checkIsDevMode()) {
            const errorJson = await response.json();

            if (errorJson && errorJson.message) {
              toast.dark(
                `DEV MODE ERROR: ${errorJson.message.substr(0, ERROR_MESSAGE_LENGTH_LIMIT)}`,
                {
                  autoClose: NOTIFICATION_AUTO_CLOSE_DELAY,
                  onClick: () => {
                    const { clipboard } = window.navigator;

                    if (clipboard && clipboard.writeText) {
                      clipboard.writeText(JSON.stringify(errorJson, null, JSON_PRETTIFY_TAB_SIZE));
                    }
                  }
                }
              );
            }
          }
          throw Errors.REQUEST_ERROR;
        }
        this.sequentialErrorsCount = 0;
        result = responseJson;
      } catch (error) {
        const { dispatch } = getStore();

        let errorType = Errors.REQUEST_ERROR;

        if (error === Errors.SESSION_EXPIRED || error === Errors.TOO_MANY_ERRORS) {
          errorType = error;
          this.fatalError = true;
        }
        this.sequentialErrorsCount++;
        dispatch(MainApiActions.apiErrorOccurred(errorType, this.fatalError));
        if (this.fatalError) await Async.waitForever();
        result = { ok: false, errorType };
      }
      delete MainApi.requests[requestUuid];

      return result;
    })();

    MainApi.requests[requestUuid] = [requestKey, requestPromise];

    return requestPromise;
  }
}
