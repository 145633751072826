import { createSelector } from "reselect";
import { getAccountsData } from "./accounts";
import { getContactsData } from "./contacts";
import { getSelectedBusinessCategories, getSelectedBusinessId, getSelectedBusinessTaxRates } from "./businesses";

export const checkAuditFetching = ({ audit: { fetchingData } }) => fetchingData;

export const getAuditStats = ({ audit: { stats } }) => stats;

export const getAuditFetchedDataRoute = ({ audit: { currentRoute } }) => currentRoute;

export const getAuditData = createSelector(
  [
    ({ audit: { data } }) => data,
    getAccountsData
  ],
  (data, accounts) => {
    return data.map(({ accountId, ...restData }) => ({
      ...restData,
      account: accounts.find(({ id }) => id === accountId) || {}
    }));
  }
);

export const getAuditUnusualVendorsData = createSelector(
  [
    ({ audit: { data } }) => data,
    getContactsData,
    getSelectedBusinessCategories
  ],
  (data, contactsData, categoriesData) => {
    return data.map(({ id, addressName, categories = [] }) => ({
      id,
      address: contactsData.find(({ name }) => name === addressName) || {},
      categories: categories.map(({ categoryCode, ...restData }) => {
        const found = categoriesData.find(({ code }) => code === categoryCode);

        return { ...restData, category: found || { code: categoryCode } };
      }).sort((
        { share: shareA, category: { displayName: nameA = "" } },
        { share: shareB, category: { displayName: nameB = "" } }
      ) => {
        return (shareB - shareA) || nameA.localeCompare(nameB);
      })
    }));
  }
);

export const getAuditUnusualTaxesData = createSelector(
  [
    ({ audit: { data } }) => data,
    getSelectedBusinessCategories,
    getSelectedBusinessTaxRates
  ],
  (data, categoriesData, taxRatesData) => {
    return data.map(({ id, categoryCode, taxRates = [] }) => ({
      id,
      category: categoriesData.find(({ code }) => code === categoryCode) || {},
      taxRates: taxRates.map(({ taxRateId, ...restData }) => {
        const found = taxRatesData.find((item) => item.id === taxRateId);

        return { ...restData, taxRate: found || {} };
      }).sort(({ share: shareA, taxRate: { name: nameA = "" } }, { share: shareB, taxRate: { name: nameB = "" } }) => {
        return (shareB - shareA) || nameA.localeCompare(nameB);
      })
    }));
  }
);

export const getAuditUnusualAmountsData = createSelector(
  [
    ({ audit: { data } }) => data,
    getAccountsData,
    getSelectedBusinessCategories
  ],
  (data, accountsData, categoriesData) => {
    return data.map(({ id, accountId, categoryCode, types = [] }) => ({
      id,
      account: accountsData.find((item) => item.id === accountId) || {},
      category: categoriesData.find((item) => item.code === categoryCode) || {},
      types: types.sort(({ type: typeA }, { type: typeB }) => {
        return typeA.localeCompare(typeB);
      })
    }));
  }
);

export const getAuditSimilarPayeesData = createSelector(
  [
    ({ audit: { data } }) => data,
    getContactsData
  ],
  (data, contactsData) => {
    return data.map(({ items = [], ...rest }) => ({
      ...rest,
      items: items.map(({ id, transactionsCount }) => ({
        id,
        transactionsCount,
        vendor: contactsData.find(({ id: contactId }) => contactId === id) || {}
      })).sort(({ transactionsCount: countA, vendor: vendorA }, { transactionsCount: countB, vendor: vendorB }) => {
        return countB - countA || (vendorA?.name || "").localeCompare(vendorB?.name || "");
      })
    }));
  }
);

export const getAuditDataCount = ({ audit: { count } }) => count;

export const getSelectedBusinessAuditFilters = createSelector(
  [
    ({ audit: { filters } }) => filters,
    getSelectedBusinessId
  ],
  (filters, selectedBusinessId) => {
    return filters[selectedBusinessId] || {};
  }
);
