import { getSelectedBusinessId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { mainApi } from "api";
import { toast } from "react-toastify";
import BusinessesActions from "./BusinessesActions";
import Constants from "const/Constants";
import MainApiRoutes from "const/MainApiRoutes";
import Utils from "utils/Utils";
import objectHash from "object-hash";

const {
  AUDIT_SUBSECTION_PATH_TO_NAME,
  AUDIT_SECTIONS_DATA,
  AUDIT_SUBSECTIONS_ROUTES
} = Constants;

export default class AuditActions {
  static FETCH_AUDIT_LIST_START = "audit/FETCH_AUDIT_LIST_START";

  static FETCH_AUDIT_LIST_DONE = "audit/FETCH_AUDIT_LIST_DONE";

  static FETCH_AUDIT_LIST_ERROR = "audit/FETCH_AUDIT_LIST_ERROR";

  static MARK_AS_REVISED_START = "audit/MARK_AS_REVISED_START";

  static MARK_AS_REVISED_DONE = "audit/MARK_AS_REVISED_DONE";

  static MARK_AS_REVISED_ERROR = "audit/MARK_AS_REVISED_ERROR";

  static DELETE_DUPLICATES_START = "audit/DELETE_DUPLICATES_START";

  static DELETE_DUPLICATES_DONE = "audit/DELETE_DUPLICATES_DONE";

  static DELETE_DUPLICATES_ERROR = "audit/DELETE_DUPLICATES_ERROR";

  static UNUSUAL_BULK_UPDATE_START = "audit/UNUSUAL_BULK_UPDATE_START";

  static UNUSUAL_BULK_UPDATE_DONE = "audit/UNUSUAL_BULK_UPDATE_DONE";

  static UNUSUAL_BULK_UPDATE_ERROR = "audit/UNUSUAL_BULK_UPDATE_ERROR";

  static UNUSUAL_MARK_AS_REVISED_START = "audit/UNUSUAL_MARK_AS_REVISED_START";

  static UNUSUAL_MARK_AS_REVISED_DONE = "audit/UNUSUAL_MARK_AS_REVISED_DONE";

  static UNUSUAL_MARK_AS_REVISED_ERROR = "audit/UNUSUAL_MARK_AS_REVISED_ERROR";

  static CLEAR_AUDIT_LIST = "audit/CLEAR_AUDIT_LIST";

  static SET_COMPANY_FILTERS = "audit/SET_COMPANY_FILTERS";

  static setCompanyFilters(filters) {
    return (dispatch, getState) => {
      const selectedBusinessId = getSelectedBusinessId(getState());

      dispatch({ type: AuditActions.SET_COMPANY_FILTERS, payload: { selectedBusinessId, filters } });
      dispatch(BusinessesActions.fetchGlobalStats());

      return null;
    };
  }

  static clearAuditList() {
    return { type: AuditActions.CLEAR_AUDIT_LIST };
  }

  static fetchAuditList(params) {
    const {
      currentSection,
      currentRoute,
      clearList,
      backgroundUpdate,
      filters,
      sortings,
      offset,
      limit = Constants.TABLE_PAGE_SIZE
    } = params;

    return async(dispatch, getState) => {
      dispatch({ type: AuditActions.FETCH_AUDIT_LIST_START, payload: { clearList, backgroundUpdate, currentRoute } });

      const { BUSINESSES, AUDIT, STATS } = MainApiRoutes;

      const selectedBusinessId = getSelectedBusinessId(getState());

      const { errors } = getTextsData(getState());

      const path = `${BUSINESSES}/${selectedBusinessId}${AUDIT}/${currentSection}`;

      const amountDeviationRoute = currentRoute === AUDIT_SUBSECTIONS_ROUTES.AMOUNT_DEVIATION;

      const { results, hash, count } = await mainApi.post(`${path}${currentRoute ? `/${currentRoute}` : ""}`, null, {
        ...filters,
        compareAmountBy: amountDeviationRoute ? filters.compareAmountBy : undefined,
        amount: amountDeviationRoute ? filters.amount : undefined,
        maxAmount: amountDeviationRoute ? filters.maxAmount : undefined,
        sortings,
        limit,
        offset
      });

      const stats = await mainApi.get(
        `${BUSINESSES}/${selectedBusinessId}${AUDIT}/${currentSection}${STATS}`,
        filters
      );

      if (Array.isArray(results)) {
        dispatch({
          type: AuditActions.FETCH_AUDIT_LIST_DONE,
          payload: {
            currentSection,
            currentRoute,
            data: results,
            stats,
            count,
            filters,
            backgroundUpdate,
            dataHash: objectHash({ value: hash || results })
          }
        });

        return results;
      }
      dispatch({ type: AuditActions.FETCH_AUDIT_LIST_ERROR });
      if (!backgroundUpdate) toast.error(errors.whileLoadingAudit);

      return null;
    };
  }

  static markAsRevised({ transactionsIds, currentRoute }, silentUpdate) {
    return async(dispatch, getState) => {
      dispatch({ type: AuditActions.MARK_AS_REVISED_START });

      const { messages, errors } = getTextsData(getState());

      const { BUSINESSES, AUDIT, TRANSACTIONS, REVISED } = MainApiRoutes;

      const selectedBusinessId = getSelectedBusinessId(getState());

      const statProp = AUDIT_SUBSECTION_PATH_TO_NAME[currentRoute];

      const path = `${BUSINESSES}/${selectedBusinessId}${AUDIT}${TRANSACTIONS}/${currentRoute}${REVISED}`;

      const result = await mainApi.post(path, null, { ids: transactionsIds });

      if (result && result.ok) {
        dispatch({ type: AuditActions.MARK_AS_REVISED_DONE, payload: { transactionsIds, statProp } });
        dispatch(BusinessesActions.fetchGlobalStats());
        if (!silentUpdate) {
          toast.success(transactionsIds.length > 1 ? messages.transactionsRevised : messages.transactionRevised);
        }

        return result;
      }

      dispatch({ type: AuditActions.MARK_AS_REVISED_ERROR });
      toast.error(transactionsIds.length > 1
        ? errors.whileRevisingTransactions : errors.whileRevisingTransaction);

      return null;
    };
  }

  static deleteDuplicates({ transactionsIds }) {
    return async(dispatch, getState) => {
      dispatch({ type: AuditActions.DELETE_DUPLICATES_START });

      const { messages, errors } = getTextsData(getState());

      const { BUSINESSES, AUDIT, TRANSACTIONS, DELETE } = MainApiRoutes;

      const selectedBusinessId = getSelectedBusinessId(getState());

      const statProp = AUDIT_SECTIONS_DATA.TRANSACTIONS.DUPLICATES;

      const path = `${BUSINESSES}/${selectedBusinessId}${AUDIT}${TRANSACTIONS}/${AUDIT_SUBSECTIONS_ROUTES.DUPLICATES}${DELETE}`;

      const result = await mainApi.post(path, null, { ids: transactionsIds });

      if (result && result.ok) {
        dispatch({ type: AuditActions.DELETE_DUPLICATES_DONE, payload: { transactionsIds, statProp } });
        toast.success(messages.transactionRevised);

        return result;
      }

      dispatch({ type: AuditActions.DELETE_DUPLICATES_ERROR });
      toast.error(errors.whileRevisingTransaction);

      return null;
    };
  }

  static unusualBulkUpdate(payload) {
    return async(dispatch, getState) => {
      dispatch({ type: AuditActions.UNUSUAL_BULK_UPDATE_START });

      const { currentRoute, ...requestPayload } = payload || {};

      const { messages, errors } = getTextsData(getState());

      const { BUSINESSES, AUDIT, TRANSACTIONS } = MainApiRoutes;

      const selectedBusinessId = getSelectedBusinessId(getState());

      const path = `${BUSINESSES}/${selectedBusinessId}${AUDIT}${TRANSACTIONS}/${currentRoute}${TRANSACTIONS}`;

      const { ok, count, failedTxs = [] } = await mainApi.put(path, null, requestPayload);

      const failedCount = failedTxs.length;

      const successCount = count - failedCount;

      if (ok || successCount) {
        dispatch({ type: AuditActions.UNUSUAL_BULK_UPDATE_DONE });
        dispatch(BusinessesActions.fetchGlobalStats());

        if (count === 1) {
          toast.success(messages.transactionEdited);
        } else {
          toast.success(Utils.replaceTextVars(
            messages.transactionsChanged,
            { successCount, totalCount: count }
          ));
        }

        return true;
      }

      dispatch({ type: AuditActions.UNUSUAL_BULK_UPDATE_ERROR });
      toast.error(errors.whileRevisingTransaction);

      return null;
    };
  }

  static unusualMarkAsRevised(payload) {
    return async(dispatch, getState) => {
      dispatch({ type: AuditActions.UNUSUAL_MARK_AS_REVISED_START });

      const { currentRoute, ...requestPayload } = payload || {};

      const { messages, errors } = getTextsData(getState());

      const { BUSINESSES, AUDIT, TRANSACTIONS, REVISED } = MainApiRoutes;

      const selectedBusinessId = getSelectedBusinessId(getState());

      const path = `${BUSINESSES}/${selectedBusinessId}${AUDIT}${TRANSACTIONS}/${currentRoute}${REVISED}`;

      const result = await mainApi.post(path, null, requestPayload);

      if (result && result.ok) {
        dispatch({ type: AuditActions.UNUSUAL_MARK_AS_REVISED_DONE });
        dispatch(BusinessesActions.fetchGlobalStats());
        toast.success(messages.transactionRevised);

        return true;
      }

      dispatch({ type: AuditActions.UNUSUAL_MARK_AS_REVISED_ERROR });
      toast.error(errors.whileRevisingTransaction);

      return null;
    };
  }
}
