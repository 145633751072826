import Css from "./style.module.scss";

import React from "react";
import classNames from "classnames";

const AutoTooltip = ({ children, className, tooltipClassName, active = true, ...restProps }) => (
  <div {...restProps} className={classNames(Css.autoTooltip, active && Css.active, className)}>
    <div className={Css.content}>{children}</div>
    {!!active && (
      <div className={classNames(Css.hoverTooltip, tooltipClassName)}>
        {children}
      </div>
    )}
  </div>
);

export default React.memo(AutoTooltip);
