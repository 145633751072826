import CommonCss from "lib/common/style.module.scss";

import Css from "./style.module.scss";

import fallbackTexts from "assets/fallbackTexts.json";

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "shards-react";
import { FiInfo, FiX } from "react-icons/fi";
import { bind, debounce } from "decko";
import { checkModalContentShown } from "selectors/ui";
import { connect } from "react-redux";
import { getTextsData } from "selectors/texts";
import { v4 as uuid } from "uuid";
import BrowserEvents from "const/BrowserEvents";
import Constants from "const/Constants";
import React, { PureComponent } from "react";
import ReactDom from "react-dom";
import UiActions from "actions/UiActions";
import classNames from "classnames";

const mapStateToProps = (state) => ({
  modalContentShown: checkModalContentShown(state),
  textsData: getTextsData(state)
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: (...args) => dispatch(UiActions.hideModal(...args)),
  toggleModalContent: (...args) => dispatch(UiActions.toggleModalContent(...args))
});

@connect(mapStateToProps, mapDispatchToProps)
class ModalWindow extends PureComponent {
  static activeInstances = [];

  static get activeInstance() {
    const instancesCount = ModalWindow.activeInstances.length;

    return instancesCount ? ModalWindow.activeInstances[instancesCount - 1] : null;
  }

  static get fullScreenMode() {
    return ModalWindow.activeInstances.some(({ props: { fullScreen } }) => fullScreen);
  }

  emptyFunction = () => null;

  uniqueId = null;

  modalWindowElement = null;

  resizeObserver = null;

  constructor(props) {
    super(props);
    this.uniqueId = uuid();
  }

  get active() {
    return this === ModalWindow.activeInstance;
  }

  setBodyOverflow(unset = false) {
    const modalContainerDiv = window.document.getElementById(this.uniqueId);

    const bodyElement = window.document.body;

    const windowHasScroll = modalContainerDiv.scrollHeight > modalContainerDiv.clientHeight;

    if (unset) bodyElement.classList.remove(Css.overflowX);
    else bodyElement.classList.add(Css.overflowX);
    if ((ModalWindow.fullScreenMode || windowHasScroll) && !unset) bodyElement.classList.add(Css.overflowY);
    else bodyElement.classList.remove(Css.overflowY);
  }

  @bind
  closeModal(result = false) {
    const { appModal, hideModal, onClose } = this.props;

    if (appModal) hideModal(result);
    if (onClose) onClose(result);
  }

  @bind
  showModal() {
    const { onShow } = this.props;

    if (onShow) onShow();
  }

  componentDidMount() {
    const { RESIZE, SCROLL, FULLSCREEN_CHANGE, ORIENTATION_CHANGE, KEY_DOWN } = BrowserEvents;

    const { modalContentShown, toggleModalContent } = this.props;

    window.addEventListener(KEY_DOWN, this.handleKeyDown);
    [RESIZE, SCROLL, FULLSCREEN_CHANGE, ORIENTATION_CHANGE]
      .forEach(((event) => window.addEventListener(event, this.handleWindowResize)));
    if ("ResizeObserver" in window) {
      try {
        this.resizeObserver = new ResizeObserver(this.handleModalResize);
        this.resizeObserver.observe(window.document.getElementById(this.uniqueId));
      } catch (error) {}
    }
    if (!ModalWindow.activeInstance && !modalContentShown) toggleModalContent(true);
    ModalWindow.activeInstances.push(this);
    ModalWindow.activeInstances.forEach((instance) => instance.forceUpdate());
    this.setBodyOverflow();
  }

  componentWillUnmount() {
    const { RESIZE, SCROLL, FULLSCREEN_CHANGE, ORIENTATION_CHANGE, KEY_DOWN } = BrowserEvents;

    window.removeEventListener(KEY_DOWN, this.handleKeyDown);
    [RESIZE, SCROLL, FULLSCREEN_CHANGE, ORIENTATION_CHANGE]
      .forEach(((event) => window.removeEventListener(event, this.handleWindowResize)));
    if (this.resizeObserver) this.resizeObserver.unobserve(window.document.getElementById(this.uniqueId));
    ModalWindow.activeInstances = ModalWindow.activeInstances.filter((value) => value !== this);
    ModalWindow.activeInstances.forEach((instance) => instance.forceUpdate());
    if (ModalWindow.activeInstance) ModalWindow.activeInstance.setBodyOverflow();
    else {
      this.setBodyOverflow(true);
      this.props.toggleModalContent(false);
    }
  }

  @bind
  @debounce(Constants.WINDOW_RESIZE_DEBOUNCE_DELAY)
  handleWindowResize() {
    if (this.active) this.setBodyOverflow();
  }

  @bind
  @debounce(Constants.WINDOW_RESIZE_DEBOUNCE_DELAY)
  handleModalResize() {
    if (this.active) this.setBodyOverflow();
  }

  @bind
  handleKeyDown(event) {
    const { key } = event;

    const { applyOnEnterPress, enableDefaultEnter, disabledButtons, disabledOkButton, disabledCancelButton } = this.props;

    if (this.active) {
      if (key === "Escape" && !(disabledCancelButton || disabledButtons)) this.closeModal(false);
      else if (key === "Enter") {
        if (!enableDefaultEnter) event.preventDefault();
        if (applyOnEnterPress && !(disabledOkButton || disabledButtons)) this.closeModal(true);
      }
    }
  }

  @bind
  handleOkButtonClick() {
    this.closeModal(true);
  }

  @bind
  handleCloseButtonClick() {
    this.closeModal(false);
  }

  @bind
  handleCancelButtonClick() {
    this.closeModal(this.props.config.cancelValue);
  }

  @bind
  handleCustomClose(value) {
    this.closeModal(value);
  }

  render() {
    const {
      textsData,
      size,
      fade,
      config = {},
      appModal,
      children,
      disabledButtons,
      disabledOkButton,
      disabledCancelButton,
      className,
      modalClassName,
      bodyClassName,
      headerClassName,
      headerWrapperProps,
      headerProps,
      footerWrapperProps,
      footerProps,
      iconComponent: Icon,
      headerComponent: Header,
      footerComponent: Footer
    } = this.props;

    const { headerText, text, confirm, okButtonText, cancelButtonText } = config;

    const { uiTexts } = textsData || fallbackTexts;

    return ReactDom.createPortal(
      <Modal
        open
        id={this.uniqueId}
        fade={fade}
        size={size || config.size}
        backdropClassName={classNames(Css.modalBackdrop, CommonCss.backdrop)}
        modalClassName={classNames(modalClassName, Css.modalWindowContainer)}
        className={classNames(className, Css.modalWindow, appModal && Css.appModalWindow)}
        showModal={this.showModal}
        toggle={this.emptyFunction}>
        <div {...headerWrapperProps}>
          {Header
            ? <Header
              {...headerProps}
              className={classNames(Css.modalHeader, headerClassName)}
              titleClass={Css.modalTitle}
              onClose={this.handleCustomClose} />
            : <ModalHeader
              {...headerProps}
              className={classNames(Css.modalHeader, headerClassName)}
              titleClass={Css.modalTitle}>
              {Icon ? <Icon /> : <FiInfo />}
              <span className={Css.text}>
                {headerText || (confirm ? uiTexts.confirm : uiTexts.notification)}
              </span>
              <div className={Css.closeButton} disabled={disabledButtons} onClick={this.handleCloseButtonClick}><FiX /></div>
            </ModalHeader>}
        </div>
        <ModalBody className={classNames(bodyClassName, Css.modalBody)}>
          {text && <div><pre>{text}</pre></div>}
          {typeof children === "function"
            ? children({ active: ModalWindow.activeInstance === this })
            : children}
        </ModalBody>
        <div {...footerWrapperProps}>
          {Footer
            ? <Footer onClose={this.handleCustomClose} className={Css.modalFooter} {...footerProps} />
            : !!(cancelButtonText || confirm) && <ModalFooter {...footerProps} className={Css.modalFooter}>
              {confirm && (
                <Button
                  disabled={disabledButtons || disabledOkButton}
                  onClick={this.handleOkButtonClick}>
                  {okButtonText || uiTexts.ok}
                </Button>
              )}
              <Button
                theme="secondary"
                disabled={disabledButtons || disabledCancelButton}
                onClick={this.handleCancelButtonClick}>
                {cancelButtonText || (confirm ? uiTexts.cancel : uiTexts.close)}
              </Button>
            </ModalFooter>}
        </div>
      </Modal>,
      window.document.getElementById("portal")
    );
  }
}

export default ModalWindow;
