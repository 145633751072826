import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import BrowserEvents from "const/BrowserEvents";
import Portal from "nlib/ui/Portal";
import React, { useCallback, useEffect, useRef } from "react";
import classNames from "classnames";

const { KEY_DOWN } = BrowserEvents;

const SIZES = {
  sm: Css.sm,
  md: Css.md,
  lg: Css.lg,
  xl: Css.xl
};

const Modal = (props) => {
  const {
    iconComponent: Icon = Icons.Info,
    notClosable,
    className,
    dialogClassName,
    headerClassName,
    contentClassName,
    size,
    title,
    children,
    buttons,
    noOverlay,
    disabled,
    onClose,
    onShow,
    onWindowKeyDown,
    ...restProps
  } = props;

  const modalInstance = useRef();

  const handleCloseClick = useCallback(() => {
    if (onClose) onClose();
  }, [onClose]);

  const handleWindowKeyDown = useCallback((event) => {
    const { key } = event;

    if (Modal.getActiveInstance() !== modalInstance) return;

    if (key === "Escape" && onClose) onClose();
    if (onWindowKeyDown) onWindowKeyDown(event);
  }, [onClose, onWindowKeyDown]);

  const handleAnimationEnd = useCallback((event) => {
    if (event.target === event.currentTarget && onShow) {
      onShow();
    }
  }, [onShow]);

  useEffect(() => {
    window.addEventListener(KEY_DOWN, handleWindowKeyDown);

    return () => {
      window.removeEventListener(KEY_DOWN, handleWindowKeyDown);
    };
  }, [handleWindowKeyDown]);

  useEffect(() => {
    Modal.activeInstances.push(modalInstance);
    window.document.body.dataset.modal = "";

    return () => {
      Modal.activeInstances = Modal.activeInstances.filter((item) => item !== modalInstance);
      delete window.document.body.dataset.modal;
    };
  }, []);

  return (
    <Portal>
      <div {...restProps} className={classNames(Css.modal, noOverlay && Css.noOverlay, disabled && Css.disabled, className)}>
        <div className={classNames(Css.dialog, SIZES[size], dialogClassName)} onAnimationEnd={handleAnimationEnd}>
          <div className={classNames(Css.header, headerClassName)}>
            <div className={Css.title} title={title}>
              {Icon && <Icon />}
              <div className={Css.text}>{title}</div>
            </div>
            {!notClosable && <div className={Css.close} onClick={handleCloseClick}>
              <Icons.X />
            </div>}
          </div>
          <div className={classNames(Css.content, contentClassName)}>{children}</div>
          {buttons && (
            <div className={Css.buttons}>{buttons}</div>
          )}
        </div>
      </div>
    </Portal>
  );
};

Modal.activeInstances = [];

Modal.getActiveInstance = () => {
  return Modal.activeInstances[Modal.activeInstances.length - 1];
};

export { default as ModalFooter } from "./lib/ModalFooter";

export default React.memo(Modal);
