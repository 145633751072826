import * as ConnectedReactRouter from "connected-react-router";
import Utils from "utils/Utils";

const initialState = {};

export default (state = initialState, action) => {
  const { type, payload } = action;

  if (type === ConnectedReactRouter.LOCATION_CHANGE) {
    const { location: { search } } = payload;

    return Utils.parseQueryString(search);
  }

  return state;
};
