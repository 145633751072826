import AccountsActions from "actions/AccountsActions";

const {
  FETCH_ACCOUNTS_LIST_START,
  FETCH_SOURCES_LIST_START,
  ADD_NEW_ACCOUNT_START,
  EDIT_ACCOUNT_START,
  DELETE_ACCOUNT_START,
  FETCH_ACCOUNTS_LIST_DONE,
  FETCH_SOURCES_LIST_DONE,
  EDIT_ACCOUNT_DONE,
  DELETE_ACCOUNT_DONE,
  ADD_NEW_ACCOUNT_DONE,
  FETCH_ACCOUNTS_LIST_ERROR,
  ADD_NEW_ACCOUNT_ERROR,
  EDIT_ACCOUNT_ERROR,
  DELETE_ACCOUNT_ERROR,
  FETCH_SOURCES_LIST_ERROR
} = AccountsActions;

const initialState = {
  fetchingData: false,
  fetchingSources: false,
  dataHash: null,
  sources: [],
  data: []
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_ACCOUNTS_LIST_START:
      return {
        ...state,
        ...(payload.clearList ? { data: [], dataHash: null } : {}),
        fetchingData: !payload.backgroundUpdate
      };

    case FETCH_SOURCES_LIST_START:
      return { ...state, fetchingSources: true };

    case ADD_NEW_ACCOUNT_START:
    case EDIT_ACCOUNT_START:
    case DELETE_ACCOUNT_START:
      return { ...state, fetchingData: true };

    case FETCH_ACCOUNTS_LIST_DONE:
      return {
        ...state,
        ...(!payload.dataHash || payload.dataHash !== state.dataHash ? {
          data: payload.accounts,
          dataHash: payload.dataHash || null
        } : {}),
        fetchingData: false
      };

    case FETCH_SOURCES_LIST_DONE:
      return {
        ...state,
        sources: [...payload.sources].sort(({ name: sourceA }, { name: sourceB }) => sourceA.localeCompare(sourceB)),
        fetchingSources: false
      };

    case EDIT_ACCOUNT_DONE: {
      const index = state.data.findIndex(({ id }) => id === payload.account.id);

      const newData = [...state.data];

      newData[index] = payload.account;

      return { ...state, data: newData, fetchingData: false };
    }
    case DELETE_ACCOUNT_DONE:
      return { ...state, data: state.data.filter(({ id }) => id !== payload.accountId), fetchingData: false };

    case ADD_NEW_ACCOUNT_DONE:
      return {
        ...state,
        data: [...state.data, payload.account],
        fetchingData: false
      };

    case FETCH_ACCOUNTS_LIST_ERROR:
    case ADD_NEW_ACCOUNT_ERROR:
    case EDIT_ACCOUNT_ERROR:
    case DELETE_ACCOUNT_ERROR:
      return { ...state, fetchingData: false };

    case FETCH_SOURCES_LIST_ERROR:
      return { ...state, fetchingSources: false };

    default:
      return state;
  }
};
