import AuthZeroApi from "api/AuthZeroApi";
import MainApi from "api/MainApi";
import RestApi from "api/RestApi";
import Utils from "utils/Utils";

const { AUTH_ZERO_DOMAIN, AUTH_ZERO_AUDIENCE, AUTH_ZERO_CLIENT_ID, MAIN_API_URL } = Utils.getProcessEnvVars();

export const authZeroApi = new AuthZeroApi(AUTH_ZERO_DOMAIN, AUTH_ZERO_AUDIENCE, AUTH_ZERO_CLIENT_ID);

export const fileLoadingApi = new RestApi();

export const mainApi = new MainApi(MAIN_API_URL);

export default {
  authZeroApi,
  mainApi
};
