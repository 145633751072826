
import { useEffect, useRef } from "react";

const usePreviousValue = (value) => {
  const previousValue = useRef();

  useEffect(() => {
    previousValue.current = value;
  });

  return previousValue.current;
};

export default usePreviousValue;
