// TODO: Replace this component by own logic or find more suitable lib
import Css from "./style.module.scss";

import { bind } from "decko";
import { v4 as uuid } from "uuid";
import BrowserEvents from "const/BrowserEvents";
import React, { PureComponent } from "react";
import camelCase from "camelcase";
import classNames from "classnames";
import tagsInput from "tags-input";

const CONTAINER_CLASS_NAME = "tags-input";

const FORM_CONTROL_CLASS_NAME = "form-control";

const DELETE_KEY_CODE = 46;

const { CLICK, FOCUS, BLUR, INPUT, CHANGE, KEY_DOWN } = BrowserEvents;

export default class TagsInput extends PureComponent {
  dataListId = null;

  inputElementRef = null;

  tagsContainerElement = null;

  tagInputElement = null;

  tags = null;

  constructor(props) {
    super(props);
    this.dataListId = uuid();
    this.inputElementRef = React.createRef();
  }

  get dataset() {
    return Object.entries(this.props)
      .filter(([key]) => key.startsWith("data-"))
      .reduce((aggregator, [propKey, propValue]) => ({
        ...aggregator,
        [camelCase(propKey.split("-").slice(1).join("-"))]: propValue
      }), {});
  }

  @bind
  toggleDisabledAttribute() {
    if (!this.tagsContainerElement || !this.tagInputElement) return;
    if (this.props.disabled) {
      this.tagsContainerElement.setAttribute("disabled", "");
      this.tagInputElement.setAttribute("disabled", "");
    } else {
      this.tagsContainerElement.removeAttribute("disabled");
      this.tagInputElement.removeAttribute("disabled");
    }
  }

  @bind
  toggleInvalidClassName() {
    const { invalid } = this.props;

    this.tagsContainerElement.classList.toggle("is-invalid", !!invalid);
    this.tagInputElement.classList.toggle("is-invalid", !!invalid);
  }

  @bind
  updateTags() {
    this.tags = this.tagsContainerElement.querySelectorAll(".tag");
    [...this.tags].forEach((tag) => {
      tag.setAttribute("title", tag.textContent);
      tag.removeEventListener(CLICK, this.handleTagClick);
      tag.addEventListener(CLICK, this.handleTagClick);
    });
  }

  componentDidMount() {
    const { className, type } = this.props;

    const { current: inputElement } = this.inputElementRef;

    const containerClassName = classNames(CONTAINER_CLASS_NAME, FORM_CONTROL_CLASS_NAME, className);

    tagsInput(inputElement);
    this.tagsContainerElement = inputElement.nextSibling;
    this.tagsContainerElement.className = containerClassName;
    this.tagsContainerElement.setAttribute("tabindex", 0);
    this.tagsContainerElement.addEventListener(FOCUS, this.handleTagsContainerFocus);
    this.tagInputElement = this.tagsContainerElement.querySelector("input");
    this.tagInputElement.className = FORM_CONTROL_CLASS_NAME;
    this.tagInputElement.setAttribute("list", this.dataListId);
    this.tagInputElement.setAttribute("autocomplete", "one-time-code");
    this.tagInputElement.setAttribute("type", type);
    this.tagInputElement.setAttribute("name", `tagInput${Date.now()}`);
    this.tagInputElement.addEventListener(INPUT, this.handleTagsInputInput);
    this.tagInputElement.addEventListener(BLUR, this.handleTagsInputBlur);
    this.tagInputElement.addEventListener(KEY_DOWN, this.handleTagsInputKeyDown);
    inputElement.removeAttribute("style");
    inputElement.style.display = "none";
    inputElement.addEventListener(INPUT, this.handleSourceInputChange);
    inputElement.addEventListener(CHANGE, this.handleSourceInputChange);
    this.toggleDisabledAttribute();
    this.toggleInvalidClassName();
    this.updateTags();
  }

  componentDidUpdate(prevProps) {
    this.updateTags();
    if (this.props.disabled !== prevProps.disabled) this.toggleDisabledAttribute();
    if (this.props.invalid !== prevProps.invalid) this.toggleInvalidClassName();
  }

  componentWillUnmount() {
    const { current: inputElement } = this.inputElementRef;

    inputElement.removeEventListener(INPUT, this.handleSourceInputChange);
    inputElement.removeEventListener(CHANGE, this.handleSourceInputChange);
    this.tagInputElement.addEventListener(KEY_DOWN, this.handleTagsInputKeyDown);
    this.tagInputElement.addEventListener(INPUT, this.handleTagsInputInput);
    this.tagInputElement.addEventListener(BLUR, this.handleTagsInputBlur);
    this.tagsContainerElement.removeEventListener(FOCUS, this.handleTagsContainerFocus);
    this.tagsContainerElement.parentNode.removeChild(this.tagsContainerElement);
    [...this.tags].forEach((tag) => tag.addEventListener(CLICK, this.handleTagClick));
  }

  @bind
  handleTagsContainerFocus() {
    this.tagInputElement.focus();
  }

  @bind
  handleTagsInputInput({ target }) {
    const { autoCompleteData } = this.props;

    target.value = target.value.replace(/"/g, "");
    if (autoCompleteData && autoCompleteData.includes(target.value)) {
      target.blur();
      target.focus();
    }
  }

  @bind
  handleTagsInputBlur() {
    const { value, onBlur } = this.props;

    if (onBlur) onBlur({ target: { value, dataset: this.dataset } });
  }

  @bind
  handleTagsInputKeyDown({ keyCode }) {
    if (keyCode === DELETE_KEY_CODE) {
      [...this.tags].forEach((tag) => tag.classList.remove("selected"));
    }
  }

  @bind
  handleTagClick({ target }) {
    target.classList.add("selected");
    this.tagInputElement.dispatchEvent(new KeyboardEvent(KEY_DOWN, { keyCode: DELETE_KEY_CODE }));
  }

  @bind
  handleSourceInputChange({ target: { value } }) {
    this.props.onChange({
      target: {
        dataset: this.dataset,
        value: value.length ? [...new Set(value.split(","))].map((tag) => tag.trim()).filter((tag) => tag) : []
      }
    });
  }

  render() {
    let { invalid, value, autoCompleteData, onChange, ...restProps } = this.props;

    if (autoCompleteData) autoCompleteData = autoCompleteData.filter((tag) => !value.includes(tag));

    return (
      <>
        {autoCompleteData && <datalist id={this.dataListId}>
          {autoCompleteData.map((tag) => <option key={tag} value={tag} />)}
        </datalist>}
        <input {...restProps} readOnly type="tags" value={value} className={Css.tagsInput} ref={this.inputElementRef} />
      </>
    );
  }
}
