import ReportsActions from "actions/ReportsActions";

const {
  EXPORT_REPORT_START,
  FETCH_REPORTS_LIST_START,
  EXPORT_REPORT_DONE,
  FETCH_REPORTS_LIST_DONE,
  EXPORT_REPORT_ERROR,
  FETCH_REPORTS_LIST_ERROR
} = ReportsActions;

const initialState = {
  fetchingData: false,
  uniqueYears: [],
  data: []
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case EXPORT_REPORT_START:
      return { ...state, fetchingData: true };

    case FETCH_REPORTS_LIST_START:
      return {
        ...state,
        uniqueYears: payload.init ? [] : state.uniqueYears,
        data: payload.init ? [] : state.data,
        fetchingData: true
      };

    case FETCH_REPORTS_LIST_DONE:
      return { ...state, uniqueYears: payload.uniqueYears, data: payload.reports, fetchingData: false };

    case EXPORT_REPORT_DONE:
    case EXPORT_REPORT_ERROR:
    case FETCH_REPORTS_LIST_ERROR:
      return { ...state, fetchingData: false };

    default:
      return state;
  }
};
