import { getSelectedBusinessId } from "selectors/businesses";
import { mainApi } from "api";
import { toast } from "react-toastify";
import MainApiRoutes from "const/MainApiRoutes";

export default class PreAccountingActions {
  static FETCH_PRE_ACCOUNTING_LIST_START = "preAccounting/FETCH_PRE_ACCOUNTING_LIST_START";

  static FETCH_PRE_ACCOUNTING_LIST_DONE = "preAccounting/FETCH_PRE_ACCOUNTING_LIST_DONE";

  static FETCH_PRE_ACCOUNTING_LIST_ERROR = "preAccounting/FETCH_PRE_ACCOUNTING_LIST_ERROR";

  static CREATE_PRE_ACCOUNTING_START = "preAccounting/CREATE_PRE_ACCOUNTING_START";

  static CREATE_PRE_ACCOUNTING_DONE = "preAccounting/CREATE_PRE_ACCOUNTING_DONE";

  static CREATE_PRE_ACCOUNTING_ERROR = "preAccounting/CREATE_PRE_ACCOUNTING_ERROR";

  static UPDATE_PRE_ACCOUNTING_START = "preAccounting/UPDATE_PRE_ACCOUNTING_START";

  static UPDATE_PRE_ACCOUNTING_DONE = "preAccounting/UPDATE_PRE_ACCOUNTING_DONE";

  static UPDATE_PRE_ACCOUNTING_ERROR = "preAccounting/UPDATE_PRE_ACCOUNTING_ERROR";

  static DELETE_PRE_ACCOUNTING_START = "preAccounting/DELETE_PRE_ACCOUNTING_START";

  static DELETE_PRE_ACCOUNTING_DONE = "preAccounting/DELETE_PRE_ACCOUNTING_DONE";

  static DELETE_PRE_ACCOUNTING_ERROR = "preAccounting/DELETE_PRE_ACCOUNTING_ERROR";

  static fetch(clearList = false, backgroundUpdate = false) {
    return async(dispatch, getState) => {
      dispatch({ type: PreAccountingActions.FETCH_PRE_ACCOUNTING_LIST_START, payload: { clearList, backgroundUpdate } });

      const businessId = getSelectedBusinessId(getState());

      const { BUSINESSES, PRE_ACCOUNTING } = MainApiRoutes;

      const results = await mainApi.get(`${BUSINESSES}/${businessId}${PRE_ACCOUNTING}`);

      if (Array.isArray(results) && results.length) {
        dispatch({ type: PreAccountingActions.FETCH_PRE_ACCOUNTING_LIST_DONE, payload: results });

        return results;
      }
      dispatch({ type: PreAccountingActions.FETCH_PRE_ACCOUNTING_LIST_ERROR });
      toast.error("Error while loading pre-accounting");

      return null;
    };
  }

  static create(payload) {
    return async(dispatch, getState) => {
      dispatch({ type: PreAccountingActions.CREATE_PRE_ACCOUNTING_START });

      const businessId = getSelectedBusinessId(getState());

      const { BUSINESSES, PRE_ACCOUNTING } = MainApiRoutes;

      const result = await mainApi.put(`${BUSINESSES}/${businessId}${PRE_ACCOUNTING}`, null, payload);

      if (result.length) {
        dispatch({ type: PreAccountingActions.CREATE_PRE_ACCOUNTING_DONE, payload: result });

        return result;
      }
      dispatch({ type: PreAccountingActions.CREATE_PRE_ACCOUNTING_ERROR });
      toast.error("Error while adding pre-accounting");

      return null;
    };
  }

  static update(payload) {
    return async(dispatch, getState) => {
      dispatch({ type: PreAccountingActions.UPDATE_PRE_ACCOUNTING_START });

      const businessId = getSelectedBusinessId(getState());

      const { BUSINESSES, PRE_ACCOUNTING } = MainApiRoutes;

      const result = await mainApi.post(`${BUSINESSES}/${businessId}${PRE_ACCOUNTING}`, null, payload);

      if (result.length) {
        dispatch({ type: PreAccountingActions.UPDATE_PRE_ACCOUNTING_DONE, payload: result });

        return result;
      }
      dispatch({ type: PreAccountingActions.UPDATE_PRE_ACCOUNTING_ERROR });
      toast.error("Error while editing pre-accounting");

      return null;
    };
  }

  static delete(payload) {
    return async(dispatch, getState) => {
      dispatch({ type: PreAccountingActions.DELETE_PRE_ACCOUNTING_START });

      const businessId = getSelectedBusinessId(getState());

      const { BUSINESSES, PRE_ACCOUNTING } = MainApiRoutes;

      const result = await mainApi.post(`${BUSINESSES}/${businessId}${PRE_ACCOUNTING}`, null, payload);

      if (result) {
        dispatch({ type: PreAccountingActions.DELETE_PRE_ACCOUNTING_DONE, payload });

        return true;
      }
      dispatch({ type: PreAccountingActions.DELETE_PRE_ACCOUNTING_ERROR });
      toast.error("Error while deleting pre-accounting");

      return null;
    };
  }
}
