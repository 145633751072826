import CommonCss from "lib/common/style.module.scss";

import Css from "./style.module.scss";

import { Preloader } from "lib/common";
import { bind } from "decko";
import { connect } from "react-redux";
import Lightbox from "react-image-lightbox";
import React, { PureComponent } from "react";
import UiActions from "actions/UiActions";
import classNames from "classnames";

const mapDispatchToProps = (dispatch) => ({
  hideModalImages: () => dispatch(UiActions.hideModalImages())
});

@connect(null, mapDispatchToProps)
class ImageViewer extends PureComponent {
  state = { imageIndex: 0, imageLoaded: false };

  @bind
  handleClose() {
    this.props.hideModalImages();
  }

  @bind
  handleImageLoaded() {
    this.setState({ imageLoaded: true });
  }

  @bind
  handleMovePrev() {
    this.setState(({ imageIndex }) => ({ imageIndex: imageIndex - 1 }));
  }

  @bind
  handleMoveNext() {
    this.setState(({ imageIndex }) => ({ imageIndex: imageIndex + 1 }));
  }

  render() {
    const { sources } = this.props;

    const { imageIndex, imageLoaded } = this.state;

    return (
      <>
        <Lightbox
          animationDuration={150}
          mainSrc={sources[imageIndex]}
          nextSrc={sources[imageIndex + 1]}
          prevSrc={sources[imageIndex - 1]}
          reactModalProps={{ overlayClassName: classNames(Css.imageViewer, CommonCss.zIndexB) }}
          onCloseRequest={this.handleClose}
          onImageLoad={this.handleImageLoaded}
          onImageLoadError={this.handleImageLoaded}
          onMovePrevRequest={this.handleMovePrev}
          onMoveNextRequest={this.handleMoveNext} />
        {!imageLoaded && <Preloader fixed className={CommonCss.zIndexC} />}
      </>
    );
  }
}

export default ImageViewer;
