import Css from "./style.module.scss";

import { Card as ShardsCard } from "shards-react";
import React from "react";
import classNames from "classnames";

const Card = ({ children, className, ...restProps }) => (
  <ShardsCard className={classNames(className, Css.card)} {...restProps}>
    {children}
  </ShardsCard>
);

export default React.memo(Card);
