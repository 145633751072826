import { createSelector } from "reselect";
import { getActiveUsersData } from "selectors/organizations";

export const checkArchiveFetching = ({ archive: { fetchingData } }) => fetchingData;

export const getUploadingArchiveFilesCount = ({ archive: { uploadingCount } }) => uploadingCount;

export const getArchiveData = createSelector(
  [
    ({ archive: { data } }) => data,
    getActiveUsersData
  ],
  (data, users) => {
    return data.map(({ createdBy, ...restData }) => {
      const user = users.find(({ sub }) => sub === createdBy) || null;

      return { ...restData, createdBy: user };
    });
  }
);
