import { getSelectedBusinessId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { mainApi } from "api";
import { toast } from "react-toastify";
import MainApiRoutes from "const/MainApiRoutes";
import Utils from "utils/Utils";

export default class ReportsActions {
  static EXPORT_REPORT_START = "reports/EXPORT_REPORT_START";

  static EXPORT_REPORT_DONE = "reports/EXPORT_REPORT_DONE";

  static EXPORT_REPORT_ERROR = "reports/EXPORT_REPORT_ERROR";

  static FETCH_REPORTS_LIST_START = "reports/FETCH_REPORTS_LIST_START";

  static FETCH_REPORTS_LIST_DONE = "reports/FETCH_REPORTS_LIST_DONE";

  static FETCH_REPORTS_LIST_ERROR = "reports/FETCH_REPORTS_LIST_ERROR";

  static exportReport(queryParams, body = {}) {
    return async(dispatch, getState) => {
      dispatch({ type: ReportsActions.EXPORT_REPORT_START, payload: { queryParams } });

      const { BUSINESSES, REPORTS, EXPORT } = MainApiRoutes;

      const selectedBusinessId = getSelectedBusinessId(getState());

      const { errors, messages } = getTextsData(getState());

      const query = Utils.objectToQueryString(queryParams);

      const path = `${BUSINESSES}/${selectedBusinessId + REPORTS + EXPORT}?${query}`;

      const { link, ok, errorMessage } = await mainApi.post(path, null, body);

      if (link) {
        Utils.downloadContent(link);
        dispatch({ type: ReportsActions.EXPORT_REPORT_DONE });

        return true;
      } else if (ok) {
        dispatch({ type: ReportsActions.EXPORT_REPORT_DONE });
        toast.success(messages.reportExported);

        return true;
      }

      dispatch({ type: ReportsActions.EXPORT_REPORT_ERROR });
      toast.error(errorMessage || errors.whileExportingReport);

      return false;
    };
  }

  static fetchReportsList(filters, init = false) {
    return async(dispatch, getState) => {
      dispatch({ type: ReportsActions.FETCH_REPORTS_LIST_START, payload: { init } });

      const { BUSINESSES, REPORTS } = MainApiRoutes;

      const selectedBusinessId = getSelectedBusinessId(getState());

      const { errors } = getTextsData(getState());

      const path = `${BUSINESSES}/${selectedBusinessId}${REPORTS}`;

      const { results: reports, uniqueYears } = await mainApi.get(path, filters);

      if (Array.isArray(reports)) {
        dispatch({ type: ReportsActions.FETCH_REPORTS_LIST_DONE, payload: { reports, uniqueYears } });

        return reports;
      }
      dispatch({ type: ReportsActions.FETCH_REPORTS_LIST_ERROR });
      toast.error(errors.whileLoadingReports);

      return null;
    };
  }
}
