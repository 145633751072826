import "moment/locale/cs";
import "moment/locale/ru";

import fallbackTexts from "assets/fallbackTexts.json";

import * as ReactDatePicker from "react-datepicker";
import { cs as dateFnsLocaleCs } from "date-fns/locale";
import { getLanguage, getTextsData } from "selectors/texts";
import { toast } from "react-toastify";
import Constants from "const/Constants";
import Errors from "const/Errors";
import Utils from "utils/Utils";
import moment from "moment";

const DATE_FNS_LOCALES = { en: Constants.LANGUAGES.EN.code, cs: dateFnsLocaleCs };

export default class TextsActions {
  static SET_APP_LANGUAGE = "texts/SET_APP_LANGUAGE";

  static FETCH_TEXTS_START = "texts/FETCH_TEXTS_START";

  static FETCH_TEXTS_DONE = "texts/FETCH_TEXTS_DONE";

  static FETCH_TEXTS_ERROR = "texts/FETCH_TEXTS_ERROR";

  static setAppLanguage(language, replacements = null) {
    const firstLoad = !language;

    return async(dispatch, getState) => {
      if (firstLoad) {
        const { LANGUAGES, DEFAULT_LANGUAGE } = Constants;

        language = Utils.storageValue(Constants.LS_KEYS.PREFERRED_LANG) || (window.navigator.language || "").split("-")[0];
        if (!Object.values(LANGUAGES).some(({ code }) => code === language)) language = DEFAULT_LANGUAGE;
      }

      const { texts } = getState();

      const action = { type: TextsActions.SET_APP_LANGUAGE, payload: { language } };

      if (texts[language]) dispatch(action);
      else {
        const result = await dispatch(TextsActions.fetchTexts(language, replacements, firstLoad));

        Utils.storageValue(Constants.LS_KEYS.PREFERRED_LANG, result ? result.language : null);
        if (result) {
          dispatch(action);
        } else if (firstLoad) throw Errors.CRITICAL_DATA_NOT_LOADED;
      }
      ReactDatePicker.setDefaultLocale(DATE_FNS_LOCALES[language]);
      moment.locale(language);

      return language;
    };
  }

  static fetchTexts(language, replacements) {
    // TODO: Extract text to external service like POEditor and fetch data by their API
    return async(dispatch, getState) => {
      dispatch({ type: TextsActions.FETCH_TEXTS_START, payload: { language } });
      try {
        let { default: textsData } = await import("assets/texts/" + language + ".json"); //eslint-disable-line

        if (replacements) textsData = JSON.parse(Utils.replaceTextVars(JSON.stringify(textsData), replacements));
        dispatch({ type: TextsActions.FETCH_TEXTS_DONE, payload: { language, textsData } });

        return { language, textsData };
      } catch (error) {
        const currentLanguage = getLanguage(getState());

        const textsData = getTextsData(getState());

        dispatch({ type: TextsActions.FETCH_TEXTS_ERROR });
        toast.error((textsData[currentLanguage] || fallbackTexts).errors.loadingTexts);

        return null;
      }
    };
  }
}
