import Css from "./style.module.scss";

import { Badge, DebounceInput, DropDownSelect } from "lib/common";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import BrowserEvents from "const/BrowserEvents";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Utils from "utils/Utils";
import classNames from "classnames";

const AutoCompleteInput = (
  {
    noItemsTitle,
    component,
    right,
    dropup,
    name,
    noFocusHold,
    hideCaret,
    triggerChar,
    triggerLength,
    className,
    inputClassName,
    placeholder,
    similarityCoef,
    selectOnFocus = true,
    value = "",
    valid,
    invalid,
    disabled,
    readOnly,
    options = [],
    toggleProps,
    tooltip,
    getOptions,
    onFocus,
    onBlur,
    onKeyDown,
    onChange,
    onInputComplete,
    onAutoComplete,
    onAddNewItemClick,
    onToggleFocus,
    ...restProps
  },
  forwardedRef
) => {
  const { uiTexts } = useSelector(getTextsData);

  const innerRef = useRef();

  const [focused, setFocused] = useState(false);

  const [holdFocus, setHoldFocus] = useState(false);

  const [showAll, setShowAll] = useState(false);

  const [dropDownExpanded, setDropDownExpanded] = useState(false);

  const uniqueId = useMemo(() => uuid(), []);

  const triggerCharPattern = useMemo(() => new RegExp(`${triggerChar}(\\S*)$`), [triggerChar]);

  const addNewItemOptionValue = useMemo(() => ({}), []);

  const dropDownItems = useMemo(() => {
    if (getOptions && !dropDownExpanded) return [];

    return (getOptions ? getOptions() : options).filter((item) => {
      let itemValue = (item?.label ? item.value : item) || "";

      if (Array.isArray(itemValue)) ([itemValue] = itemValue);

      const localValue = value || "";

      if (showAll) return !!itemValue;

      const triggerMatch = triggerChar && localValue.match(triggerCharPattern);

      const searchString = triggerMatch ? (triggerMatch[1] || "") : localValue;

      const itemLowered = itemValue.trim().toLowerCase();

      const localLowered = localValue.trim().toLowerCase();

      if (itemLowered === localLowered) return true;

      const similarityCheck = similarityCoef && searchString && itemValue
      && Utils.checkSimilarityBy({ value: searchString }, { value: itemValue }, similarityCoef);

      if (similarityCheck) return true;

      const searchLowered = searchString.toLowerCase();

      if (itemLowered.includes(searchLowered) && (itemLowered !== searchLowered)) return true;

      return item?.children?.some((str) => {
        return str.toLowerCase().includes(localLowered);
      });
    });
  }, [dropDownExpanded, getOptions, options, value, showAll, triggerChar, triggerCharPattern, similarityCoef]);

  const dropDownOptions = useMemo(() => {
    if (!dropDownExpanded) return [];

    const result = dropDownItems.map((item) => {
      if (item.label) return item;
      if (!Array.isArray(item)) item = [item];

      const [label, group] = item;

      return {
        value: label,
        title: label,
        label: <span>
          <span>{label}</span>
          {group && <Badge value={group} title={group} className={Css.badge} />}
        </span>
      };
    });

    if (onAddNewItemClick) {
      result.unshift({
        value: addNewItemOptionValue,
        label: (
          <span className={Css.addNewItemButton}>
            {`+ ${uiTexts.add} ${value || uiTexts.new.toLowerCase()}`}
          </span>
        )
      });
    }

    return result;
  }, [dropDownExpanded, dropDownItems, onAddNewItemClick, addNewItemOptionValue, uiTexts, value]);

  const inputRef = forwardedRef || innerRef;

  const handleItemSelect = useCallback((item) => {
    if (item === addNewItemOptionValue) {
      onAddNewItemClick();
    } else {
      const newValue = `${value.slice(0, value.lastIndexOf(triggerChar) + 1) + item}${triggerChar ? " " : ""}`;

      const eventData = { target: { value: newValue, dataset: inputRef.current.dataset } };

      onChange(eventData);

      if (onAutoComplete) onAutoComplete(eventData, item);
      if (!noFocusHold) setHoldFocus(true);
    }
    setFocused(false);
    setDropDownExpanded(false);
  }, [value, triggerChar, noFocusHold, inputRef, addNewItemOptionValue, onChange, onAutoComplete, onAddNewItemClick]);

  const handleInputChange = useCallback(({ target }) => {
    if (!triggerChar) setDropDownExpanded(target.value.length >= (triggerLength || 0));
    onChange({ target });
  }, [triggerChar, triggerLength, onChange]);

  const handleInputInputComplete = useCallback(() => {
    if (onInputComplete) onInputComplete(value);
  }, [value, onInputComplete]);

  const handleInputFocus = useCallback(() => {
    setFocused(true);
    setShowAll(false);
    if (!holdFocus && !triggerChar && value.length >= (triggerLength || 0)
      && (getOptions || dropDownItems.length)) {
      setDropDownExpanded(true);
    }
    if (inputRef.current && selectOnFocus) inputRef.current.select();
    if (onFocus) onFocus();
    if (onToggleFocus) onToggleFocus(true);
  }, [
    holdFocus,
    triggerChar,
    value.length,
    triggerLength,
    dropDownItems.length,
    inputRef,
    selectOnFocus,
    getOptions,
    onFocus,
    onToggleFocus
  ]);

  const handleInputBlur = useCallback((event) => {
    setFocused(false);
    if (holdFocus) {
      inputRef.current.focus();
      setHoldFocus(false);
    } else if (onAutoComplete) onAutoComplete(event, value);
    if (onBlur) onBlur(event);
    if (onToggleFocus) onToggleFocus(false);
  }, [value, holdFocus, inputRef, onAutoComplete, onBlur, onToggleFocus]);

  const handleWindowMouseDown = useCallback((event) => {
    let parent = event.target;

    const [dropDownSelectElement] = document.getElementsByClassName(uniqueId);

    // eslint-disable-next-line no-loops/no-loops
    while (parent) {
      if (parent.isSameNode(dropDownSelectElement)) return;
      parent = parent.parentNode;
    }

    setDropDownExpanded(false);
  }, [uniqueId]);

  const handleCaretClick = useCallback(() => {
    setShowAll(!dropDownExpanded);
    setDropDownExpanded(!dropDownExpanded);
  }, [dropDownExpanded]);

  useEffect(() => {
    if (triggerChar) {
      const lastTriggerIndex = value.lastIndexOf(triggerChar);

      const previousTriggerChar = value[lastTriggerIndex - 1];

      if (lastTriggerIndex >= 0 && (!previousTriggerChar || /\s/.test(previousTriggerChar)) && focused) {
        setDropDownExpanded(true);
      }
    }
  }, [dropDownItems, focused, triggerChar, triggerLength, value]);

  useEffect(() => {
    if (triggerLength && !dropDownExpanded) {
      if (value.length >= triggerLength && dropDownItems.length && focused && !holdFocus && !disabled && !readOnly) {
        setDropDownExpanded(true);
      }
    }
  }, [disabled, readOnly, focused, dropDownExpanded, holdFocus, options, triggerLength, value.length, dropDownItems.length]);

  useEffect(() => {
    if (triggerChar && value === "") setDropDownExpanded(false);
  }, [dropDownExpanded, triggerChar, value]);

  useEffect(() => {
    if (!dropDownExpanded) return undefined;
    window.addEventListener(BrowserEvents.MOUSE_DOWN, handleWindowMouseDown);

    return () => {
      window.removeEventListener(BrowserEvents.MOUSE_DOWN, handleWindowMouseDown);
    };
  }, [dropDownExpanded, handleWindowMouseDown]);

  const showCaret = !hideCaret && !disabled && (getOptions || !!options.length) && !triggerChar;

  return (
    <DropDownSelect
      mouseDown
      noItemsTitle={noItemsTitle}
      right={right}
      dropup={dropup}
      selectedValue={value}
      disabled={!!disabled || readOnly}
      className={classNames(
        Css.autoCompleteInput,
        showCaret && Css.showCaret,
        className,
        uniqueId
      )}
      toggleClassName={Css.dropDownToggle}
      menuClassName={Css.dropDownMenu}
      expanded={dropDownExpanded}
      toggleContent={(
        <>
          <DebounceInput
            {...toggleProps}
            name={name}
            component={component}
            placeholder={placeholder}
            disabled={disabled}
            readOnly={readOnly}
            value={value}
            valid={valid && !disabled && !readOnly}
            invalid={!!invalid && !disabled && !readOnly}
            innerRef={inputRef}
            className={classNames(
              Css.input,
              invalid && Css.invalid,
              inputClassName
            )}
            data-tooltip={focused ? undefined : tooltip}
            data-tooltip-delay="600"
            onKeyDown={onKeyDown}
            onChange={handleInputChange}
            onInputComplete={handleInputInputComplete}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            {...restProps} />
          {showCaret && (
            <div
              data-caret
              className={classNames(Css.caret, invalid && Css.invalid)}
              onClick={handleCaretClick}>
              {dropDownExpanded ? <FiChevronUp /> : <FiChevronDown />}
            </div>
          )}
        </>
      )}
      options={dropDownOptions}
      onChange={handleItemSelect} />
  );
};

export default React.memo(React.forwardRef(AutoCompleteInput));
