import { getActiveOrganization } from "selectors/organizations";
import { getLanguage } from "selectors/texts";
import { mainApi } from "api";
import Async from "utils/Async";
import MainApiRoutes from "const/MainApiRoutes";
import Utils from "utils/Utils";

const REQUEST_EXTRA_DELAY = 1000;

export default class StripeActions {
  static CREATE_BILLING_PORTAL_SESSION_START = "stripe/CREATE_BILLING_PORTAL_SESSION_START";

  static CREATE_BILLING_PORTAL_SESSION_DONE = "stripe/CREATE_BILLING_PORTAL_SESSION_DONE";

  static CREATE_BILLING_PORTAL_SESSION_ERROR = "stripe/CREATE_BILLING_PORTAL_SESSION_ERROR";

  static CREATE_SETUP_CHECKOUT_SESSION_START = "stripe/CREATE_SETUP_CHECKOUT_SESSION_START";

  static CREATE_SETUP_CHECKOUT_SESSION_DONE = "stripe/CREATE_SETUP_CHECKOUT_SESSION_DONE";

  static CREATE_SETUP_CHECKOUT_SESSION_ERROR = "stripe/CREATE_SETUP_CHECKOUT_SESSION_ERROR";

  static FULLFILL_CHECKOUT_SESSION_START = "stripe/FULLFILL_CHECKOUT_SESSION_START";

  static FULLFILL_CHECKOUT_SESSION_DONE = "stripe/FULLFILL_CHECKOUT_SESSION_DONE";

  static FULLFILL_CHECKOUT_SESSION_ERROR = "stripe/FULLFILL_CHECKOUT_SESSION_ERROR";

  static createBillingPortalSession() {
    return async(dispatch, getState) => {
      dispatch({ type: StripeActions.CREATE_BILLING_PORTAL_SESSION_START });

      const { STRIPE, BILLING_PORTAL } = MainApiRoutes;

      const locale = getLanguage(getState());

      const { url } = await mainApi.post(STRIPE + BILLING_PORTAL, null, { redirectUrl: window.location.origin, locale });

      await Async.delay(REQUEST_EXTRA_DELAY);

      if (url) {
        dispatch({ type: StripeActions.CREATE_BILLING_PORTAL_SESSION_DONE, payload: { url } });

        return url;
      }
      dispatch({ type: StripeActions.CREATE_BILLING_PORTAL_SESSION_ERROR });

      return null;
    };
  }

  static createSetupCheckoutSession() {
    return async(dispatch, getState) => {
      dispatch({ type: StripeActions.CREATE_SETUP_CHECKOUT_SESSION_START });

      const { STRIPE, CHECKOUT_SESSION, SETUP } = MainApiRoutes;

      const activeOrganization = getActiveOrganization(getState());

      const locale = getLanguage(getState());

      const firstPromoterTid = activeOrganization.firstPromoterTid || Utils.cookieValue("_fprom_tid") || null;

      const { url } = await mainApi.post(
        STRIPE + CHECKOUT_SESSION + SETUP,
        null,
        {
          locale,
          redirectUrl: window.location.origin,
          ...(firstPromoterTid && { firstPromoterTid })
        }
      );

      await Async.delay(REQUEST_EXTRA_DELAY);

      if (url) {
        dispatch({ type: StripeActions.CREATE_SETUP_CHECKOUT_SESSION_DONE, payload: { url } });

        return url;
      }
      dispatch({ type: StripeActions.CREATE_SETUP_CHECKOUT_SESSION_ERROR });

      return null;
    };
  }

  static fullfillCheckoutSession(sessionId) {
    return async(dispatch) => {
      dispatch({ type: StripeActions.FULLFILL_CHECKOUT_SESSION_START });

      const { STRIPE, CHECKOUT_SESSION, FULLFILL } = MainApiRoutes;

      const { ok } = await mainApi.post(`${STRIPE + CHECKOUT_SESSION}/${sessionId + FULLFILL}`);

      await Async.delay(REQUEST_EXTRA_DELAY);

      if (ok) {
        dispatch({ type: StripeActions.FULLFILL_CHECKOUT_SESSION_DONE });

        return true;
      }
      dispatch({ type: StripeActions.FULLFILL_CHECKOUT_SESSION_ERROR });

      return false;
    };
  }
}
