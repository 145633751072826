import { createPortal } from "react-dom";
import { useEffect, useMemo } from "react";

const Portal = ({ children }) => {
  const element = useMemo(() => {
    return document.createElement("div");
  }, []);

  useEffect(() => {
    document.body.appendChild(element);

    return () => {
      document.body.removeChild(element);
    };
  }, [element]);

  return createPortal(children, element);
};

export default Portal;
