import Css from "./style.module.scss";

import { AutoCompleteInput, Form } from "lib/common";
import { Button, Dropdown, DropdownMenu, FormGroup } from "shards-react";
import { FiUser, FiUserPlus, FiX } from "react-icons/fi";
import { getContactsSortedData } from "selectors/contacts";
import { getTextsData } from "selectors/texts";
import { useContactBlock } from "hooks";
import { useDispatch, useSelector } from "react-redux";
import Constants from "const/Constants";
import ContactsActions from "actions/ContactsActions";
import DataConstants from "const/DataConstants";
import React, { memo, useCallback, useMemo, useRef, useState } from "react";
import classNames from "classnames";

const { CONTACT_SUB_TYPES: { VENDOR, CUSTOMER } } = DataConstants;

const NewContactWidget = memo((props) => {
  const {
    dropup,
    name,
    onlyCustomers,
    onlyVendors,
    onClose,
    onAddContact
  } = props;

  const dispatch = useDispatch();

  const { uiTexts } = useSelector(getTextsData);

  const [disabled, setDisabled] = useState(false);

  const prefillData = useMemo(() => {
    return { name, subType: "" };
  }, [name]);

  const { state, ui } = useContactBlock({ prefillData, disabled, disabledSubTypeInput: !!(onlyCustomers || onlyVendors) });

  const handleSaveButtonClick = useCallback(async() => {
    setDisabled(true);

    const contact = await dispatch(ContactsActions.addNewContact(state));

    setDisabled(false);

    if (!contact) return;
    if (onAddContact) onAddContact(contact);

    onClose();
  }, [state, dispatch, onAddContact, onClose]);

  return (
    <Dropdown open dropup={dropup} toggle={onClose}>
      <DropdownMenu className={Css.newContactWidget}>
        <div className={Css.title}>
          <FiUserPlus />
          <span>{uiTexts.addNewContact}</span>
        </div>
        <Form>
          <FormGroup>{ui.nameInputGroup}</FormGroup>
        </Form>
        <div className={Css.buttonWrapper}>
          <Button
            theme="primary"
            size="sm"
            disabled={disabled || !(state.name || "").trim()}
            onClick={handleSaveButtonClick}>
            {uiTexts.addContact}
          </Button>
          <Button
            theme="danger"
            size="sm"
            disabled={disabled}
            onClick={onClose}>
            {uiTexts.close}
          </Button>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
});

const SelectContactInput = (props) => {
  const [widgetShownInner, setWidgetShownInner] = useState(false);

  const {
    name,
    right,
    dropup,
    vendorId,
    value,
    invalid,
    valid,
    highlight,
    warning,
    disabled,
    onlyCustomers,
    onlyVendors,
    className,
    inputClassName,
    buttonClassName,
    useWidget,
    toggleButton,
    noButton,
    inputProps,
    probablyVendor = onlyVendors,
    useTooltip,
    widgetShown = widgetShownInner,
    setWidgetShown = setWidgetShownInner,
    customFilter,
    onChange,
    onAutoComplete,
    onContactOpen
  } = props;

  const inputRef = useRef();

  const { uiTexts } = useSelector(getTextsData);

  const contactsData = useSelector(getContactsSortedData);

  const [firstContact] = contactsData;

  const getOptions = useCallback(() => {
    const data = customFilter ? customFilter(contactsData) : contactsData;

    return data.filter(({ subType }) => {
      return !subType || (!!onlyCustomers === !!onlyVendors)
        || (onlyCustomers && subType === CUSTOMER) || (onlyVendors && subType === VENDOR);
    })
      .map((contact) => [contact.name, uiTexts[contact.subType] || uiTexts.contact]);
  }, [contactsData, onlyCustomers, onlyVendors, uiTexts, customFilter]);

  const contactsListFlag = firstContact && firstContact.short;

  const handleChange = useCallback((event) => {
    onChange(event, { name: event.target.value });
  }, [onChange]);

  const handleAutoComplete = useCallback((event) => {
    if (onAutoComplete) {
      onAutoComplete(event, contactsData.find((item) => item.name === event.target.value) || {});
    }
  }, [contactsData, onAutoComplete]);

  const handleToggleButtonClick = useCallback(() => {
    if (vendorId) {
      inputRef.current.value = "";
      inputRef.current.focus();
      onChange({ target: inputRef.current }, { name: inputRef.current.value });
    } else if (onContactOpen) {
      onContactOpen({ target: inputRef.current });
    } else {
      setWidgetShown(true);
    }
  }, [vendorId, onChange, onContactOpen, setWidgetShown]);

  const handleContactButtonClick = useCallback(() => {
    if (onContactOpen) {
      onContactOpen({ target: inputRef.current });
    } else {
      setWidgetShown(true);
    }
  }, [onContactOpen, setWidgetShown]);

  const handleAddNewItemClick = useCallback(() => {
    if (useWidget) setWidgetShown(true);
    else if (onContactOpen) onContactOpen({ target: inputRef.current });
  }, [useWidget, inputRef, onContactOpen, setWidgetShown]);

  const handleAutoCompleteInputFocus = useCallback(() => {
    setWidgetShown(false);
  }, [setWidgetShown]);

  const handleNewContactWidgetClose = useCallback(() => {
    setWidgetShown(false);
  }, [setWidgetShown]);

  const handleNewContactWidgetAddNewContact = useCallback((contact) => {
    if (onAutoComplete && inputRef.current) {
      onAutoComplete({
        target: {
          value: contact.name,
          dataset: inputRef.current.dataset
        }
      }, contact);
    }
  }, [onAutoComplete]);

  return (
    <div className={classNames(Css.selectContactInput, className, !noButton && Css.withButton)}>
      {widgetShown && (
        <NewContactWidget
          dropup={dropup}
          name={value}
          probablyVendor={probablyVendor}
          onlyCustomers={onlyCustomers}
          onlyVendors={onlyVendors}
          onClose={handleNewContactWidgetClose}
          onAddContact={handleNewContactWidgetAddNewContact} />
      )}
      <AutoCompleteInput
        {...({ [useTooltip ? "tooltip" : "title"]: value })}
        ref={inputRef}
        name={name}
        right={right}
        dropup={dropup}
        disabled={!!disabled}
        inputClassName={classNames(
          Css.contactInput,
          inputClassName,
          (highlight || warning) && !disabled && (highlight ? Css.highlight : Css.warning)
        )}
        value={value}
        valid={valid}
        invalid={invalid === undefined ? !vendorId : invalid}
        noFocusHold={contactsListFlag}
        hideCaret={contactsListFlag}
        triggerLength={contactsListFlag ? Constants.SEARCH_TEXT_MIN_LENGTH : undefined}
        placeholder={contactsListFlag ? uiTexts.searchContact : uiTexts.selectContact}
        similarityCoef={Constants.DATA_SIMILARITY_COEFFICIENT}
        toggleProps={inputProps}
        getOptions={getOptions}
        onFocus={handleAutoCompleteInputFocus}
        onChange={handleChange}
        onAutoComplete={handleAutoComplete}
        onAddNewItemClick={(!useWidget || vendorId) ? undefined : handleAddNewItemClick} />
      {!noButton && (
        <Button
          outline
          data-contact={vendorId && !disabled ? "" : undefined}
          data-toggle={toggleButton ? "" : undefined}
          theme={vendorId && !disabled ? "success" : (value && !disabled ? "primary" : "secondary")}
          className={classNames(
            Css.contactButton,
            buttonClassName,
            (highlight || warning) && !disabled && (highlight ? Css.highlight : Css.warning)
          )}
          disabled={!!disabled}
          onClick={toggleButton ? handleToggleButtonClick : handleContactButtonClick}>
          {disabled || vendorId ? <><FiUser /><FiX data-clear /></> : <FiUserPlus />}
        </Button>
      )}
    </div>
  );
};

export default React.memo(SelectContactInput);
