import { getSelectedBusinessId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { mainApi } from "api";
import { toast } from "react-toastify";
import BusinessesActions from "./BusinessesActions";
import MainApiRoutes from "const/MainApiRoutes";

export default class CustomReportsActions {
  static FETCH_CUSTOM_REPORTS_LIST_START = "audit/FETCH_CUSTOM_REPORTS_LIST_START";

  static FETCH_CUSTOM_REPORTS_LIST_DONE = "audit/FETCH_CUSTOM_REPORTS_LIST_DONE";

  static FETCH_CUSTOM_REPORTS_LIST_ERROR = "audit/FETCH_CUSTOM_REPORTS_LIST_ERROR";

  static FETCH_CUSTOM_REPORT_START = "audit/FETCH_CUSTOM_REPORT_START";

  static FETCH_CUSTOM_REPORT_DONE = "audit/FETCH_CUSTOM_REPORT_DONE";

  static FETCH_CUSTOM_REPORT_ERROR = "audit/FETCH_CUSTOM_REPORT_ERROR";

  static CREATE_CUSTOM_REPORT_START = "audit/CREATE_CUSTOM_REPORT_START";

  static CREATE_CUSTOM_REPORT_DONE = "audit/CREATE_CUSTOM_REPORT_DONE";

  static CREATE_CUSTOM_REPORT_ERROR = "audit/CREATE_CUSTOM_REPORT_ERROR";

  static UPDATE_CUSTOM_REPORT_START = "audit/UPDATE_CUSTOM_REPORT_START";

  static UPDATE_CUSTOM_REPORT_DONE = "audit/UPDATE_CUSTOM_REPORT_DONE";

  static UPDATE_CUSTOM_REPORT_ERROR = "audit/UPDATE_CUSTOM_REPORT_ERROR";

  static REMOVE_CUSTOM_REPORT_START = "audit/REMOVE_CUSTOM_REPORT_START";

  static REMOVE_CUSTOM_REPORT_DONE = "audit/REMOVE_CUSTOM_REPORT_DONE";

  static REMOVE_CUSTOM_REPORT_ERROR = "audit/REMOVE_CUSTOM_REPORT_ERROR";

  static fetchCustomReportsList({ clearList, backgroundUpdate } = {}) {
    return async(dispatch, getState) => {
      dispatch({
        type: CustomReportsActions.FETCH_CUSTOM_REPORTS_LIST_START,
        payload: { clearList, backgroundUpdate }
      });

      const { BUSINESSES, AUDIT, REPORTS } = MainApiRoutes;

      const state = getState();

      const selectedBusinessId = getSelectedBusinessId(state);

      const { errors } = getTextsData(state);

      const { results } = await mainApi.get(`${BUSINESSES}/${selectedBusinessId}${AUDIT}${REPORTS}`);

      if (Array.isArray(results)) {
        dispatch({
          type: CustomReportsActions.FETCH_CUSTOM_REPORTS_LIST_DONE,
          payload: { data: results }
        });

        return results;
      }

      dispatch({ type: CustomReportsActions.FETCH_CUSTOM_REPORTS_LIST_ERROR });
      if (!backgroundUpdate) toast.error(errors.whileLoadingCustomReports);

      return null;
    };
  }

  static fetchCustomReport(reportId) {
    return async(dispatch, getState) => {
      dispatch({ type: CustomReportsActions.FETCH_CUSTOM_REPORT_START });

      const { BUSINESSES, AUDIT, REPORTS } = MainApiRoutes;

      const state = getState();

      const selectedBusinessId = getSelectedBusinessId(state);

      const { errors } = getTextsData(state);

      const report = await mainApi.get(`${BUSINESSES}/${selectedBusinessId}${AUDIT}${REPORTS}/${reportId}`);

      if (report.id) {
        dispatch({
          type: CustomReportsActions.FETCH_CUSTOM_REPORT_DONE,
          payload: { report }
        });

        return report;
      }

      dispatch({ type: CustomReportsActions.FETCH_CUSTOM_REPORT_ERROR });
      toast.error(errors.whileLoadingCustomReport);

      return null;
    };
  }

  static createCustomReport(payload) {
    return async(dispatch, getState) => {
      dispatch({ type: CustomReportsActions.CREATE_CUSTOM_REPORT_START });

      const { BUSINESSES, AUDIT, REPORTS } = MainApiRoutes;

      const state = getState();

      const selectedBusinessId = getSelectedBusinessId(state);

      const { errors, messages } = getTextsData(state);

      const report = await mainApi.put(`${BUSINESSES}/${selectedBusinessId}${AUDIT}${REPORTS}`, null, payload);

      if (report.id) {
        await dispatch({
          type: CustomReportsActions.CREATE_CUSTOM_REPORT_DONE,
          payload: { report }
        });
        await dispatch(CustomReportsActions.fetchCustomReportsList());
        await dispatch(BusinessesActions.fetchGlobalStats());
        toast.success(messages.customReportCreated);

        return report;
      }

      dispatch({ type: CustomReportsActions.CREATE_CUSTOM_REPORT_ERROR });
      toast.error(errors.whileCreatingCustomReport);

      return null;
    };
  }

  static updateCustomReport(payload) {
    return async(dispatch, getState) => {
      dispatch({ type: CustomReportsActions.UPDATE_CUSTOM_REPORT_START });

      const { BUSINESSES, AUDIT, REPORTS } = MainApiRoutes;

      const state = getState();

      const selectedBusinessId = getSelectedBusinessId(state);

      const { errors, messages } = getTextsData(state);

      const report = await mainApi.patch(`${BUSINESSES}/${selectedBusinessId}${AUDIT}${REPORTS}/${payload.id}`, null, payload);

      if (report.id) {
        dispatch({
          type: CustomReportsActions.UPDATE_CUSTOM_REPORT_DONE,
          payload: { report }
        });
        dispatch(CustomReportsActions.fetchCustomReportsList());
        dispatch(BusinessesActions.fetchGlobalStats());
        toast.success(messages.customReportEdited);

        return report;
      }

      dispatch({ type: CustomReportsActions.UPDATE_CUSTOM_REPORT_ERROR });
      toast.error(errors.whileEditingCustomReport);

      return null;
    };
  }

  static removeCustomReport(reportId) {
    return async(dispatch, getState) => {
      dispatch({ type: CustomReportsActions.REMOVE_CUSTOM_REPORT_START });

      const { BUSINESSES, AUDIT, REPORTS } = MainApiRoutes;

      const state = getState();

      const selectedBusinessId = getSelectedBusinessId(state);

      const { errors, messages } = getTextsData(state);

      const result = await mainApi.delete(`${BUSINESSES}/${selectedBusinessId}${AUDIT}${REPORTS}/${reportId}`);

      if (result) {
        dispatch({ type: CustomReportsActions.REMOVE_CUSTOM_REPORT_DONE, payload: { reportId } });
        dispatch(BusinessesActions.fetchGlobalStats());
        toast.success(messages.customReportDeleted);

        return true;
      }

      dispatch({ type: CustomReportsActions.REMOVE_CUSTOM_REPORT_ERROR });
      toast.error(errors.whileDeletingCustomReport);

      return false;
    };
  }

  static clone(payload) {
    return async(dispatch, getState) => {
      const { BUSINESSES, AUDIT, REPORTS, CLONE } = MainApiRoutes;

      const state = getState();

      const selectedBusinessId = getSelectedBusinessId(state);

      const { errors, messages } = getTextsData(state);

      const result = await mainApi.post(`${BUSINESSES}/${selectedBusinessId}${AUDIT}${REPORTS}${CLONE}`, null, payload);

      if (result) {
        toast.success(messages.customReportDeleted);
        await dispatch(CustomReportsActions.fetchCustomReportsList());
        await dispatch(BusinessesActions.fetchGlobalStats());

        return true;
      }

      toast.error(errors.whileDeletingCustomReport);

      return false;
    };
  }
}
