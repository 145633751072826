// TODO: Update all tables to actual react-table lib version

import Css from "./style.module.scss";

import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import React, { useMemo } from "react";
import ReactTable, { ReactTableDefaults } from "react-table-6";
import classNames from "classnames";

const Table = ({ className, manual, page, pages, pageSize, ...restProps }) => {
  const { uiTexts } = useSelector(getTextsData);

  const columnOptions = useMemo(() => ({ ...ReactTableDefaults.column, minWidth: 1 }), []);

  return (
    <ReactTable
      minRows={0}
      manual={manual}
      defaultPageSize={Constants.TABLE_PAGE_SIZE}
      page={page > pages - 1 ? pages - 1 : page}
      pages={pages}
      pageSize={pageSize}
      className={classNames(className, Css.table)}
      showPagination={false}
      showPageSizeOptions={false}
      resizable={false}
      previousText={uiTexts.previous}
      nextText={uiTexts.next}
      pageText={uiTexts.page}
      rowsText={uiTexts.rows.toLowerCase()}
      ofText={uiTexts.of.toLowerCase()}
      column={columnOptions}
      {...restProps} />
  );
};

export default React.memo(Table);
