import PreAccountingActions from "actions/PreAccountingActions";
import Utils from "utils/Utils";

const {
  FETCH_PRE_ACCOUNTING_LIST_START,
  FETCH_PRE_ACCOUNTING_LIST_DONE,
  FETCH_PRE_ACCOUNTING_LIST_ERROR,
  CREATE_PRE_ACCOUNTING_START,
  CREATE_PRE_ACCOUNTING_DONE,
  CREATE_PRE_ACCOUNTING_ERROR,
  UPDATE_PRE_ACCOUNTING_START,
  UPDATE_PRE_ACCOUNTING_DONE,
  UPDATE_PRE_ACCOUNTING_ERROR,
  DELETE_PRE_ACCOUNTING_START,
  DELETE_PRE_ACCOUNTING_DONE,
  DELETE_PRE_ACCOUNTING_ERROR
} = PreAccountingActions;

const initialState = {
  fetchingData: false,
  data: []
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_PRE_ACCOUNTING_LIST_START: {
      const { clearList = false, backgroundUpdate = false } = payload;

      return {
        ...state,
        fetchingData: !backgroundUpdate,
        data: clearList ? [] : state.data
      };
    }

    case FETCH_PRE_ACCOUNTING_LIST_DONE: {
      return {
        ...state,
        fetchingData: false,
        data: payload
      };
    }

    case CREATE_PRE_ACCOUNTING_START:
    case UPDATE_PRE_ACCOUNTING_START:
    case DELETE_PRE_ACCOUNTING_START:
      return { ...state, fetchingData: true };

    case CREATE_PRE_ACCOUNTING_DONE: {
      return {
        ...state,
        fetchingData: false,
        data: [...state.data, ...payload]
      };
    }

    case UPDATE_PRE_ACCOUNTING_DONE: {
      return {
        ...state,
        fetchingData: false,
        data: state.data.map((item) => Utils.arrayFindById(payload, "id", item.id, item))
      };
    }

    case DELETE_PRE_ACCOUNTING_DONE: {
      const ids = payload.map(({ id }) => id);

      return {
        ...state,
        fetchingData: false,
        data: state.data.filter(({ id }) => !ids.includes(id))
      };
    }

    case FETCH_PRE_ACCOUNTING_LIST_ERROR:
    case CREATE_PRE_ACCOUNTING_ERROR:
    case UPDATE_PRE_ACCOUNTING_ERROR:
    case DELETE_PRE_ACCOUNTING_ERROR:
      return { ...state, fetchingData: false };

    default:
      return state;
  }
};
