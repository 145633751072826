import Css from "./style.module.scss";

import GlobalNotifications from "lib/common/GlobalNotifications";
import React from "react";
import classNames from "classnames";

const Page = ({ children, className, ...restProps }) => (
  <div className={classNames(className, Css.page)} {...restProps}>
    <GlobalNotifications />
    {children}
  </div>
);

export default React.memo(Page);
