import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import React, { useMemo } from "react";
import colorInterpolate from "color-interpolate";

const { NEGATIVE, WARNING, POSITIVE } = Constants.COLORS;

const COLOR_INTENSITY = 0.07;

const GRADIENT_ANGLE = "128deg";

const BACKGROUND_COLOR = "#fff";

const Span = React.memo((props) => <span {...props} />);

const ConfidencePercents = ({
  background,
  backgroundInvert,
  className,
  value = 0,
  children,
  component: Component = Span
}) => {
  const { uiTexts } = useSelector(getTextsData);

  const confidencePercentsPalette = useMemo(() => {
    return colorInterpolate([NEGATIVE, WARNING, POSITIVE]);
  }, []);

  const confidencePercents = useMemo(() => {
    return Math.floor(value * Constants.PERCENTS_MULTIPLIER);
  }, [value]);

  const color = useMemo(() => {
    return confidencePercentsPalette(confidencePercents / Constants.PERCENTS_MULTIPLIER);
  }, [confidencePercentsPalette, confidencePercents]);

  const palette = useMemo(() => {
    return colorInterpolate([BACKGROUND_COLOR, color]);
  }, [color]);

  const backgroundGradient = useMemo(() => {
    return background
    && `linear-gradient(${GRADIENT_ANGLE}, ${BACKGROUND_COLOR}, ${palette(COLOR_INTENSITY)})`;
  }, [background, palette]);

  const style = useMemo(() => ({
    color: backgroundInvert ? BACKGROUND_COLOR : color,
    backgroundColor: backgroundInvert && color,
    backgroundImage: background && backgroundGradient
  }), [background, backgroundInvert, backgroundGradient, color]);

  return (
    <Component
      className={className}
      style={style}>
      {children || (confidencePercents ? `${confidencePercents}%` : uiTexts.low.toLowerCase())}
    </Component>
  );
};

export default React.memo(ConfidencePercents);
