import ContactsActions from "actions/ContactsActions";

const {
  FETCH_CONTACTS_LIST_START,
  FETCH_VENDORS_TAGS_LIST_START,
  FETCH_VENDORS_VALIDATION_STATS_START,
  STOP_VENDORS_VALIDATION_START,
  LOOKUP_CONTACT_DATA_START,
  ADD_NEW_CONTACT_START,
  EDIT_CONTACT_START,
  DELETE_CONTACT_START,
  FETCH_CONTACTS_LIST_DONE,
  FETCH_VENDORS_TAGS_LIST_DONE,
  FETCH_VENDORS_VALIDATION_STATS_DONE,
  STOP_VENDORS_VALIDATION_DONE,
  LOOKUP_CONTACT_DATA_DONE,
  ADD_NEW_CONTACT_DONE,
  EDIT_CONTACT_DONE,
  DELETE_CONTACT_DONE,
  FETCH_CONTACTS_LIST_ERROR,
  FETCH_VENDORS_TAGS_LIST_ERROR,
  FETCH_VENDORS_VALIDATION_STATS_ERROR,
  STOP_VENDORS_VALIDATION_ERROR,
  LOOKUP_CONTACT_DATA_ERROR,
  ADD_NEW_CONTACT_ERROR,
  EDIT_CONTACT_ERROR,
  DELETE_CONTACT_ERROR
} = ContactsActions;

const initialState = {
  fetchingVendorsTags: false,
  fetchingVendorsValidation: false,
  fetchingData: false,
  dataHash: null,
  vendorsValidationHash: null,
  vendorsTags: [],
  vendorsCategories: {},
  vendorsClasses: {},
  vendorsLocations: {},
  vendorsProjects: {},
  vendorsTaxRates: {},
  data: []
};

const sortByName = (data) => {
  return [...data].sort(({ name: nameA }, { name: nameB }) => nameA.localeCompare(nameB));
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_CONTACTS_LIST_START:
      return {
        ...state,
        ...(payload.clearList ? { data: [], dataHash: null } : {}),
        fetchingData: !payload.backgroundUpdate
      };

    case FETCH_VENDORS_TAGS_LIST_START:
      return { ...state, vendorsTags: [], fetchingVendorsTag: true };

    case FETCH_VENDORS_VALIDATION_STATS_START:
      return {
        ...state,
        ...(payload.mergeResults
          ? {}
          : {
            vendorsCategories: [],
            vendorsClasses: [],
            vendorsLocations: [],
            vendorsProjects: [],
            vendorsTaxRates: [],
            vendorsValidationHash: null
          }),
        fetchingVendorsValidation: true
      };

    case STOP_VENDORS_VALIDATION_START:
    case LOOKUP_CONTACT_DATA_START:
    case ADD_NEW_CONTACT_START:
    case EDIT_CONTACT_START:
    case DELETE_CONTACT_START:
      return { ...state, fetchingData: true };

    case FETCH_CONTACTS_LIST_DONE:
      return {
        ...state,
        ...(!payload.dataHash || payload.dataHash !== state.dataHash ? {
          dataHash: payload.dataHash || null,
          data: payload.contacts
        } : {}),
        fetchingData: false
      };

    case FETCH_VENDORS_TAGS_LIST_DONE:
      return { ...state, vendorsTags: payload.vendorsTags, fetchingVendorsTag: false };

    case FETCH_VENDORS_VALIDATION_STATS_DONE: {
      const dataHashChanged = !payload.dataHash || payload.dataHash !== state.vendorsValidationHash;

      return {
        ...state,
        ...(payload.mergeResults && (payload.sparseUpdate || dataHashChanged)
          ? {
            vendorsCategories: { ...state.vendorsCategories, ...payload.vendorsCategories },
            vendorsClasses: { ...state.vendorsClasses, ...payload.vendorsClasses },
            vendorsLocations: { ...state.vendorsLocations, ...payload.vendorsLocations },
            vendorsProjects: { ...state.vendorsProjects, ...payload.vendorsProjects },
            vendorsTaxRates: { ...state.vendorsTaxRates, ...payload.vendorsTaxRates }
          }
          : {
            vendorsCategories: dataHashChanged ? payload.vendorsCategories : state.vendorsCategories,
            vendorsClasses: dataHashChanged ? payload.vendorsClasses : state.vendorsClasses,
            vendorsLocations: dataHashChanged ? payload.vendorsLocations : state.vendorsLocations,
            vendorsProjects: dataHashChanged ? payload.vendorsProjects : state.vendorsProjects,
            vendorsTaxRates: dataHashChanged ? payload.vendorsTaxRates : state.vendorsTaxRates
          }),
        vendorsValidationHash: payload.sparseUpdate ? state.vendorsValidationHash : payload.dataHash || null,
        fetchingVendorsValidation: false
      };
    }
    case ADD_NEW_CONTACT_DONE:
      return {
        ...state,
        data: sortByName([...state.data, payload.contact]),
        fetchingData: false
      };

    case EDIT_CONTACT_DONE:
      return {
        ...state,
        data: sortByName(state.data.map((contact) => {
          return contact.id === payload.contact.id
            ? { ...payload.contact }
            : contact;
        })),
        fetchingData: false
      };

    case DELETE_CONTACT_DONE:
      return {
        ...state,
        data: state.data.filter(({ id }) => id !== payload.contactId),
        fetchingData: false
      };

    case FETCH_VENDORS_TAGS_LIST_ERROR:
      return { ...state, fetchingVendorsTag: false };

    case FETCH_VENDORS_VALIDATION_STATS_ERROR:
      return { ...state, fetchingVendorsValidation: false };

    case STOP_VENDORS_VALIDATION_DONE: {
      const { vendorsCategories, vendorsClasses, vendorsLocations, vendorsProjects, vendorsTaxRates } = state;

      delete vendorsCategories[payload.vendorId];
      delete vendorsClasses[payload.vendorId];
      delete vendorsLocations[payload.vendorId];
      delete vendorsProjects[payload.vendorId];
      delete vendorsTaxRates[payload.vendorId];

      return {
        ...state,
        vendorsCategories: { ...vendorsCategories },
        vendorsClasses: { ...vendorsClasses },
        vendorsLocations: { ...vendorsLocations },
        vendorsProjects: { ...vendorsProjects },
        vendorsTaxRates: { ...vendorsTaxRates },
        fetchingData: false
      };
    }
    case LOOKUP_CONTACT_DATA_DONE:
    case FETCH_CONTACTS_LIST_ERROR:
    case STOP_VENDORS_VALIDATION_ERROR:
    case LOOKUP_CONTACT_DATA_ERROR:
    case ADD_NEW_CONTACT_ERROR:
    case EDIT_CONTACT_ERROR:
    case DELETE_CONTACT_ERROR:
      return { ...state, fetchingData: false };

    default:
      return state;
  }
};
