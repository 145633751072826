import EventsActions from "actions/EventsActions";

const {
  FETCH_EVENTS_BY_TYPE_START,
  FETCH_EVENTS_BY_TYPE_DONE,
  FETCH_EVENTS_BY_TYPE_ERROR
} = EventsActions;

const initialState = {
  fetchingData: false,
  data: {}
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_EVENTS_BY_TYPE_START:
      return { ...state, data: { ...state.data, [payload.type]: [] }, fetchingData: true };

    case FETCH_EVENTS_BY_TYPE_DONE:
      return {
        ...state,
        data: { ...state.data, [payload.type]: payload.events },
        fetchingData: false
      };

    case FETCH_EVENTS_BY_TYPE_ERROR:
      return { ...state, fetchingData: false };

    default:
      return state;
  }
};
