import Css from "./style.module.scss";

import React from "react";
import classNames from "classnames";

const Preloader = ({ className, absolute, fixed, opaque, overlayOnly, children }) => {
  return (
    <div
      className={classNames(Css.preloader, {
        [Css.absolute]: absolute,
        [Css.fixed]: fixed,
        [Css.opaque]: opaque
      }, className)}>
      {!overlayOnly && (
        <div className={Css.animated}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
            <path
              d="M12,22c5.421,0,10-4.579,10-10h-2c0,4.337-3.663,8-8,8s-8-3.663-8-8c0-4.336,
                3.663-8,8-8V2C6.579,2,2,6.58,2,12 C2,17.421,6.579,22,12,22z"
              fill="currentColor" />
          </svg>
        </div>
      )}
      {!!(children || fixed) && <div className={Css.content}>{children || <>&nbsp;</>}</div>}
    </div>
  );
};

export default React.memo(Preloader);
