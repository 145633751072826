import { mainApi } from "api";
import { useCallback, useEffect, useState } from "react";
import RestApi from "api/RestApi";

const useMainApi = ({
  method = RestApi.REQUEST_METHODS.GET,
  initialData = null,
  fetchCondition = true,
  parameters = [],
  dependencies = []
}) => {
  const [fetching, setFetching] = useState(true);

  const [data, setData] = useState(initialData);

  const [error, setError] = useState(null);

  const fetchData = useCallback(async() => {
    setFetching(true);
    setData(initialData);
    setError(null);

    const response = await mainApi.makeRequest(...[method, ...parameters]);

    if (response.hasOwnProperty("ok") && !response.ok) setError(response.errorType);
    else setData(response);
    setFetching(false);
  }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (fetchCondition) fetchData();
  }, [fetchData]); // eslint-disable-line react-hooks/exhaustive-deps

  return [data, fetching, error, fetchData];
};

export default useMainApi;
