import { getActiveOrganization } from "selectors/organizations";
import { getAllBusinessesData, getSelectedBusinessId } from "selectors/businesses";
import { getSelectedBusinessAuditFilters } from "selectors/audit";
import { getTextsData } from "selectors/texts";
import { getUserData } from "selectors/user";
import { mainApi } from "api";
import { toast } from "react-toastify";
import Constants from "const/Constants";
import Countries from "const/Countries";
import IntegrationServices from "const/IntegrationServices";
import IntegrationsActions from "actions/IntegrationsActions";
import MainApiRoutes from "const/MainApiRoutes";
import UiActions from "actions/UiActions";
import UserRoles from "const/UserRoles";
import Utils from "utils/Utils";

const { AUDIT_AMOUNT_DEVIATION_FILTERS } = Constants;

export default class BusinessesActions {
  static SELECT_BUSINESS = "businesses/SELECT_BUSINESS";

  static SET_QUICKBOOKS_ACCOUNTANT_CLIENT_NAME_START = "businesses/SET_QUICKBOOKS_ACCOUNTANT_CLIENT_NAME";

  static SET_QUICKBOOKS_ACCOUNTANT_CLIENT_NAME_DONE = "businesses/SET_QUICKBOOKS_ACCOUNTANT_CLIENT_NAME_DONE";

  static SET_QUICKBOOKS_ACCOUNTANT_CLIENT_NAME_ERROR = "businesses/SET_QUICKBOOKS_ACCOUNTANT_CLIENT_NAME_ERROR";

  static FETCH_BUSINESS_START = "businesses/FETCH_BUSINESS_START";

  static FETCH_BUSINESS_DONE = "businesses/FETCH_BUSINESS_DONE";

  static FETCH_BUSINESS_ERROR = "businesses/FETCH_BUSINESS_ERROR";

  static FETCH_BUSINESSES_LIST_START = "businesses/FETCH_BUSINESSES_LIST_START";

  static FETCH_BUSINESSES_LIST_DONE = "businesses/FETCH_BUSINESSES_LIST_DONE";

  static FETCH_BUSINESSES_LIST_ERROR = "businesses/FETCH_BUSINESSES_LIST_ERROR";

  static FETCH_DATA_MATCHES_LIST_START = "businesses/FETCH_DATA_MATCHES_LIST_START";

  static FETCH_DATA_MATCHES_LIST_DONE = "businesses/FETCH_DATA_MATCHES_LIST_DONE";

  static FETCH_DATA_MATCHES_LIST_ERROR = "businesses/FETCH_DATA_MATCHES_LIST_ERROR";

  static FETCH_GLOBAL_STATS_START = "businesses/FETCH_GLOBAL_STATS_START";

  static FETCH_GLOBAL_STATS_DONE = "businesses/FETCH_GLOBAL_STATS_DONE";

  static FETCH_GLOBAL_STATS_ERROR = "businesses/FETCH_GLOBAL_STATS_ERROR";

  static UPDATE_GLOBAL_STATS = "businesses/UPDATE_GLOBAL_STATS";

  static PAIR_MATCHES_START = "businesses/PAIR_MATCHES_START";

  static PAIR_MATCHES_DONE = "businesses/PAIR_MATCHES_DONE";

  static PAIR_MATCHES_ERROR = "businesses/PAIR_MATCHES_ERROR";

  static UNPAIR_MATCHES_START = "businesses/UNPAIR_MATCHES_START";

  static UNPAIR_MATCHES_DONE = "businesses/UNPAIR_MATCHES_DONE";

  static UNPAIR_MATCHES_ERROR = "businesses/UNPAIR_MATCHES_ERROR";

  static ADD_NEW_BUSINESS_START = "businesses/ADD_NEW_BUSINESS_START";

  static ADD_NEW_BUSINESS_DONE = "businesses/ADD_NEW_BUSINESS_DONE";

  static ADD_NEW_BUSINESS_ERROR = "businesses/ADD_NEW_BUSINESS_ERROR";

  static EDIT_BUSINESS_SETTINGS_START = "businesses/EDIT_BUSINESS_SETTINGS_START";

  static EDIT_BUSINESS_SETTINGS_DONE = "businesses/EDIT_BUSINESS_SETTINGS_DONE";

  static EDIT_BUSINESS_SETTINGS_ERROR = "businesses/EDIT_BUSINESS_SETTINGS_ERROR";

  static EDIT_BUSINESS_AUDIT_SETTINGS_START = "businesses/EDIT_BUSINESS_AUDIT_SETTINGS_START";

  static EDIT_BUSINESS_AUDIT_SETTINGS_DONE = "businesses/EDIT_BUSINESS_AUDIT_SETTINGS_DONE";

  static EDIT_BUSINESS_AUDIT_SETTINGS_ERROR = "businesses/EDIT_BUSINESS_AUDIT_SETTINGS_ERROR";

  static DELETE_BUSINESS_START = "businesses/DELETE_BUSINESS_START";

  static DELETE_BUSINESS_DONE = "businesses/DELETE_BUSINESS_DONE";

  static DELETE_BUSINESS_ERROR = "businesses/DELETE_BUSINESS_ERROR";

  static setQuickBooksAccountantClientName(businessId, clientBusinessName) {
    return async(dispatch) => {
      dispatch({ type: BusinessesActions.SET_QUICKBOOKS_ACCOUNTANT_CLIENT_NAME_START });

      const { BUSINESSES, EXTRA, CLIENT_NAME } = MainApiRoutes;

      const { ok } = await mainApi.patch(
        `${BUSINESSES}/${businessId}${EXTRA}/${IntegrationServices.QUICK_BOOKS.value}${CLIENT_NAME}`,
        null,
        { clientBusinessName }
      );

      if (ok) {
        dispatch({ type: BusinessesActions.SET_QUICKBOOKS_ACCOUNTANT_CLIENT_NAME_DONE });

        return true;
      }
      dispatch({ type: BusinessesActions.SET_QUICKBOOKS_ACCOUNTANT_CLIENT_NAME_ERROR });

      return false;
    };
  }

  static selectBusiness(businessId) {
    return async(dispatch, getState) => {
      const state = getState();

      const { uiTexts, messages } = getTextsData(state);

      const businessesData = getAllBusinessesData(state);

      const userData = getUserData(state);

      const { countryCode } = getActiveOrganization(state);

      const businessData = businessesData.find(({ id }) => id === businessId) || {};

      const accountantUser = UserRoles.checkIsAccountant(userData.role);

      const {
        extraData: {
          integrationService,
          lastGptAiCategorizedAt,
          lastEntitiesAutoSetAt,
          uncategorizedRpaEnabled,
          reconciliationRpaEnabled,
          integrationServiceConnected
        } = {} } = businessData;

      const { label: serviceName } = IntegrationServices.getByValue(integrationService) || {};

      const quickBooksDesktopBusiness = integrationService === IntegrationServices.QUICK_BOOKS_DESKTOP.value;

      Utils.storageValue(Constants.LS_KEYS.SELECTED_BUSINESS, businessId);
      dispatch({ type: BusinessesActions.SELECT_BUSINESS, payload: { businessId } });
      await dispatch(BusinessesActions.fetchBusiness(businessId));
      if (integrationService && accountantUser) {
        if (
          !integrationServiceConnected
          && (countryCode !== Countries.CZ)
          && (uncategorizedRpaEnabled === undefined && reconciliationRpaEnabled === undefined
            ? lastEntitiesAutoSetAt
            : (lastGptAiCategorizedAt && (uncategorizedRpaEnabled || reconciliationRpaEnabled)))
        ) {
          let [serviceIntegrationExpiredA, serviceIntegrationExpiredB] = quickBooksDesktopBusiness
            ? messages.quickBooksDesktopExpired : messages.serviceIntegrationExpired;

          serviceIntegrationExpiredA = Utils.replaceTextVars(
            serviceIntegrationExpiredA,
            { businessName: businessData.name, serviceName }
          );

          serviceIntegrationExpiredB = Utils.replaceTextVars(
            serviceIntegrationExpiredB,
            { businessName: businessData.name, serviceName }
          );

          const result = await dispatch(UiActions.showModal(
            `${serviceIntegrationExpiredA}\n\n${serviceIntegrationExpiredB}`,
            uiTexts.warning,
            !quickBooksDesktopBusiness,
            null,
            quickBooksDesktopBusiness ? undefined : uiTexts.authorizeBusiness,
            quickBooksDesktopBusiness ? uiTexts.skip : uiTexts.keepDisconnected
          ));

          if (result) {
            const consentUrl = await dispatch(IntegrationsActions.buildConsentUrl(integrationService));

            dispatch(UiActions.togglePreloader(false));
            if (consentUrl) window.location.assign(consentUrl);
          }
        }
      }

      return businessData;
    };
  }

  static fetchBusiness(businessId, backgroundUpdate, silentUpdate) {
    return async(dispatch, getState) => {
      dispatch({ type: BusinessesActions.FETCH_BUSINESS_START, payload: { backgroundUpdate } });

      const { errors } = getTextsData(getState());

      const business = await mainApi.get(`${MainApiRoutes.BUSINESSES}/${businessId}`);

      if (business && business.id) {
        dispatch({ type: BusinessesActions.FETCH_BUSINESS_DONE, payload: { business } });

        return business;
      }
      dispatch({ type: BusinessesActions.FETCH_BUSINESS_ERROR });
      if (!silentUpdate) toast.error(errors.whileLoadingBusinessData);

      return null;
    };
  }

  static fetchBusinessesList(clearList, backgroundUpdate, silentUpdate) {
    return async(dispatch, getState) => {
      dispatch({ type: BusinessesActions.FETCH_BUSINESSES_LIST_START, payload: { clearList, backgroundUpdate } });

      const { errors } = getTextsData(getState());

      const { results: businesses } = await mainApi.get(MainApiRoutes.BUSINESSES);

      if (Array.isArray(businesses)) {
        dispatch({ type: BusinessesActions.FETCH_BUSINESSES_LIST_DONE, payload: { businesses } });

        return businesses;
      }
      dispatch({ type: BusinessesActions.FETCH_BUSINESSES_LIST_ERROR });
      if (!silentUpdate) toast.error(errors.whileLoadingBusinesses);

      return null;
    };
  }

  static fetchDataMatchesList(transactionId, documentId, backgroundUpdate) {
    return async(dispatch, getState) => {
      dispatch({ type: BusinessesActions.FETCH_DATA_MATCHES_LIST_START, payload: { backgroundUpdate } });

      const { BUSINESSES, MATCHES } = MainApiRoutes;

      const selectedBusinessId = getSelectedBusinessId(getState());

      const { errors } = getTextsData(getState());

      const requestData = { filters: { transactionId, documentId } };

      const { results: matches } = await mainApi.post(`${BUSINESSES}/${selectedBusinessId}${MATCHES}`, null, requestData);

      if (Array.isArray(matches)) {
        dispatch({ type: BusinessesActions.FETCH_DATA_MATCHES_LIST_DONE, payload: { matches } });

        return matches;
      }
      dispatch({ type: BusinessesActions.FETCH_DATA_MATCHES_LIST_ERROR });
      toast.error(errors.whileLoadingMatches);

      return null;
    };
  }

  static fetchGlobalStats(clearData = false) {
    return async(dispatch, getState) => {
      dispatch({ type: BusinessesActions.FETCH_GLOBAL_STATS_START, payload: { clearData } });

      const { BUSINESSES, STATS } = MainApiRoutes;

      const selectedBusinessId = getSelectedBusinessId(getState());

      const auditFilters = getSelectedBusinessAuditFilters(getState());

      const { compareAmountBy, amount, minAmount } = auditFilters;

      const betweenType = compareAmountBy === AUDIT_AMOUNT_DEVIATION_FILTERS.BETWEEN;

      const parsedAmount = amount === undefined ? undefined : Utils.parseFloat(amount);

      const parsedMinAmount = minAmount === undefined ? undefined : Utils.parseFloat(minAmount);

      const { results: globalStats } = await mainApi.get(`${BUSINESSES}/${selectedBusinessId}${STATS}`, {
        compareAmountBy,
        amount: betweenType ? Math.min(parsedAmount, parsedMinAmount) : parsedAmount,
        maxAmount: betweenType ? Math.max(parsedAmount, parsedMinAmount) : undefined
      });

      if (globalStats && globalStats.documents && globalStats.transactions) {
        dispatch({ type: BusinessesActions.FETCH_GLOBAL_STATS_DONE, payload: { globalStats } });

        return globalStats;
      }
      dispatch({ type: BusinessesActions.FETCH_GLOBAL_STATS_ERROR });

      return null;
    };
  }

  static pairMatches(transactionId, documentId, backgroundUpdate = false, silentUpdate = false) {
    return async(dispatch, getState) => {
      dispatch({ type: BusinessesActions.PAIR_MATCHES_START, payload: { backgroundUpdate } });

      const { BUSINESSES, MATCHES, PAIR } = MainApiRoutes;

      const selectedBusinessId = getSelectedBusinessId(getState());

      const { messages, errors } = getTextsData(getState());

      const requestData = {
        transactionId: transactionId || undefined,
        documentId: documentId || undefined
      };

      const { ok } = await mainApi.patch(`${BUSINESSES}/${selectedBusinessId + MATCHES + PAIR}`, null, requestData);

      if (ok) {
        dispatch({ type: BusinessesActions.PAIR_MATCHES_DONE, payload: requestData });
        if (!silentUpdate) toast.success(messages.pairingDone);

        return requestData;
      }
      dispatch({ type: BusinessesActions.PAIR_MATCHES_ERROR });
      toast.error(errors.whilePairing);

      return null;
    };
  }

  static unpairMatches(transactionId, documentId, backgroundUpdate = false, silentUpdate = false) {
    return async(dispatch, getState) => {
      dispatch({ type: BusinessesActions.UNPAIR_MATCHES_START, payload: { backgroundUpdate } });

      const { BUSINESSES, MATCHES, UNPAIR } = MainApiRoutes;

      const selectedBusinessId = getSelectedBusinessId(getState());

      const { messages, errors } = getTextsData(getState());

      const requestData = {
        transactionId: transactionId || undefined,
        documentId: documentId || undefined
      };

      const { ok } = await mainApi.patch(`${BUSINESSES}/${selectedBusinessId + MATCHES + UNPAIR}`, null, requestData);

      if (ok) {
        dispatch({ type: BusinessesActions.UNPAIR_MATCHES_DONE, payload: requestData });
        if (!silentUpdate) toast.success(messages.unpairingDone);

        return requestData;
      }
      dispatch({ type: BusinessesActions.UNPAIR_MATCHES_ERROR });
      toast.error(errors.whileUnpairing);

      return null;
    };
  }

  static addNewBusiness(name, regId, type, vatPayer) {
    return async(dispatch, getState) => {
      dispatch({ type: BusinessesActions.ADD_NEW_BUSINESS_START });

      const { messages, errors } = getTextsData(getState());

      const business = await mainApi.put(MainApiRoutes.BUSINESSES, null, { name, regId, type, vatPayer });

      if (business.id) {
        dispatch({ type: BusinessesActions.ADD_NEW_BUSINESS_DONE });
        dispatch(BusinessesActions.fetchBusinessesList(false, false, true));
        toast.success(messages.businessAdded);

        return business;
      }
      dispatch({ type: BusinessesActions.ADD_NEW_BUSINESS_ERROR });
      toast.error(errors.whileAddingBusiness);

      return null;
    };
  }

  static editBusinessSettings(businessId, settingsData, silentUpdate = false) {
    return async(dispatch, getState) => {
      dispatch({ type: BusinessesActions.EDIT_BUSINESS_SETTINGS_START });

      const { BUSINESSES, SETTINGS } = MainApiRoutes;

      const { messages, errors } = getTextsData(getState());

      const selectedBusinessId = getSelectedBusinessId(getState());

      const { ok } = await mainApi.patch(
        `${BUSINESSES}/${businessId}${SETTINGS}`,
        null,
        settingsData
      );

      if (ok) {
        dispatch({ type: BusinessesActions.EDIT_BUSINESS_SETTINGS_DONE, payload: { id: businessId, settings: settingsData } });
        if (selectedBusinessId) dispatch(BusinessesActions.fetchGlobalStats());
        if (!silentUpdate) toast.success(messages.businessSettingsUpdated);

        return true;
      }
      dispatch({ type: BusinessesActions.EDIT_BUSINESS_SETTINGS_ERROR });
      toast.error(errors.whileUpdatingBusinessSettings);

      return false;
    };
  }

  static editSelectedBusinessAuditSettings(settingsData) {
    return async(dispatch, getState) => {
      dispatch({ type: BusinessesActions.EDIT_BUSINESS_AUDIT_SETTINGS_START });

      const state = getState();

      const { BUSINESSES, SETTINGS, AUDIT } = MainApiRoutes;

      const { messages, errors } = getTextsData(state);

      const selectedBusinessId = getSelectedBusinessId(state);

      const { ok } = await mainApi.patch(
        `${BUSINESSES}/${selectedBusinessId}${SETTINGS}${AUDIT}`,
        null,
        settingsData
      );

      if (ok) {
        dispatch({
          type: BusinessesActions.EDIT_BUSINESS_AUDIT_SETTINGS_DONE,
          payload: { id: selectedBusinessId, auditSettings: settingsData }
        });
        dispatch(BusinessesActions.fetchGlobalStats());
        toast.success(messages.businessSettingsUpdated);

        return true;
      }
      dispatch({ type: BusinessesActions.EDIT_BUSINESS_AUDIT_SETTINGS_ERROR });
      toast.error(errors.whileUpdatingBusinessSettings);

      return false;
    };
  }

  static deleteBusiness(businessId) {
    return async(dispatch, getState) => {
      dispatch({ type: BusinessesActions.DELETE_BUSINESS_START });

      const selectedBusinessId = getSelectedBusinessId(getState());

      const { messages, errors } = getTextsData(getState());

      const { ok } = await mainApi.delete(`${MainApiRoutes.BUSINESSES}/${businessId}`);

      if (ok) {
        dispatch({ type: BusinessesActions.DELETE_BUSINESS_DONE, payload: { businessId } });
        if (businessId !== selectedBusinessId) toast.success(messages.businessDeleted);

        return businessId;
      }
      dispatch({ type: BusinessesActions.DELETE_BUSINESS_ERROR });
      toast.error(errors.whileDeletingBusiness);

      return null;
    };
  }
}
