import AuditActions from "actions/AuditActions";
import Constants from "const/Constants";
import Utils from "utils/Utils";

const { LS_KEYS: { AUDIT_FILTERS } } = Constants;

export default () => {
  return (next) => (action) => {
    const { type, payload } = action;

    const result = next(action);

    if (type === AuditActions.SET_COMPANY_FILTERS) {
      const { selectedBusinessId, filters } = payload;

      const stored = Utils.storageJsonValue(AUDIT_FILTERS) || {};

      Utils.storageJsonValue(AUDIT_FILTERS, {
        ...stored,
        [selectedBusinessId]: filters
      });
    }

    return result;
  };
};
