import { getArchiveData } from "selectors/archive";
import { getSelectedBusinessId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { mainApi } from "api";
import { toast } from "react-toastify";
import Async from "utils/Async";
import DataConstants from "const/DataConstants";
import MainApiRoutes from "const/MainApiRoutes";
import UiActions from "./UiActions";
import Utils from "utils/Utils";

const { FOLDER } = DataConstants.ARCHIVE_ATTACHMENT_TYPES;

export default class ArchiveActions {
  static FETCH_FILES_LIST_START = "archive/FETCH_FILES_LIST_START";

  static FETCH_FILES_LIST_DONE = "archive/FETCH_FILES_LIST_DONE";

  static FETCH_FILES_LIST_ERROR = "archive/FETCH_FILES_LIST_ERROR";

  static DOWNLOAD_FILE_START = "archive/DOWNLOAD_FILE_START";

  static DOWNLOAD_FILE_DONE = "archive/DOWNLOAD_FILE_DONE";

  static DOWNLOAD_FILE_ERROR = "archive/DOWNLOAD_FILE_ERROR";

  static UPLOAD_FILE_START = "archive/UPLOAD_FILE_START";

  static UPLOAD_FILE_DONE = "archive/UPLOAD_FILE_DONE";

  static UPLOAD_FILE_ERROR = "archive/UPLOAD_FILE_ERROR";

  static UPLOAD_FILES_START = "archive/UPLOAD_FILES_START";

  static UPLOAD_FILES_DONE = "archive/UPLOAD_FILES_DONE";

  static UPLOAD_FILES_ERROR = "archive/UPLOAD_FILES_ERROR";

  static EDIT_FILE_START = "archive/EDIT_FILE_START";

  static EDIT_FILE_DONE = "archive/EDIT_FILE_DONE";

  static EDIT_FILE_ERROR = "archive/EDIT_FILE_ERROR";

  static DELETE_FILE_START = "archive/DELETE_FILE_START";

  static DELETE_FILE_DONE = "archive/DELETE_FILE_DONE";

  static DELETE_FILE_ERROR = "archive/DELETE_FILE_ERROR";

  static BULK_FILES_UPDATE_START = "transactions/BULK_FILES_UPDATE_START";

  static BULK_FILES_UPDATE_DONE = "transactions/BULK_FILES_UPDATE_DONE";

  static BULK_FILES_UPDATE_ERROR = "transactions/BULK_FILES_UPDATE_ERROR";

  static CREATE_FOLDER_START = "transactions/CREATE_FOLDER_START";

  static CREATE_FOLDER_DONE = "transactions/CREATE_FOLDER_DONE";

  static CREATE_FOLDER_ERROR = "transactions/CREATE_FOLDER_ERROR";

  static MARK_AS_READ = "archive/MARK_AS_READ";

  static fetchFilesList(clearList, backgroundUpdate) {
    return async(dispatch, getState) => {
      dispatch({ type: ArchiveActions.FETCH_FILES_LIST_START, payload: { clearList, backgroundUpdate } });

      const { BUSINESSES, ARCHIVE } = MainApiRoutes;

      const selectedBusinessId = getSelectedBusinessId(getState());

      const { errors } = getTextsData(getState());

      const path = `${BUSINESSES}/${selectedBusinessId + ARCHIVE}`;

      const { results: files } = await mainApi.get(path);

      if (Array.isArray(files)) {
        dispatch({ type: ArchiveActions.FETCH_FILES_LIST_DONE, payload: { files } });

        return files;
      }
      dispatch({ type: ArchiveActions.FETCH_FILES_LIST_ERROR });
      toast.error(errors.whileLoadingFiles);

      return null;
    };
  }

  static downloadFile(fileId, queryParams) {
    return async(dispatch, getState) => {
      dispatch({ type: ArchiveActions.DOWNLOAD_FILE_START, payload: { queryParams } });

      const { BUSINESSES, ARCHIVE, DOWNLOAD } = MainApiRoutes;

      const archiveData = getArchiveData(getState());

      const selectedBusinessId = getSelectedBusinessId(getState());

      const { errors } = getTextsData(getState());

      const query = Utils.objectToQueryString(queryParams);

      const path = `${BUSINESSES}/${selectedBusinessId + ARCHIVE + DOWNLOAD}?${query}`;

      const { link } = await mainApi.get(path);

      if (link) {
        const file = archiveData.find(({ id }) => id === fileId);

        await mainApi.get(`${ARCHIVE}/${fileId}`);
        if (file.notRead) dispatch({ type: ArchiveActions.MARK_AS_READ, payload: { fileId } });
        Utils.downloadContent(link);
        dispatch({ type: ArchiveActions.DOWNLOAD_FILE_DONE, payload: { fileId } });

        return true;
      }
      dispatch({ type: ArchiveActions.DOWNLOAD_FILE_ERROR });
      toast.error(errors.whileDownloadingFile);

      return false;
    };
  }

  static uploadFile({ file: attachedFile, ...restFileData }) {
    return async(dispatch, getState) => {
      const { BUSINESSES, ATTACHMENTS, ARCHIVE } = MainApiRoutes;

      const selectedBusinessId = getSelectedBusinessId(getState());

      const path = `${BUSINESSES}/${selectedBusinessId}${ATTACHMENTS}`;

      const { key, originalName } = await mainApi.put(path, null, attachedFile, "document");

      const file = key ? await mainApi.put(
        `${BUSINESSES}/${selectedBusinessId}${ARCHIVE}`,
        null,
        { ...restFileData, attachment: { key, originalName } }
      ) : {};

      if (file.id) {
        dispatch({ type: ArchiveActions.UPLOAD_FILE_DONE, payload: { file } });

        return file;
      }
      dispatch({ type: ArchiveActions.UPLOAD_FILE_ERROR });

      return null;
    };
  }

  static uploadFiles(files, data, backgroundUpdate = false) {
    return async(dispatch, getState) => {
      dispatch({ type: ArchiveActions.UPLOAD_FILES_START, payload: { filesCount: files.length, backgroundUpdate } });

      const { messages, errors, uiTexts } = getTextsData(getState());

      const uploadResults = await Async.runInSequence(files.map((file) => {
        return async() => {
          const result = await dispatch(ArchiveActions.uploadFile({ file, ...data }));

          return result;
        };
      }));

      const failedFilesNames = uploadResults.map((result, index) => !result && files[index.name]).filter(Boolean);

      if (failedFilesNames.length) {
        const failedFilesNamesString = failedFilesNames.map((fileName, index) => `${index + 1}. ${fileName}`).join("\n");

        await dispatch(UiActions.showModal(
          `${errors.whileUploadingFiles}\n\n${failedFilesNamesString}`,
          uiTexts.error,
          false,
          null
        ));
      } else {
        toast.info(messages.allFilesUploaded);
      }
      dispatch({ type: ArchiveActions.UPLOAD_FILES_DONE });

      return failedFilesNames.length === files.length ? null : uploadResults;
    };
  }

  static createFolder({ path }) {
    return async(dispatch, getState) => {
      dispatch({ type: ArchiveActions.CREATE_FOLDER_START });

      const { BUSINESSES, ARCHIVE } = MainApiRoutes;

      const selectedBusinessId = getSelectedBusinessId(getState());

      const { errors } = getTextsData(getState());

      const file = await mainApi.put(`${BUSINESSES}/${selectedBusinessId}${ARCHIVE}`, null, { path, type: FOLDER });

      if (file.id) {
        dispatch({ type: ArchiveActions.UPLOAD_FILE_DONE, payload: { file } });

        return file;
      }
      dispatch({ type: ArchiveActions.UPLOAD_FILE_ERROR });
      toast.error(errors.whileCreatingFolder);

      return null;
    };
  }

  static editFile(fileId, fileData) {
    return async(dispatch, getState) => {
      dispatch({ type: ArchiveActions.EDIT_FILE_START });

      const { messages, errors } = getTextsData(getState());

      const file = await mainApi.patch(
        `${MainApiRoutes.ARCHIVE}/${fileId}`,
        null,
        fileData
      );

      if (file.id) {
        dispatch({ type: ArchiveActions.EDIT_FILE_DONE, payload: { file } });
        toast.success(messages.fileEdited);

        return file;
      }
      dispatch({ type: ArchiveActions.EDIT_FILE_ERROR });
      toast.error(errors.whileEditingFile);

      return null;
    };
  }

  static deleteFile(fileId) {
    return async(dispatch, getState) => {
      dispatch({ type: ArchiveActions.DELETE_FILE_START });

      const archiveData = getArchiveData(getState());

      const { messages, errors } = getTextsData(getState());

      const { ok } = await mainApi.delete(`${MainApiRoutes.ARCHIVE}/${fileId}`);

      if (ok) {
        const file = archiveData.find(({ id }) => id === fileId);

        if (file.notRead) dispatch({ type: ArchiveActions.MARK_AS_READ, payload: { fileId } });
        dispatch({ type: ArchiveActions.DELETE_FILE_DONE, payload: { fileId } });
        toast.success(messages.fileDeleted);

        return fileId;
      }
      dispatch({ type: ArchiveActions.DELETE_FILE_ERROR });
      toast.error(errors.whileDeletingFile);

      return null;
    };
  }

  static bulkFilesUpdate(filesIds, path, tags) {
    return async(dispatch, getState) => {
      dispatch({ type: ArchiveActions.BULK_FILES_UPDATE_START });

      const { ARCHIVE, BULK } = MainApiRoutes;

      const { CHANGE_PATH, ADD_TAGS, REMOVE } = DataConstants.BULK_ACTIONS;

      const { messages, errors } = getTextsData(getState());

      const action = [
        path && CHANGE_PATH,
        tags && ADD_TAGS,
        REMOVE
      ].find((bulkAction) => bulkAction);

      const requestData = {
        ids: filesIds,
        action,
        path,
        tags
      };

      const { ok } = await mainApi.post(ARCHIVE + BULK, null, requestData);

      if (ok) {
        dispatch({ type: ArchiveActions.BULK_FILES_UPDATE_DONE, payload: { filesIds, path, tags } });
        if (path) {
          toast.success(Utils.replaceTextVars(messages.filesMoved, { filesCount: filesIds.length }));
        } else if (tags) {
          toast.success(Utils.replaceTextVars(messages.filesChanged, { filesCount: filesIds.length }));
        } else {
          toast.success(Utils.replaceTextVars(messages.filesDeleted, { filesCount: filesIds.length }));
        }

        return filesIds;
      }
      dispatch({ type: ArchiveActions.BULK_FILES_UPDATE_ERROR });
      if (path) {
        toast.error(Utils.replaceTextVars(errors.whileMovingFiles, { filesCount: filesIds.length }));
      } else if (tags) {
        toast.error(Utils.replaceTextVars(errors.whileChangingFiles, { filesCount: filesIds.length }));
      } else {
        toast.error(Utils.replaceTextVars(errors.whileDeletingFiles, { filesCount: filesIds.length }));
      }

      return null;
    };
  }
}
