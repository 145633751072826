import Errors from "const/Errors";
import Utils from "utils/Utils";

export default class RestApi {
  static REQUEST_METHODS = {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE",
    PATCH: "PATCH"
  };

  apiUrl = null;

  constructor(apiUrl = "") {
    this.apiUrl = apiUrl;
  }

  async get(path, urlParameters) {
    const response = await this.makeRequest(RestApi.REQUEST_METHODS.GET, path, urlParameters);

    return response;
  }

  async put(path, urlParameters, payload, binary) {
    const response = await this.makeRequest(RestApi.REQUEST_METHODS.PUT, path, urlParameters, payload, binary);

    return response;
  }

  async post(path, urlParameters, payload, binary) {
    const response = await this.makeRequest(RestApi.REQUEST_METHODS.POST, path, urlParameters, payload, binary);

    return response;
  }

  async patch(path, urlParameters, payload) {
    const response = await this.makeRequest(RestApi.REQUEST_METHODS.PATCH, path, urlParameters, payload);

    return response;
  }

  async delete(path, urlParameters, payload) {
    const response = await this.makeRequest(RestApi.REQUEST_METHODS.DELETE, path, urlParameters, payload);

    return response;
  }

  async makeRequest(method, path, urlParameters) {
    try {
      const response = await fetch(
        `${this.apiUrl}${path}${urlParameters ? `?${Utils.objectToQueryString(urlParameters)}` : ""}`,
        { method }
      );

      let responseJson;

      if (!response.ok || !Object.keys(responseJson).length) throw Errors.REQUEST_ERROR;

      return responseJson;
    } catch (error) {
      throw Errors.REQUEST_ERROR;
    }
  }
}
