import Css from "./style.module.scss";

import { Avatar } from "lib/common";
import Constants from "const/Constants";
import React, { useMemo } from "react";
import classNames from "classnames";

const AvatarWithName = ({ name, className, avatarUrl, left, children, ...restProps }) => {
  const content = useMemo(
    () => (
      <div data-left={left ? "" : undefined} className={Css.content}>
        <div>{name}</div>
        {children && <div className={Css.subContent}>{children}</div>}
      </div>
    ),
    [name, left, children]
  );

  return (
    <div {...restProps} className={classNames(Css.avatarWithName, className)}>
      {left && content}
      <div><Avatar name={typeof name === "string" ? name : Constants.EMPTY_PLACEHOLDER} avatarUrl={avatarUrl} /></div>
      {!left && content}
    </div>
  );
};

export default React.memo(AvatarWithName);
