import { useCallback, useRef, useState } from "react";

const useShowModal = () => {
  const resolveRef = useRef();

  const [modalShown, setModalShown] = useState(false);

  const showModal = useCallback((value = true) => {
    setModalShown(value);

    return new Promise((resolve) => {
      resolveRef.current = resolve;
    });
  }, []);

  const onClose = useCallback((result) => {
    setModalShown(false);
    if (resolveRef.current) {
      resolveRef.current(result);
      resolveRef.current = null;
    }
  }, []);

  return [modalShown, showModal, onClose];
};

export default useShowModal;
