export default class ModalNames {
  static LINK_CONTACT_WINDOW = "LinkContactWindow";

  static SELECT_CATEGORY_WINDOW = "SelectCategoryWindow";

  static SELECT_CLASS_WINDOW = "SelectClassWindow";

  static SELECT_LOCATION_WINDOW = "SelectLocationWindow";

  static SELECT_PROJECT_WINDOW = "SelectProjectWindow";

  static SELECT_TAX_RATE_WINDOW = "SelectTaxRateWindow";

  static ADD_TAGS_WINDOW = "AddTagsWindow";

  static ADD_REASON_WINDOW = "AddReasonWindow";

  static SELECT_BUSINESSES_WINDOW = "SelectedBusinessesWindow";

  static ADD_BUSINESS_WINDOW = "AddBusinessWindow";

  static ADD_QUICK_BOOKS_DESKTOP_WINDOW = "AddQuickBooksDesktopWindow";

  static EDIT_BUSINESS_SETTINGS_WINDOW = "EditBusinessSettingsWindow";

  static ASK_CLIENT_WINDOW = "AskClientWindow";

  static USER_DETAILS_WINDOW = "UserDetailsWindow";

  static PAIR_TRANSACTIONS_WINDOW = "PairTransactionsWindow";

  static SETUP_BACKUP_WINDOW = "SetupBackupWindow";
}
