import { mainApi } from "api";
import MainApiRoutes from "const/MainApiRoutes";

export default class GoogleActions {
  static EXCHANGE_CODE_START = "stripe/EXCHANGE_CODE_START";

  static EXCHANGE_CODE_DONE = "stripe/EXCHANGE_CODE_DONE";

  static EXCHANGE_CODE_ERROR = "stripe/EXCHANGE_CODE_ERROR";

  static exchangeCode(code) {
    return async(dispatch) => {
      dispatch({ type: GoogleActions.EXCHANGE_CODE_START });

      const { GOOGLE, EXCHANGE_CODE } = MainApiRoutes;

      const authResult = await mainApi.post(
        GOOGLE + EXCHANGE_CODE,
        null,
        { code, redirectUrl: window.location.origin }
      );

      if (authResult) {
        dispatch({ type: GoogleActions.EXCHANGE_CODE_DONE, payload: authResult });

        return authResult;
      }
      dispatch({ type: GoogleActions.EXCHANGE_CODE_ERROR });

      return null;
    };
  }
}
