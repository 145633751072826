import { getSelectedBusinessId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { mainApi } from "api";
import { toast } from "react-toastify";
import Async from "utils/Async";
import BusinessesActions from "./BusinessesActions";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import MainApiRoutes from "const/MainApiRoutes";
import UiActions from "actions/UiActions";
import Utils from "utils/Utils";
import objectHash from "object-hash";

const { STATUSES: { TO_REPORT, EXCLUDED } } = DataConstants;

export default class DocumentsActions {
  static FETCH_DOCUMENT_START = "documents/FETCH_DOCUMENT_START";

  static FETCH_DOCUMENT_DONE = "documents/FETCH_DOCUMENT_DONE";

  static FETCH_DOCUMENT_ERROR = "documents/FETCH_DOCUMENT_ERROR";

  static FETCH_DOCUMENTS_LIST_START = "documents/FETCH_DOCUMENTS_LIST_START";

  static FETCH_DOCUMENTS_LIST_DONE = "documents/FETCH_DOCUMENTS_LIST_DONE";

  static FETCH_DOCUMENTS_LIST_ERROR = "documents/FETCH_DOCUMENTS_LIST_ERROR";

  static ADD_NEW_DOCUMENT_START = "documents/ADD_NEW_DOCUMENT_START";

  static ADD_NEW_DOCUMENT_DONE = "documents/ADD_NEW_DOCUMENT_DONE";

  static ADD_NEW_DOCUMENT_ERROR = "documents/ADD_NEW_DOCUMENT_ERROR";

  static IMPORT_CLOUD_DOCUMENT_DONE = "documents/IMPORT_CLOUD_DOCUMENT_DONE";

  static IMPORT_CLOUD_DOCUMENT_ERROR = "documents/IMPORT_CLOUD_DOCUMENT_ERROR";

  static UPLOAD_DOCUMENTS_START = "documents/UPLOAD_DOCUMENTS_START";

  static UPLOAD_DOCUMENTS_DONE = "documents/UPLOAD_DOCUMENTS_DONE";

  static UPLOAD_DOCUMENT_DONE = "documents/UPLOAD_DOCUMENT_DONE";

  static UPLOAD_DOCUMENT_ERROR = "documents/UPLOAD_DOCUMENT_ERROR";

  static EDIT_DOCUMENT_START = "documents/EDIT_DOCUMENT_START";

  static EDIT_DOCUMENT_DONE = "documents/EDIT_DOCUMENT_DONE";

  static EDIT_DOCUMENT_ERROR = "documents/EDIT_DOCUMENT_ERROR";

  static DELETE_DOCUMENT_START = "documents/DELETE_DOCUMENT_START";

  static DELETE_DOCUMENT_DONE = "documents/DELETE_DOCUMENT_DONE";

  static DELETE_DOCUMENT_ERROR = "documents/DELETE_DOCUMENT_ERROR";

  static BULK_DOCUMENTS_UPDATE_START = "documents/BULK_DOCUMENTS_UPDATE_START";

  static BULK_DOCUMENTS_UPDATE_DONE = "documents/BULK_DOCUMENTS_UPDATE_DONE";

  static BULK_DOCUMENTS_UPDATE_ERROR = "documents/BULK_DOCUMENTS_UPDATE_ERROR";

  static LOCK_DOCUMENT = "documents/LOCK_DOCUMENT";

  static UNLOCK_DOCUMENT = "documents/UNLOCK_DOCUMENT";

  static fetchDocument(documentId) {
    return async(dispatch, getState) => {
      dispatch({ type: DocumentsActions.FETCH_DOCUMENT_START, payload: { documentId } });

      const { errors } = getTextsData(getState());

      const document = await mainApi.get(`${MainApiRoutes.DOCUMENTS}/${documentId}`);

      if (document && document.id) {
        dispatch({ type: DocumentsActions.FETCH_DOCUMENT_DONE, payload: { document } });

        return document;
      }
      dispatch({ type: DocumentsActions.FETCH_DOCUMENT_ERROR });
      toast.error(errors.whileLoadingDocument);

      return null;
    };
  }

  static fetchDocumentsList(clearList, backgroundUpdate, status, filters, sortings, offset, limit = Constants.TABLE_PAGE_SIZE) {
    return async(dispatch, getState) => {
      dispatch({ type: DocumentsActions.FETCH_DOCUMENTS_LIST_START, payload: { clearList, backgroundUpdate } });

      const { BUSINESSES, DOCUMENTS, STATS } = MainApiRoutes;

      const selectedBusinessId = getSelectedBusinessId(getState());

      const { errors } = getTextsData(getState());

      const path = `${BUSINESSES}/${selectedBusinessId + DOCUMENTS}`;

      const requestBody = { ...filters, sortings, limit, offset };

      if (status) requestBody.status = status;

      const { results: documents, hash } = await mainApi.post(path, null, requestBody);

      // TODO: Return stats in list response
      const stats = documents ? await mainApi.get(path + STATS, filters) : {};

      if (Array.isArray(documents) && stats.total) {
        dispatch({
          type: DocumentsActions.FETCH_DOCUMENTS_LIST_DONE,
          payload: {
            documents,
            stats,
            status,
            filters,
            backgroundUpdate,
            dataHash: objectHash({ value: hash || documents, stats })
          }
        });

        return documents;
      }
      dispatch({ type: DocumentsActions.FETCH_DOCUMENTS_LIST_ERROR });
      if (!backgroundUpdate) toast.error(errors.whileLoadingDocuments);

      return null;
    };
  }

  static uploadCloudDocuments(cloudService, type, paymentType, links, merge) {
    return async(dispatch, getState) => {
      dispatch({ type: DocumentsActions.UPLOAD_DOCUMENTS_START, payload: { documentsCount: links.length } });

      const { BUSINESSES, DOCUMENTS, ATTACHMENTS, MERGE } = MainApiRoutes;

      const selectedBusinessId = getSelectedBusinessId(getState());

      const { messages, errors, uiTexts } = getTextsData(getState());

      const path = `${BUSINESSES}/${selectedBusinessId + ATTACHMENTS}/${cloudService}`;

      const addDocument = async({ file, key, originalKey, originalName, hash }) => {
        const fileName = file ? file.name : originalName;

        const document = key ? await mainApi.put(
          `${BUSINESSES}/${selectedBusinessId}${DOCUMENTS}`,
          { by: "attachment" },
          {
            type: type || undefined,
            paymentType: paymentType || undefined,
            attachment: { key, originalKey, originalName, hash }
          }
        ) : {};

        if (document.id) {
          dispatch({ type: DocumentsActions.UPLOAD_DOCUMENT_DONE, payload: { document } });

          return { id: document.id, fileName, success: true };
        }
        dispatch({ type: DocumentsActions.UPLOAD_DOCUMENT_ERROR });
        toast.error(Utils.replaceTextVars(errors.whileUploadingFile, { fileName }));

        return { fileName };
      };

      let uploadResults = await Async.runInSequence(links.map((requestData) => {
        return async() => {
          const { key, originalKey, originalName, hash } = await mainApi.put(path, null, requestData);

          if (merge) {
            dispatch({ type: DocumentsActions.UPLOAD_DOCUMENT_DONE, payload: {} });

            return { key, originalKey, originalName, hash, success: true };
          }

          return addDocument({ key, originalKey, originalName, hash });
        };
      }));

      if (merge) {
        dispatch({ type: DocumentsActions.UPLOAD_DOCUMENTS_START, payload: { documentsCount: 1 } });
        try {
          const { key, originalName, hash } = await mainApi.post(
            `${BUSINESSES}/${selectedBusinessId}${ATTACHMENTS}${MERGE}`,
            null,
            { attachmentKeys: uploadResults.map((item) => item.key) }
          );

          uploadResults = [await addDocument({ hash, key, originalName })];
        } catch (error) {
          dispatch({ type: DocumentsActions.UPLOAD_DOCUMENT_ERROR });
          toast.error(Utils.replaceTextVars(errors.whileUploadingFiles));
        }
      }

      const failedFilesNames = uploadResults.filter(({ success }) => !success).map(({ fileName }) => fileName);

      if (failedFilesNames.length === 0) toast.info(messages.allDocumentsUploaded);
      else if (links.length > 1) {
        const failedFilesNamesString = failedFilesNames.map((fileName, index) => `${index + 1}..\u00A0${fileName}`).join("\n");

        await dispatch(UiActions.showModal(
          `${errors.whileUploadingDocuments}\n\n${failedFilesNamesString}`,
          uiTexts.error,
          false,
          null
        ));
      }
      dispatch({ type: DocumentsActions.UPLOAD_DOCUMENTS_DONE });

      return failedFilesNames.length === links.length ? null : uploadResults;
    };
  }

  static uploadDocuments(files, payload = {}, backgroundUpdate = false) {
    return async(dispatch, getState) => {
      dispatch({
        type: DocumentsActions.UPLOAD_DOCUMENTS_START,
        payload: { documentsCount: files.length, backgroundUpdate }
      });

      const { BUSINESSES, DOCUMENTS, ATTACHMENTS, MERGE } = MainApiRoutes;

      const selectedBusinessId = getSelectedBusinessId(getState());

      const { messages, errors, uiTexts } = getTextsData(getState());

      const addDocument = async({ file, key, originalKey, originalName, hash }) => {
        const fileName = file ? file.name : originalName;

        const document = key ? await mainApi.put(
          `${BUSINESSES}/${selectedBusinessId}${DOCUMENTS}`,
          { by: "attachment" },
          {
            type: payload.type || undefined,
            paymentType: payload.paymentType || undefined,
            transactionAttachment: payload.transactionAttachment || undefined,
            attachment: { key, originalKey, originalName, hash }
          }
        ) : {};

        if (document.id) {
          dispatch({ type: DocumentsActions.UPLOAD_DOCUMENT_DONE, payload: { document } });

          return { id: document.id, fileName, success: true };
        }
        dispatch({ type: DocumentsActions.UPLOAD_DOCUMENT_ERROR });
        toast.error(Utils.replaceTextVars(errors.whileUploadingFile, { fileName }));

        return { fileName };
      };

      const filesSorted = files.sort(({ name: nameA }, { name: nameB }) => nameA.localeCompare(nameB));

      let uploadResults = await Async.runInSequence(filesSorted.map((file) => {
        return async() => {
          const { key, originalKey, originalName, hash } = await mainApi.put(
            `${BUSINESSES}/${selectedBusinessId}${ATTACHMENTS}`,
            { scope: "document", fileName: file.name },
            file,
            "document"
          );

          if (payload.merge) {
            dispatch({ type: DocumentsActions.UPLOAD_DOCUMENT_DONE, payload: {} });

            return { key, originalKey, originalName, hash, success: true };
          }

          return addDocument({ file, key, originalKey, originalName, hash });
        };
      }));

      const failedFilesNames = uploadResults.filter(({ success }) => !success).map(({ fileName }) => fileName);

      if (payload.merge && !failedFilesNames.length) {
        dispatch({
          type: DocumentsActions.UPLOAD_DOCUMENTS_START,
          payload: { documentsCount: 1, backgroundUpdate }
        });
        try {
          const { key, originalName, hash } = await mainApi.post(
            `${BUSINESSES}/${selectedBusinessId}${ATTACHMENTS}${MERGE}`,
            null,
            { attachmentKeys: uploadResults.map((item) => item.key) }
          );

          if (key) {
            uploadResults = [await addDocument({ hash, key, originalName })];
          } else {
            throw new Error("Merge error");
          }
        } catch (error) {
          dispatch({ type: DocumentsActions.UPLOAD_DOCUMENT_ERROR });
          await dispatch(UiActions.showModal(
            errors.whileMergeFiles,
            uiTexts.error,
            false,
            null
          ));

          return null;
        }
      }

      if (!failedFilesNames.length) {
        const succeedFilesNames = uploadResults.filter(({ success }) => success).map(({ fileName }) => fileName);

        if (succeedFilesNames.length > 1) toast.success(messages.allDocumentsUploaded);
        else toast.success(Utils.replaceTextVars(messages.documentUploaded, { fileName: succeedFilesNames[0] }));
      } else if (files.length > 1) {
        const failedFilesNamesString = failedFilesNames.map((fileName, index) => `${index + 1}.\u00A0${fileName}`).join("\n");

        await dispatch(UiActions.showModal(
          `${errors.whileUploadingDocuments}\n\n${failedFilesNamesString}`,
          uiTexts.error,
          false,
          null
        ));
      }
      dispatch({ type: DocumentsActions.UPLOAD_DOCUMENTS_DONE });

      return failedFilesNames.length === files.length ? null : uploadResults;
    };
  }

  static addNewDocument(documentData, toReport) {
    return async(dispatch, getState) => {
      dispatch({ type: DocumentsActions.ADD_NEW_DOCUMENT_START });

      const { BUSINESSES, DOCUMENTS } = MainApiRoutes;

      const selectedBusinessId = getSelectedBusinessId(getState());

      const { messages, errors } = getTextsData(getState());

      const document = await mainApi.put(
        `${BUSINESSES}/${selectedBusinessId + DOCUMENTS + (toReport ? `/${DataConstants.STATUSES.TO_REPORT}` : "")}`,
        null,
        documentData
      );

      if (document.id) {
        dispatch({ type: DocumentsActions.ADD_NEW_DOCUMENT_DONE, payload: { document } });
        toast.success(messages.documentAdded);

        return document;
      }
      dispatch({ type: DocumentsActions.ADD_NEW_DOCUMENT_ERROR });
      toast.error(errors.whileAddingDocument);

      return null;
    };
  }

  static editDocument(documentId, { files, ...restDocumentData }, toReport, silentUpdate, toExclude, backgroundUpdate) {
    return async(dispatch, getState) => {
      dispatch({ type: DocumentsActions.EDIT_DOCUMENT_START, payload: { backgroundUpdate } });

      const { BUSINESSES, DOCUMENTS, ATTACHMENTS } = MainApiRoutes;

      const selectedBusinessId = getSelectedBusinessId(getState());

      const { messages, errors } = getTextsData(getState());

      let documentData = null;

      if (files) {
        const path = `${BUSINESSES}/${selectedBusinessId + ATTACHMENTS}`;

        const { key, originalKey, originalName } = await mainApi.post(path, null, files[0], "document");

        if (key) documentData = { attachment: { key, originalKey, originalName } };
      } else documentData = restDocumentData;

      let document = {};

      if (documentData) {
        const appendix = toReport ? TO_REPORT : (toExclude ? EXCLUDED : "");

        document = await mainApi.patch(
          `${DOCUMENTS}/${documentId}${(appendix ? `/${appendix}` : "")}`,
          null,
          documentData
        );
      }
      if (document.id) {
        dispatch({ type: DocumentsActions.EDIT_DOCUMENT_DONE, payload: { document } });
        dispatch(BusinessesActions.fetchGlobalStats());
        if (!silentUpdate) toast.success(messages.documentEdited);

        return document;
      }
      dispatch({ type: DocumentsActions.EDIT_DOCUMENT_ERROR });
      toast.error(errors.whileEditingDocument);

      return null;
    };
  }

  static deleteDocument(documentId) {
    return async(dispatch, getState) => {
      dispatch({ type: DocumentsActions.DELETE_DOCUMENT_START });

      const { messages, errors } = getTextsData(getState());

      const { ok } = await mainApi.delete(`${MainApiRoutes.DOCUMENTS}/${documentId}`);

      if (ok) {
        dispatch({ type: DocumentsActions.DELETE_DOCUMENT_DONE, payload: { documentId } });
        dispatch(BusinessesActions.fetchGlobalStats());
        toast.success(messages.documentDeleted);

        return documentId;
      }
      dispatch({ type: DocumentsActions.DELETE_DOCUMENT_ERROR });
      toast.error(errors.whileDeletingDocument);

      return null;
    };
  }

  static bulkDocumentsUpdate({ ids: documentsIds, data, silently }) {
    return async(dispatch, getState) => {
      dispatch({ type: DocumentsActions.BULK_DOCUMENTS_UPDATE_START });

      const { DOCUMENTS, BULK } = MainApiRoutes;

      const {
        CHANGE_STATUS,
        ADD_TAGS,
        LINK_VENDOR,
        SELECT_CATEGORY,
        SELECT_ITEM,
        SELECT_CLASS,
        SELECT_LOCATION,
        SELECT_PROJECT,
        SELECT_TAX_RATE,
        SELECT_PAYMENT_ACCOUNT,
        REMOVE
      } = DataConstants.BULK_ACTIONS;

      const { messages, errors } = getTextsData(getState());

      const action = [
        data.status && CHANGE_STATUS,
        data.category && SELECT_CATEGORY,
        data.item && SELECT_ITEM,
        data.class && SELECT_CLASS,
        data.location && SELECT_LOCATION,
        data.project && SELECT_PROJECT,
        data.taxRate && SELECT_TAX_RATE,
        data.serviceAccountId && SELECT_PAYMENT_ACCOUNT,
        data.tags && ADD_TAGS,
        data.vendorId && LINK_VENDOR,
        REMOVE
      ].find((bulkAction) => bulkAction);

      const requestData = {
        action,
        ids: documentsIds,
        ...data
      };

      const { ok, failedDocs = [] } = await mainApi.patch(DOCUMENTS + BULK, null, requestData);

      if (ok || failedDocs.length < documentsIds.length) {
        dispatch({ type: DocumentsActions.BULK_DOCUMENTS_UPDATE_DONE, payload: { documentsIds, action } });
        dispatch(BusinessesActions.fetchGlobalStats());
        if (!silently) {
          if (action === REMOVE) {
            toast.success(
              Utils.replaceTextVars(
                messages.documentsDeleted,
                { documentsCount: documentsIds.length }
              )
            );
          } else if (documentsIds.length === 1) {
            toast.success(messages.documentEdited);
          } else {
            toast.success(
              Utils.replaceTextVars(
                messages.documentsChanged,
                { successCount: documentsIds.length - failedDocs.length, totalCount: documentsIds.length }
              )
            );
          }
        }

        return { successCount: documentsIds.length - failedDocs.length, failedDocs };
      }
      dispatch({ type: DocumentsActions.BULK_DOCUMENTS_UPDATE_ERROR });
      if (action === REMOVE) {
        toast.error(Utils.replaceTextVars(errors.whileDeletingDocuments, { documentsCount: documentsIds.length }));
      } else toast.error(Utils.replaceTextVars(errors.whileChangingDocuments, { documentsCount: documentsIds.length }));

      return null;
    };
  }

  static lockDocument(documentId) {
    return { type: DocumentsActions.LOCK_DOCUMENT, payload: documentId };
  }

  static unlockDocument(documentId) {
    return { type: DocumentsActions.UNLOCK_DOCUMENT, payload: documentId };
  }
}
