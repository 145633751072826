import Css from "./style.module.scss";

import { Form as ShardsForm } from "shards-react";
import { bind } from "decko";
import React, { PureComponent } from "react";
import classNames from "classnames";

export default class Form extends PureComponent {
  static defaultProps = {
    onSubmit: () => {}
  };

  @bind
  handleSubmit(event) {
    event.preventDefault();
    this.props.onSubmit(event);
  }

  render() {
    const { children, className, ...restProps } = this.props;

    return (
      <ShardsForm {...restProps} onSubmit={this.handleSubmit} className={classNames(className, Css.form)}>
        {this.props.children}
      </ShardsForm>
    );
  }
}
