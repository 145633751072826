import { getTextsData } from "selectors/texts";
import { mainApi } from "api";
import { toast } from "react-toastify";
import MainApiRoutes from "const/MainApiRoutes";

export default class MetaDataActions {
  static FETCH_CURRENCIES_LIST_START = "metaData/FETCH_CURRENCIES_LIST_START";

  static FETCH_CURRENCIES_LIST_DONE = "metaData/FETCH_CURRENCIES_LIST_DONE";

  static FETCH_CURRENCIES_LIST_ERROR = "metaData/FETCH_CURRENCIES_LIST_ERROR";

  static FETCH_PRICES_LIST_START = "metaData/FETCH_PRICES_LIST_START";

  static FETCH_PRICES_LIST_DONE = "metaData/FETCH_PRICES_LIST_DONE";

  static FETCH_PRICES_LIST_ERROR = "metaData/FETCH_PRICES_LIST_ERROR";

  static FETCH_BANKS_LIST_START = "metaData/FETCH_BANKS_LIST_START";

  static FETCH_BANKS_LIST_DONE = "metaData/FETCH_BANKS_LIST_DONE";

  static FETCH_BANKS_LIST_ERROR = "metaData/FETCH_BANKS_LIST_ERROR";

  static fetchCurrenciesList() {
    return async(dispatch, getState) => {
      dispatch({ type: MetaDataActions.FETCH_CURRENCIES_LIST_START });

      const { META, CURRENCIES } = MainApiRoutes;

      const { errors } = getTextsData(getState());

      const { results: currencies } = await mainApi.get(META + CURRENCIES);

      if (Array.isArray(currencies) && currencies.length) {
        dispatch({ type: MetaDataActions.FETCH_CURRENCIES_LIST_DONE, payload: { currencies } });

        return currencies;
      }
      dispatch({ type: MetaDataActions.FETCH_CURRENCIES_LIST_ERROR });
      toast.error(errors.whileLoadingCurrencies);

      return null;
    };
  }

  static fetchPricesList() {
    return async(dispatch, getState) => {
      dispatch({ type: MetaDataActions.FETCH_PRICES_LIST_START });

      const { META, PRICES } = MainApiRoutes;

      const { errors } = getTextsData(getState());

      const { result: prices } = await mainApi.get(META + PRICES);

      if (prices) {
        dispatch({ type: MetaDataActions.FETCH_PRICES_LIST_DONE, payload: { prices } });

        return prices;
      }
      dispatch({ type: MetaDataActions.FETCH_PRICES_LIST_ERROR });
      toast.error(errors.whileLoadingPrices);

      return null;
    };
  }

  static fetchBanksList() {
    return async(dispatch, getState) => {
      dispatch({ type: MetaDataActions.FETCH_BANKS_LIST_START });

      const { META, BANKS } = MainApiRoutes;

      const { errors } = getTextsData(getState());

      const { results: banks } = await mainApi.get(META + BANKS);

      if (Array.isArray(banks) && banks.length) {
        dispatch({ type: MetaDataActions.FETCH_BANKS_LIST_DONE, payload: { banks } });

        return banks;
      }
      dispatch({ type: MetaDataActions.FETCH_BANKS_LIST_ERROR });
      toast.error(errors.whileLoadingBanks);

      return null;
    };
  }
}
