import Css from "./style.module.scss";

import { Button } from "shards-react";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import TableContext from "./TableContext";
import classNames from "classnames";

const {
  TABLE_PAGE_SIZE,
  TABLE_PAGE_SIZE_MEDIUM,
  TABLE_PAGE_SIZE_MAXIMUM
} = Constants;

const PAGE_SIZE = [
  TABLE_PAGE_SIZE,
  TABLE_PAGE_SIZE_MEDIUM,
  TABLE_PAGE_SIZE_MAXIMUM
];

const INPUT_DELAY = 1000;

const THEMES = {
  flat: Css.flat
};

const TableNew = (props) => {
  const {
    theme,
    tableRef,
    children,
    className,
    sortBy,
    sortOrder,
    count,
    page,
    pageSize,
    showFooter = true,
    onSortChange,
    onPageChange,
    onPageSizeChange,
    ...restProps
  } = props;

  const { uiTexts } = useSelector(getTextsData);

  const timeoutRef = useRef();

  const pageCount = (count && pageSize) ? Math.ceil(count / pageSize) : 0;

  const [pageValue, setPageValue] = useState("");

  const contextValue = useMemo(() => {
    return { sortBy, sortOrder, onSortChange };
  }, [sortBy, sortOrder, onSortChange]);

  const handleInputChange = useCallback((event) => {
    const newValue = event.target.value;

    clearTimeout(timeoutRef.current);

    if (newValue) {
      const number = Number(newValue);

      if (isNaN(number)) return;

      const nextValue = Math.min(Math.max(1, parseInt(number, 10)), pageCount);

      setPageValue(nextValue);

      timeoutRef.current = setTimeout(() => {
        if (onPageChange) onPageChange(nextValue);
      }, INPUT_DELAY);
    } else {
      setPageValue(newValue, event);
    }
  }, [pageCount, onPageChange]);

  const handleInputBlur = useCallback(() => {
    if (pageValue && (page !== pageValue) && onPageChange) {
      clearTimeout(timeoutRef.current);
      onPageChange(pageValue);
    }
  }, [page, pageValue, onPageChange]);

  const handlePrevClick = useCallback(() => {
    if (onPageChange) onPageChange(page - 1);
  }, [page, onPageChange]);

  const handleNextClick = useCallback(() => {
    if (onPageChange) onPageChange(page + 1);
  }, [page, onPageChange]);

  const handleSelectChange = useCallback((event) => {
    if (onPageSizeChange) onPageSizeChange(+event.target.value);
  }, [onPageSizeChange]);

  useEffect(() => {
    setPageValue(Math.min(Math.max(1, parseInt(page, 10)), pageCount));
  }, [page, pageCount]);

  return (
    <TableContext.Provider value={contextValue}>
      <div {...restProps} className={classNames(Css.table, THEMES[theme], className)} ref={tableRef}>
        {children}
        {showFooter && (count > pageSize || count > Constants.TABLE_PAGE_SIZE) && (
          <div className={Css.footer}>
            <div className={Css.prev}>
              <Button onClick={handlePrevClick} disabled={page === 1}>{uiTexts.previous}</Button>
            </div>
            <div className={Css.paginator}>
              <span>{uiTexts.page}</span>
              <input
                type="number"
                disabled={pageCount === 1}
                value={pageValue}
                onChange={handleInputChange}
                onBlur={handleInputBlur} />
              <span>{`${uiTexts.of.toLowerCase()} ${pageCount}`}</span>
              <select value={pageSize} onChange={handleSelectChange}>
                {PAGE_SIZE.map((size) => (
                  <option key={size} value={size}>
                    {`${size} ${uiTexts.rows.toLowerCase()}`}
                  </option>
                ))}
              </select>
            </div>
            <div className={Css.next}>
              <Button onClick={handleNextClick} disabled={page === pageCount}>{uiTexts.next}</Button>
            </div>
          </div>
        )}
      </div>
    </TableContext.Provider>
  );
};

export default TableNew;

export { default as TableCell } from "./lib/TableCell";
export { default as TableHead } from "./lib/TableHead";
export { default as TableRow } from "./lib/TableRow";
