import InstitutionsActions from "actions/InstitutionsActions";

const {
  FETCH_INSTITUTIONS_LIST_START,
  FETCH_INSTITUTIONS_LIST_DONE,
  FETCH_INSTITUTIONS_LIST_ERROR,
  FETCH_INSTITUTIONS_ACCOUNTS_LIST_START,
  FETCH_INSTITUTIONS_ACCOUNTS_LIST_DONE,
  FETCH_INSTITUTIONS_ACCOUNTS_LIST_ERROR,
  DELETE_INSTITUTION_ACCOUNT_START,
  DELETE_INSTITUTION_ACCOUNT_DONE,
  DELETE_INSTITUTION_ACCOUNT_ERROR,
  GET_CONNECTING_LINK_START,
  GET_CONNECTING_LINK_DONE,
  GET_CONNECTING_LINK_ERROR,
  COMPLETE_SETUP_START,
  COMPLETE_SETUP_DONE,
  COMPLETE_SETUP_ERROR,
  FETCH_INSTITUTION_LINKS_START,
  FETCH_INSTITUTION_LINKS_DONE,
  FETCH_INSTITUTION_LINKS_ERROR,
  FETCH_LINKS_EXPIRATION_INFO_DONE
} = InstitutionsActions;

const initialState = {
  fetching: false,
  fetchingLinks: false,
  fetchingAccounts: false,
  fetchingConnectionLink: false,
  data: [],
  accounts: [],
  links: [],
  linkExpirationsInfo: []
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_INSTITUTIONS_LIST_START:
      return { ...state, fetching: true };
    case GET_CONNECTING_LINK_START:
      return { ...state, fetchingConnectionLink: true };
    case FETCH_INSTITUTION_LINKS_START: {
      const { links } = state;

      const { clearList, backgroundUpdate } = payload;

      return {
        ...state,
        links: clearList ? [] : links,
        fetchingLinks: !backgroundUpdate
      };
    }
    case COMPLETE_SETUP_START:
      return { ...state, fetchingLinks: true };
    case DELETE_INSTITUTION_ACCOUNT_START:
      return { ...state, fetchingAccounts: true };

    case FETCH_INSTITUTIONS_ACCOUNTS_LIST_START: {
      const { accounts } = state;

      const { clearList, backgroundUpdate } = payload;

      return {
        ...state,
        accounts: clearList ? [] : accounts,
        fetchingAccounts: !backgroundUpdate
      };
    }

    case FETCH_INSTITUTIONS_LIST_DONE:
      return { ...state, data: payload, fetching: false };
    case GET_CONNECTING_LINK_DONE:
      return { ...state, fetchingConnectionLink: false };
    case COMPLETE_SETUP_DONE:
      return { ...state, fetchingLinks: false };
    case FETCH_INSTITUTION_LINKS_DONE:
      return { ...state, links: payload, fetchingLinks: false };

    case DELETE_INSTITUTION_ACCOUNT_DONE: {
      const { accounts } = state;

      const { accountId } = payload;

      return { ...state, fetchingAccounts: false, accounts: accounts.filter(({ id }) => id !== accountId) };
    }

    case FETCH_INSTITUTIONS_ACCOUNTS_LIST_DONE:
      return { ...state, accounts: payload, fetchingAccounts: false };

    case FETCH_INSTITUTIONS_LIST_ERROR:
      return { ...state, fetching: false };
    case GET_CONNECTING_LINK_ERROR:
      return { ...state, fetchingConnectionLink: false };
    case COMPLETE_SETUP_ERROR:
    case FETCH_INSTITUTION_LINKS_ERROR:
      return { ...state, fetchingLinks: false };
    case DELETE_INSTITUTION_ACCOUNT_ERROR:
      return { ...state, fetchingAccounts: false };

    case FETCH_INSTITUTIONS_ACCOUNTS_LIST_ERROR:
      return { ...state, fetchingAccounts: false };

    case FETCH_LINKS_EXPIRATION_INFO_DONE:
      return { ...state, linkExpirationsInfo: payload };

    default:
      return state;
  }
};
