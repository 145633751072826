import RoboticActions from "actions/RoboticActions";

const {
  START_RPA_WORKFLOW_START,
  START_RPA_WORKFLOW_DONE,
  START_RPA_WORKFLOW_ERROR
} = RoboticActions;

const initialState = {
  fetchingData: false
};

export default (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case START_RPA_WORKFLOW_START:
      return { ...state, fetchingData: true };
    case START_RPA_WORKFLOW_DONE:
    case START_RPA_WORKFLOW_ERROR:
      return { ...state, fetchingData: false };
    default:
      return state;
  }
};
