import { Auth0Client as AuthZeroClient } from "@auth0/auth0-spa-js";
import UiRoutes from "const/UiRoutes";
import moment from "moment";

export default class AuthZeroApi {
  client = null;

  domain = null;

  audience = null;

  clientId = null;

  redirectUrl = null;

  constructor(domain, audience, clientId) {
    this.domain = domain;
    this.audience = audience;
    this.clientId = clientId;
    this.redirectUrl = window.location.origin;
  }

  getClient() {
    if (!this.client) {
      this.client = new AuthZeroClient({
        domain: this.domain,
        audience: this.audience,
        client_id: this.clientId,
        redirect_uri: this.redirectUrl,
        cacheLocation: "localstorage",
        avatar: null
      });
    }

    return this.client;
  }

  async getAuthToken(callbackState) {
    let token = null;

    try {
      const client = this.getClient();

      token = await client.getTokenSilently({
        ignoreCache: !callbackState && window.location.pathname === UiRoutes.MAIN
      });

      if (token) {
        const payload = JSON.parse(atob(token.split(".")[1]));

        const checkAudience = payload && Array.isArray(payload.aud) && payload.aud.includes(this.audience);

        const checkExpiration = payload && payload.exp >= moment().unix();

        if (!checkAudience || !checkExpiration) token = null;
      }
    } catch (error) {
      token = null;
    }

    return token;
  }

  async getUserInfo() {
    try {
      const client = this.getClient();

      const userInfo = await client.getUser();

      return userInfo;
    } catch (error) {
      return null;
    }
  }

  async loginWithRedirect(appState) {
    try {
      const client = this.getClient();

      await client.loginWithRedirect(appState ? { appState } : {});

      return true;
    } catch (error) {
      return false;
    }
  }

  async logoutUser() {
    try {
      const client = this.getClient();

      await client.logout({ returnTo: this.redirectUrl });

      return true;
    } catch (error) {
      return false;
    }
  }

  async handleRedirectCallback() {
    try {
      const client = this.getClient();

      const callbackResult = await client.handleRedirectCallback();

      return callbackResult;
    } catch (error) {
      return null;
    }
  }
}
