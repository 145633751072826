import ArchiveActions from "actions/ArchiveActions";
import CommentsActions from "actions/CommentsActions";
import DataConstants from "const/DataConstants";
import Utils from "utils/Utils";
import moment from "moment";

const { COMMENT_TARGET_TYPES } = DataConstants;

const {
  FETCH_FILES_LIST_START,
  DOWNLOAD_FILE_START,
  UPLOAD_FILE_START,
  EDIT_FILE_START,
  DELETE_FILE_START,
  BULK_FILES_UPDATE_START,
  FETCH_FILES_LIST_DONE,
  DOWNLOAD_FILE_DONE,
  UPLOAD_FILE_DONE,
  EDIT_FILE_DONE,
  DELETE_FILE_DONE,
  BULK_FILES_UPDATE_DONE,
  FETCH_FILES_LIST_ERROR,
  DOWNLOAD_FILE_ERROR,
  UPLOAD_FILE_ERROR,
  EDIT_FILE_ERROR,
  DELETE_FILE_ERROR,
  BULK_FILES_UPDATE_ERROR,
  UPLOAD_FILES_START,
  UPLOAD_FILES_DONE,
  UPLOAD_FILES_ERROR
} = ArchiveActions;

const { MARK_ALL_AS_READ_DONE, ADD_NEW_COMMENT_START, FETCH_COMMENTS_DONE } = CommentsActions;

const initialState = {
  fetchingData: false,
  data: []
};

const sortByFromDate = (data) => {
  return [...data].sort(({ fromDate: fromDateA }, { fromDate: fromDateB }) => moment.utc(fromDateB).diff(moment.utc(fromDateA)));
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_FILES_LIST_START:
      return { ...state, data: payload.clearList ? [] : state.data, fetchingData: !payload.backgroundUpdate };

    case DOWNLOAD_FILE_START:
    case UPLOAD_FILE_START:
    case EDIT_FILE_START:
    case DELETE_FILE_START:
    case BULK_FILES_UPDATE_START:
      return { ...state, fetchingData: true };

    case FETCH_FILES_LIST_DONE:
      return { ...state, data: payload.files, fetchingData: false };

    case DOWNLOAD_FILE_DONE:
      return {
        ...state,
        data: state.data.map((file) => ({ ...file, notRead: file.id === payload.fileId ? false : file.notRead })),
        fetchingData: false
      };

    case UPLOAD_FILE_DONE:
      return {
        ...state,
        uploadingCount: state.uploadingCount ? state.uploadingCount - 1 : 0,
        data: [...state.data, payload.file],
        fetchingData: false
      };

    case EDIT_FILE_DONE:
      return {
        ...state,
        data: sortByFromDate(state.data.map((file) => {
          return file.id === payload.file.id ? { ...file, ...payload.file } : file;
        })),
        fetchingData: false
      };

    case DELETE_FILE_DONE:
      return {
        ...state,
        data: state.data.filter(({ id }) => id !== payload.fileId),
        fetchingData: false
      };

    case BULK_FILES_UPDATE_DONE:
      return {
        ...state,
        data: payload.path || payload.tags
          ? state.data.map((fileData) => {
            return payload.filesIds.includes(fileData.id)
              ? {
                ...fileData,
                path: payload.path || fileData.path,
                tags: payload.tags ? [...new Set([...fileData.tags, ...payload.tags])] : fileData.tags
              }
              : fileData;
          })
          : state.data.filter(({ id }) => !payload.filesIds.includes(id)),
        fetchingData: false
      };

    case UPLOAD_FILES_START:
      return {
        ...state,
        uploadingCount: (state.uploadingCount || 0) + payload.filesCount,
        fetchingData: !payload.backgroundUpdate
      };

    case FETCH_FILES_LIST_ERROR:
    case DOWNLOAD_FILE_ERROR:
    case UPLOAD_FILE_ERROR:
    case EDIT_FILE_ERROR:
    case DELETE_FILE_ERROR:
    case BULK_FILES_UPDATE_ERROR:
    case UPLOAD_FILES_DONE:
    case UPLOAD_FILES_ERROR:
      return { ...state, fetchingData: false };

    case MARK_ALL_AS_READ_DONE:
      return {
        ...state,
        data: state.data.map((item) => ({
          ...item,
          comments: item.comments && { ...item.comments, unread: 0 }
        }))
      };

    case ADD_NEW_COMMENT_START: {
      const { targetType, targetId, text, createdBy } = payload;

      if (targetType !== COMMENT_TARGET_TYPES.ARCHIVE) return state;

      return {
        ...state,
        data: Utils.arrayUpdateItemById(state.data, targetId, (item) => {
          const { all = 0, ...rest } = item.comments || {};

          return {
            ...item,
            comments: { ...rest, all: all + 1 },
            lastComment: { text, createdBy }
          };
        })
      };
    }

    case FETCH_COMMENTS_DONE: {
      const { targetType, targetId } = payload;

      if (targetType !== COMMENT_TARGET_TYPES.ARCHIVE) return state;

      return {
        ...state,
        data: Utils.arrayUpdateItemById(state.data, targetId, (item) => {
          return { ...item, comments: { ...item.comments, unread: 0 } };
        })
      };
    }

    default:
      return state;
  }
};
