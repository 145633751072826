import Css from "./style.module.scss";

import { DatePicker, FormInput } from "shards-react";
import { getActiveOrganization } from "selectors/organizations";
import { useSelector } from "react-redux";
import Countries from "const/Countries";
import React, { useCallback, useMemo } from "react";
import Utils from "utils/Utils";
import classNames from "classnames";
import moment from "moment";

const TIME_FORMAT = "HH:mm";

const MONTH_DATE_FORMAT = "MMM yyyy";

const DATE_FORMATS = {
  US: "MM/dd/yyyy",
  EU: "dd.MM.yyyy"
};

const DateTimeInput = (props) => {
  const {
    showRawValue,
    placeholder,
    invalid,
    valid,
    disabled,
    showMonthYearPicker,
    showTimeSelect,
    selectsStart,
    selectsEnd,
    timeIntervals,
    inputRef,
    className,
    onChange
  } = props;

  const activeOrganization = useSelector(getActiveOrganization);

  const popperModifiers = useMemo(() => ({
    preventOverflow: {
      enabled: true,
      escapeWithReference: false,
      boundariesElement: "viewport"
    }
  }), []);

  const handleCalendarOpen = useCallback(() => {
    window.document.body.dataset.calendar = "";
  }, []);

  const handleCalendarClose = useCallback(() => {
    delete window.document.body.dataset.calendar;
  }, []);

  const handleFocus = useCallback((event) => {
    if (Utils.checkIsTouchDevice()) {
      event.preventDefault();
      event.target.blur();
    }
  }, []);

  const handleBlur = useCallback((event) => {
    if (!moment(event.target.value).isValid()) {
      event.target.value = "";
      onChange("");
    }
  }, [onChange]);

  const handleChange = useCallback((inputValue) => {
    onChange(inputValue ? Utils.formatNoTimeZoneDate(inputValue, false) : "");
  }, [onChange]);

  const value = useMemo(() => {
    if (showRawValue || !props.value) return props.value;

    const formatted = Utils.formatNoTimeZoneDate(props.value);

    const dateObject = moment(formatted);

    if (dateObject.isValid()) return dateObject.toDate();

    return undefined;
  }, [props.value, showRawValue]);

  const startDate = useMemo(() => {
    if (props.startDate) {
      const formatted = Utils.formatNoTimeZoneDate(props.startDate);

      const dateObject = moment(formatted);

      if (dateObject.isValid()) return dateObject.toDate();
    }

    return undefined;
  }, [props.startDate]);

  const openToDate = useMemo(() => {
    if (props.openToDate) {
      const formatted = Utils.formatNoTimeZoneDate(props.openToDate);

      const dateObject = moment(formatted);

      if (dateObject.isValid()) return dateObject.toDate();
    }

    return undefined;
  }, [props.openToDate]);

  const endDate = useMemo(() => {
    if (props.endDate) {
      const formatted = Utils.formatNoTimeZoneDate(props.endDate);

      const dateObject = moment(formatted);

      if (dateObject.isValid()) return dateObject.toDate();
    }

    return undefined;
  }, [props.endDate]);

  const minDate = useMemo(() => {
    if (props.minDate) {
      const formatted = Utils.formatNoTimeZoneDate(props.minDate);

      const dateObject = moment(formatted);

      if (dateObject.isValid()) return dateObject.toDate();
    }

    return undefined;
  }, [props.minDate]);

  const maxDate = useMemo(() => {
    if (props.maxDate) {
      const formatted = Utils.formatNoTimeZoneDate(props.maxDate);

      const dateObject = moment(formatted);

      if (dateObject.isValid()) return dateObject.toDate();
    }

    return undefined;
  }, [props.maxDate]);

  if (showRawValue) return <FormInput ref={inputRef} className={className} disabled={disabled} value={value} />;

  const dateFormat = activeOrganization.countryCode === Countries.US ? DATE_FORMATS.US : DATE_FORMATS.EU;

  const dateTimeFormat = `${dateFormat} ${TIME_FORMAT}`;

  return (
    <DatePicker
      fixedHeight
      ref={inputRef}
      showMonthDropdown={!showMonthYearPicker}
      showYearDropdown={!showMonthYearPicker}
      showTimeSelect={!showMonthYearPicker && showTimeSelect}
      showMonthYearPicker={showMonthYearPicker}
      selectsStart={selectsStart}
      selectsEnd={selectsEnd}
      timeFormat={TIME_FORMAT}
      dateFormat={showMonthYearPicker ? MONTH_DATE_FORMAT : (showTimeSelect ? dateTimeFormat : dateFormat)}
      timeIntervals={timeIntervals}
      placeholderText={placeholder}
      disabled={disabled}
      selected={value || (selectsStart && endDate) || (selectsEnd && startDate)}
      startDate={startDate}
      openToDate={openToDate}
      endDate={endDate}
      minDate={minDate}
      maxDate={maxDate}
      dropdownMode="select"
      className={classNames(className, { "is-invalid": invalid, "is-valid": valid })}
      calendarClassName={Css.dateTimeInputCalendar}
      popperModifiers={popperModifiers}
      onCalendarOpen={handleCalendarOpen}
      onCalendarClose={handleCalendarClose}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onChange={handleChange} />
  );
};

export default React.memo(DateTimeInput);
