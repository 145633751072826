import { useCallback } from "react";
import { useDispatch } from "react-redux";

import UiActions from "actions/UiActions";

const useShowCommonModal = () => {
  const dispatch = useDispatch();

  const showModal = useCallback((params = {}) => {
    const { text, headerText, confirm, size, okButtonText, cancelButtonText } = params;

    return dispatch(
      UiActions.showModal(text, headerText, confirm, size, okButtonText, cancelButtonText)
    );
  }, [dispatch]);

  return showModal;
};

export default useShowCommonModal;
