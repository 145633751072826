import { memoize } from "decko";

export default class UserRoles {
  static ACCOUNTANT_ADMIN = {
    roleId: "accountant:admin",
    langId: "accountantAdmin",
    restrictions: []
  };

  static BUSINESS_ADMIN = {
    roleId: "business:admin",
    langId: "businessAdmin",
    restrictions: [
      "reports:create",
      "reports:read"
    ]
  };

  static ACCOUNTANT_MANAGER = {
    roleId: "accountant:manager",
    langId: "accountantManager",
    restrictions: [
      "organizations:read",
      "businesses:create",
      "businesses:delete",
      "users:create",
      "users:read",
      "users:update",
      "users:delete",
      "usage-stats:read"
    ]
  };

  static BUSINESS_MANAGER = {
    roleId: "business:manager",
    langId: "businessManager",
    restrictions: [
      "organizations:read",
      "businesses:create",
      "businesses:delete",
      "users:create",
      "users:read",
      "users:update",
      "users:delete",
      "usage-stats:read",
      "documents:update",
      "transactions:update",
      "transactions:delete",
      "archive:delete",
      "reports:create",
      "reports:read"
    ]
  };

  @memoize
  static getRoles() {
    return [
      UserRoles.ACCOUNTANT_ADMIN,
      UserRoles.ACCOUNTANT_MANAGER,
      UserRoles.BUSINESS_MANAGER
    ];
  }

  @memoize
  static getIds() {
    return [
      UserRoles.ACCOUNTANT_ADMIN.roleId,
      UserRoles.ACCOUNTANT_MANAGER.roleId,
      UserRoles.BUSINESS_MANAGER.roleId
    ];
  }

  @memoize
  static getRestrictions(roleId = "") {
    return (UserRoles.getRoles().find((role) => role.roleId === roleId) || { restrictions: [] }).restrictions;
  }

  @memoize
  static getLangId(roleId = "") {
    return (UserRoles.getRoles().find((role) => role.roleId === roleId) || { langId: "unknown" }).langId;
  }

  @memoize
  static getRoleType(roleId = "") {
    return roleId.split(":")[0];
  }

  @memoize
  static getRoleAccessLevel(roleId = "") {
    return roleId.split(":")[1];
  }

  @memoize
  static checkIsBusiness(roleId = "") {
    const type = UserRoles.getRoleType(roleId);

    return type === "business";
  }

  @memoize
  static checkIsAccountant(roleId = "") {
    const type = UserRoles.getRoleType(roleId);

    return type === "accountant";
  }

  @memoize
  static checkIsAdmin(roleId = "") {
    const accessLevel = UserRoles.getRoleAccessLevel(roleId);

    return accessLevel === "admin";
  }
}
