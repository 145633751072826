import Css from "./style.module.scss";

import { Button, ButtonGroup } from "shards-react";
import {
  FiAlertTriangle,
  FiAtSign,
  FiBox,
  FiCheck,
  FiClipboard,
  FiCreditCard,
  FiDownload,
  FiEdit,
  FiEdit3 as FiEditAlt,
  FiExternalLink,
  FiFilePlus,
  FiFileText,
  FiGrid,
  FiLayers,
  FiLink2 as FiLink,
  FiMail,
  FiMapPin,
  FiMoreHorizontal,
  FiLink as FiPair,
  FiPercent,
  FiRefreshCw,
  FiRepeat,
  FiRotateCcw,
  FiSettings,
  FiTag,
  FiThumbsUp,
  FiTrash2 as FiTrash,
  FiTrello,
  FiUser,
  FiUserMinus,
  FiX
} from "react-icons/fi";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import React, { useCallback, useMemo } from "react";
import classNames from "classnames";

const ACTION_BUTTONS = [
  {
    iconComponent: FiUser,
    buttonTheme: "primary",
    langId: "linkContact",
    disabledStateProp: "disabledLinkContact",
    actionHandlerProp: "onLinkContact"
  },
  {
    iconComponent: FiTag,
    buttonTheme: "primary",
    langId: "addTags",
    disabledStateProp: "disabledAddTags",
    actionHandlerProp: "onAddTags"
  },
  {
    iconComponent: FiEdit,
    buttonTheme: "primary",
    langId: "addReason",
    disabledStateProp: "disabledAddReason",
    actionHandlerProp: "onAddReason"
  },
  {
    iconComponent: FiGrid,
    buttonTheme: "primary",
    langId: "selectCategory",
    disabledStateProp: "disabledSelectCategory",
    actionHandlerProp: "onSelectCategory"
  },
  {
    iconComponent: FiBox,
    buttonTheme: "primary",
    langId: "selectItem",
    disabledStateProp: "disabledSelectItem",
    actionHandlerProp: "onSelectItem"
  },
  {
    iconComponent: FiLayers,
    buttonTheme: "primary",
    langId: "selectClass",
    disabledStateProp: "disabledSelectClass",
    actionHandlerProp: "onSelectClass"
  },
  {
    iconComponent: FiPercent,
    buttonTheme: "primary",
    langId: "selectTaxRate",
    disabledStateProp: "disabledSelectTaxRate",
    actionHandlerProp: "onSelectTaxRate"
  },
  {
    iconComponent: FiMapPin,
    buttonTheme: "primary",
    langId: "selectLocation",
    disabledStateProp: "disabledSelectLocation",
    actionHandlerProp: "onSelectLocation"
  },
  {
    iconComponent: FiTrello,
    buttonTheme: "primary",
    langId: "selectProject",
    disabledStateProp: "disabledSelectProject",
    actionHandlerProp: "onSelectProject"
  },
  {
    iconComponent: FiTrello,
    buttonTheme: "primary",
    langId: "selectCustomer",
    disabledStateProp: "disabledSelectProject",
    actionHandlerProp: "onSelectCustomer"
  },
  {
    iconComponent: FiTrello,
    buttonTheme: "primary",
    langId: "selectProjectOrCustomer",
    disabledStateProp: "disabledSelectProject",
    actionHandlerProp: "onSelectProjectCustomer"
  },
  {
    iconComponent: FiCreditCard,
    buttonTheme: "primary",
    langId: "selectPaymentAccount",
    disabledStateProp: "disabledSelectPaymentAccount",
    actionHandlerProp: "onSelectPaymentAccount"
  },
  {
    iconComponent: FiAtSign,
    buttonTheme: "info",
    langId: "copyEmail",
    disabledStateProp: "disabledShowEmail",
    actionHandlerProp: "onShowEmail"
  },
  {
    iconComponent: FiCheck,
    buttonTheme: "success",
    langId: "applyChanges",
    disabledStateProp: "disabledApply",
    actionHandlerProp: "onApply"
  },
  {
    iconComponent: FiExternalLink,
    buttonTheme: "royal-blue",
    disabledStateProp: "disabledGoTo",
    actionHandlerProp: "onGoTo"
  },
  {
    iconComponent: FiThumbsUp,
    buttonTheme: "success",
    langId: "markAsCorrect",
    disabledStateProp: "disabledMarkAsRevised",
    actionHandlerProp: "onMarkAsRevised"
  },
  {
    iconComponent: FiAlertTriangle,
    buttonTheme: "warning",
    langId: "warning",
    disabledStateProp: "disableWarning",
    actionHandlerProp: "onWarning"
  },
  {
    iconComponent: FiRepeat,
    buttonTheme: "secondary",
    langId: "transaction",
    disabledStateProp: "disabledShowTransaction",
    actionHandlerProp: "onShowTransaction"
  },
  {
    iconComponent: FiFileText,
    buttonTheme: "secondary",
    langId: "preview",
    disabledStateProp: "disabledPreview",
    actionHandlerProp: "onPreview"
  },
  {
    iconComponent: FiFilePlus,
    buttonTheme: "royal-blue",
    langId: "addDocument",
    disabledStateProp: "addDocument",
    actionHandlerProp: "onAddDocument"
  },
  {
    iconComponent: FiSettings,
    buttonTheme: "primary",
    langId: "settings",
    disabledStateProp: "disabledOpenSettings",
    actionHandlerProp: "onOpenSettings"
  },
  {
    iconComponent: FiLink,
    buttonTheme: "primary",
    langId: "pair",
    disabledStateProp: "disabledLink",
    actionHandlerProp: "onLink"
  },
  {
    iconComponent: FiPair,
    buttonTheme: "royal-blue",
    langId: "pair",
    disabledStateProp: "disabledPair",
    actionHandlerProp: "onPair"
  },
  {
    iconComponent: FiMoreHorizontal,
    buttonTheme: "light",
    langId: "details",
    disabledStateProp: "disabledDetails",
    actionHandlerProp: "onDetails"
  },
  {
    iconComponent: FiDownload,
    buttonTheme: "light",
    langId: "download",
    disabledStateProp: "disabledDownload",
    actionHandlerProp: "onDownload"
  },
  {
    iconComponent: FiCheck,
    buttonTheme: "success",
    langId: "approve",
    disabledStateProp: "disabledApprove",
    actionHandlerProp: "onApprove"
  },
  {
    iconComponent: FiClipboard,
    buttonTheme: "secondary",
    langId: "copy",
    disabledStateProp: "disabledCopy",
    actionHandlerProp: "onCopy"
  },
  {
    iconComponent: FiMail,
    buttonTheme: "info",
    langId: "resendEmail",
    disabledStateProp: "disabledResendInvite",
    actionHandlerProp: "onResendInvite"
  },
  {
    iconComponent: FiRefreshCw,
    buttonTheme: "primary",
    langId: "reconnect",
    disabledStateProp: "disabledReconnect",
    actionHandlerProp: "onReconnect"
  },
  {
    iconComponent: FiEditAlt,
    buttonTheme: "primary",
    langId: "edit",
    disabledStateProp: "disabledEdit",
    actionHandlerProp: "onEdit"
  },
  {
    iconComponent: FiTrash,
    buttonTheme: "danger",
    langId: "delete",
    disabledStateProp: "disabledDelete",
    actionHandlerProp: "onDelete"
  },
  {
    iconComponent: FiRotateCcw,
    buttonTheme: "danger",
    langId: "resetAndRedo",
    disabledStateProp: "disabledResetAndRedo",
    actionHandlerProp: "onResetAndRedo"
  },
  {
    iconComponent: FiX,
    buttonTheme: "danger",
    langId: "dismiss",
    disabledStateProp: "disabledDismiss",
    actionHandlerProp: "onDismiss"
  },
  {
    iconComponent: FiUserMinus,
    buttonTheme: "danger",
    langId: "revoke",
    disabledStateProp: "disabledRevokeUser",
    actionHandlerProp: "onRevokeUser"
  },
  {
    iconComponent: FiX,
    buttonTheme: "light",
    langId: "unselect",
    disabledStateProp: "disabledUnselect",
    actionHandlerProp: "onUnselect"
  }
];

const ActionButton = React.memo((props) => {
  const {
    className,
    title,
    itemId,
    children,
    onClick,
    ...restProps
  } = props;

  const handleClick = useCallback(() => onClick(itemId), [itemId, onClick]);

  return (
    <Button
      className={className}
      title={title}
      onClick={handleClick}
      {...restProps}>
      {children}
    </Button>
  );
});

const ActionsButtons = ({ className, disabled, itemId, ...restProps }) => {
  const { uiTexts } = useSelector(getTextsData);

  const activeButtons = useMemo(() => {
    return ACTION_BUTTONS.filter(({ actionHandlerProp }) => restProps[actionHandlerProp]);
  }, [restProps]);

  const uniqueId = useMemo(() => uuid(), []);

  return (
    <ButtonGroup className={classNames(Css.actionsButtons, className)} disabled={disabled}>
      {activeButtons.map(({
        buttonTheme,
        iconClassName,
        langId,
        disabledStateProp,
        actionHandlerProp,
        iconComponent: Icon,
        ...restButtonProps
      }) => {
        const onClick = restProps[actionHandlerProp];

        return (
          <ActionButton
            key={actionHandlerProp}
            className={classNames(actionHandlerProp, uniqueId)}
            theme={buttonTheme}
            itemId={itemId}
            title={uiTexts[langId]}
            disabled={restProps[disabledStateProp]}
            onClick={onClick}
            {...restButtonProps}>
            <Icon className={iconClassName} />
          </ActionButton>
        );
      })}
    </ButtonGroup>
  );
};

export default React.memo(ActionsButtons);
