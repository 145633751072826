import { useEffect, useRef } from "react";

const useInterval = (callback, delay, runImmediately = false) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const localCallback = () => {
      savedCallback.current();
    };

    const intervalId = setInterval(localCallback, delay);

    if (runImmediately) localCallback();

    return () => clearInterval(intervalId);
  }, [delay, runImmediately]);
};

export default useInterval;
