import * as History from "history";
import * as Store from "store";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import App from "App";
import React from "react";
import ReactDom from "react-dom";
import Utils from "utils/Utils";

export default class Main {
  static initApp(rootNode) {
    if (Utils.checkIsTouchDevice()) {
      window.document.body.dataset.mobile = "";
    } else {
      window.document.body.dataset.desktop = "";
    }
    this.history = History.createBrowserHistory();
    this.store = Store.createStore({ history: this.history });
    Main.renderApp(rootNode);
  }

  static renderApp(rootNode) {
    ReactDom.render(
      <Provider store={this.store}>
        <ConnectedRouter history={this.history}>
          <App />
        </ConnectedRouter>
      </Provider>,
      rootNode
    );
  }
}
