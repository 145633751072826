import Css from "./style.module.scss";

import { BiLoaderAlt } from "react-icons/bi";
import React from "react";
import classNames from "classnames";

const Preloader = ({ text, fixed, absolute, className }) => (
  <div className={classNames(Css.preloader, fixed && Css.fixed, absolute && Css.absolute, className)}>
    <div>
      <BiLoaderAlt />
    </div>
    {text && <span>{text}</span>}
  </div>
);

export default React.memo(Preloader);
