import Css from "lib/common/TableNew/style.module.scss";

import React from "react";
import classNames from "classnames";

const TableRow = (props) => {
  const {
    children,
    className,
    highlight,
    positive,
    negative,
    warning,
    special,
    ...restProps
  } = props;

  return (
    <div
      className={classNames(
        Css.tableRow,
        highlight && Css.highlight,
        positive && Css.positive,
        negative && Css.negative,
        warning && Css.warning,
        special && Css.special,
        className
      )}
      {...restProps}>
      {children}
    </div>
  );
};

export default TableRow;
