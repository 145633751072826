import Css from "./style.module.scss";

import React, { useMemo } from "react";
import classNames from "classnames";
import stringToColor from "string-to-color";

const Avatar = ({ name = "", backgroundColor, onlyOneLetter, avatarUrl, className }) => {
  const [firstName, secondName = ""] = useMemo(() => {
    return name.split(" ").map((str) => str.replace(/\W/g, ""));
  }, [name]);

  return (
    <div
      className={classNames(Css.avatar, className)}
      style={avatarUrl ? undefined : { backgroundColor: backgroundColor || stringToColor(name) }}>
      <div data-letters={avatarUrl ? undefined : ""} >
        {avatarUrl ? <img src={avatarUrl} /> : !!firstName[0] && (
          <span>{(firstName[0] + (!onlyOneLetter && secondName ? secondName[0] : "")).toUpperCase()}</span>
        )}
      </div>
    </div>
  );
};

export default React.memo(Avatar);
