import SummaryActions from "actions/SummaryActions";

const {
  FETCH_SUMMARY_DATA_START,
  FETCH_SUMMARY_DATA_DONE,
  FETCH_SUMMARY_DATA_ERROR
} = SummaryActions;

const initialState = {
  fetchingData: false,
  lastUpdatedAt: null,
  data: []
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_SUMMARY_DATA_START:
      return {
        ...state,
        fetchingData: !payload.backgroundUpdate
      };

    case FETCH_SUMMARY_DATA_DONE:
      return { ...state, lastUpdatedAt: payload.lastUpdatedAt, data: payload.summary, fetchingData: false };

    case FETCH_SUMMARY_DATA_ERROR:
      return { ...state, fetchingData: false };

    default:
      return state;
  }
};
