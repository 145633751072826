import { createSelector } from "reselect";
import Constants from "const/Constants";
import moment from "moment";

export const checkInstitutionsFetching = ({ institutions: { fetching } }) => fetching;

export const checkInstitutionAccountsFetching = ({ institutions: { fetchingAccounts } }) => fetchingAccounts;

export const checkInstitutionLinksFetching = ({ institutions: { fetchingLinks } }) => fetchingLinks;

export const checkInstitutionConnectionLinkFetching = ({ institutions: { fetchingConnectionLink } }) => fetchingConnectionLink;

export const getLinkExpirationsInfoData = ({ institutions: { linkExpirationsInfo = [] } }) => linkExpirationsInfo
  .sort((
    {
      businessName: businessNameA,
      institutionName: institutionNameA,
      expired: expiredA,
      disconnected: disconnectedA,
      expiresAt: expiresAtA
    },
    {
      businessName: businessNameB,
      institutionName: institutionNameB,
      expired: expiredB,
      disconnected: disconnectedB,
      expiresAt: expiresAtB
    }
  ) => {
    const stateA = expiredA
      ? "expired"
      : (disconnectedA ? "disconnected" : `expires${moment.utc(expiresAtA).unix()}`);

    const stateB = expiredB
      ? "expired"
      : (disconnectedB ? "disconnected" : `expires${moment.utc(expiresAtB).unix()}`);

    return `${stateA} ${businessNameA} ${institutionNameA}`.toLocaleLowerCase()
      .localeCompare(`${stateB} ${businessNameB} ${institutionNameB}`);
  });

export const getInstitutionsData = ({ institutions: { data } }) => data.sort(({ name: nameA = "" }, { name: nameB = "" }) => {
  return nameA.toLocaleLowerCase().localeCompare(nameB.toLocaleLowerCase());
});

export const getInstitutionAccountsData = createSelector(
  [({ institutions: { accounts } }) => accounts],
  (accounts) => {
    return accounts.map((account) => {
      const { name, accountNumber, currency } = account;

      return {
        ...account,
        name: `${name}${
          currency ? ` ${currency}` : ""
        }${
          accountNumber ? ` (*${accountNumber.substr(-Constants.ACCOUNT_NUMBER_SUBSTRING_LENGTH)})` : ""
        }`
      };
    });
  }
);

export const getInstitutionLinksData = createSelector(
  [
    getInstitutionsData,
    getInstitutionAccountsData,
    ({ institutions: { links } }) => links
  ],
  (institutions, accounts, links) => {
    return links.map((link) => {
      const institution = institutions.find(({ id }) => id === link.institutionId);

      const linkAccounts = accounts.filter(({ linkId }) => linkId === link.id).sort(({ name: nameA }, { name: nameB }) => {
        return nameA.toLocaleLowerCase().localeCompare(nameB.toLocaleLowerCase());
      });

      return {
        ...link,
        institution,
        accounts: linkAccounts
      };
    }).sort(({ institution: institutionA }, { institution: institutionB }) => {
      const nameA = (institutionA.name || "").toLocaleLowerCase();

      const nameB = (institutionB.name || "").toLocaleLowerCase();

      return nameA.localeCompare(nameB);
    });
  }
);
