import { getTextsData } from "selectors/texts";
import { mainApi } from "api";
import { toast } from "react-toastify";
import Constants from "const/Constants";
import Errors from "const/Errors";
import MainApiRoutes from "const/MainApiRoutes";
import UiActions from "actions/UiActions";
import Utils from "utils/Utils";

export default class MainApiActions {
  static SET_AUTH_TOKEN = "mainApi/SET_AUTH_TOKEN";

  static API_ERROR_OCCURRED = "mainApi/API_ERROR_OCCURRED";

  static FETCH_ATTACHMENT_URL_START = "mainApi/FETCH_ATTACHMENT_URL_START";

  static FETCH_ATTACHMENT_URL_DONE = "mainApi/FETCH_ATTACHMENT_URL_DONE";

  static FETCH_ATTACHMENT_URL_ERROR = "mainApi/FETCH_ATTACHMENT_URL_ERROR";

  static setAuthToken(token) {
    return (dispatch) => {
      dispatch({ type: MainApiActions.SET_AUTH_TOKEN, payload: { token } });
      mainApi.token = token;
    };
  }

  static fetchAttachmentUrl({ key, originalKey, originalName } = {}, useOriginal = false, silentMode = false) {
    return async(dispatch, getState) => {
      const { errors } = getTextsData(getState());

      dispatch({ type: MainApiActions.FETCH_ATTACHMENT_URL_START });

      const { ATTACHMENTS, DOWNLOAD } = MainApiRoutes;

      if (key && originalName) {
        if (useOriginal && originalKey) key = originalKey;
        if (!useOriginal) originalName = key;

        const query = Utils.objectToQueryString({ key, originalName });

        const { link } = await mainApi.get(`${ATTACHMENTS + DOWNLOAD}?${query}`);

        if (link) {
          dispatch({ type: MainApiActions.FETCH_ATTACHMENT_URL_DONE, payload: { link } });

          return link;
        }
      }
      dispatch({ type: MainApiActions.FETCH_ATTACHMENT_URL_ERROR });
      if (!silentMode) toast.error(errors.whileDownloadingFile);

      return null;
    };
  }

  static apiErrorOccurred(errorType, fatalError) {
    return (dispatch, getState) => {
      dispatch({ type: MainApiActions.API_ERROR_OCCURRED, payload: { errorType } });
      if (fatalError) {
        const { uiTexts, errors } = getTextsData(getState());

        const errorText = errorType === Errors.SESSION_EXPIRED ? errors.sessionExpired : errors.fatalError;

        setTimeout(() => { window.location.reload(); }, Constants.AUTO_PAGE_RELOAD_ON_ERROR_DELAY);
        dispatch(UiActions.showModal(errorText, uiTexts.error, false, "sm", null, uiTexts.reload))
          .then(() => {
            dispatch(UiActions.togglePreloader(true));
            window.location.reload();
          });
      }
    };
  }
}
