import AuthZeroActions from "actions/AuthZeroActions";
import OrganizationsActions from "actions/OrganizationsActions";
import UserActions from "actions/UserActions";

const {
  FETCH_AUTH_TOKEN_START,
  FETCH_USER_INFO_START,
  FETCH_AUTH_TOKEN_DONE,
  FETCH_USER_INFO_DONE,
  FETCH_USER_INFO_ERROR
} = AuthZeroActions;

const {
  FETCH_USERS_LIST_START,
  FETCH_USERS_LIST_DONE,
  FETCH_USERS_LIST_ERROR
} = OrganizationsActions;

const {
  EDIT_USER_START,
  EDIT_USER_DONE,
  EDIT_USER_ERROR
} = UserActions;

const inititalState = {
  fetchingData: false,
  token: null,
  data: {}
};

export default (state = inititalState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_AUTH_TOKEN_START:
    case FETCH_USER_INFO_START:
    case FETCH_USERS_LIST_START:
    case EDIT_USER_START: {
      const { backgroundUpdate } = payload || {};

      return { ...state, ...(backgroundUpdate ? {} : { fetchingData: true }) };
    }

    case FETCH_AUTH_TOKEN_DONE:
      return { ...state, token: payload.token, fetchingData: false };

    case FETCH_USER_INFO_DONE:
      return { ...state, data: payload.userInfo, fetchingData: false };

    case FETCH_USERS_LIST_DONE:
      return {
        ...state,
        data: { ...state.data, ...payload.users.find(({ email }) => email === state.data.email) },
        fetchingData: false
      };

    case EDIT_USER_DONE:
      return {
        ...state,
        data: {
          ...state.data,
          firstName: payload.firstName,
          lastName: payload.lastName,
          fullName: `${payload.firstName || ""} ${payload.lastName || ""}`.trim() || payload.email,
          discoverySourceAsked: payload.discoverySourceAsked
        },
        fetchingData: false
      };

    case FETCH_USER_INFO_ERROR:
    case FETCH_USERS_LIST_ERROR:
    case EDIT_USER_ERROR:
      return { ...state, fetchingData: false };

    default:
      return state;
  }
};
