import { createSelector } from "reselect";

export const checkAccountsAvailable = ({ accounts: { data } }) => !!data.length;

export const checkAccountsFetching = ({ accounts: { fetchingData } }) => fetchingData;

export const getSourcesData = ({ accounts: { sources } }) => sources;

export const getAccountsData = createSelector(
  [
    ({ accounts: { data } }) => data,
    getSourcesData
  ],
  (data, sources) => data.map(({ sourceId, ...restData }) => {
    return {
      ...restData,
      source: sources.find(({ id }) => id === sourceId)
    };
  })
);
