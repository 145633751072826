import { getSelectedBusinessId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { mainApi } from "api";
import { toast } from "react-toastify";
import MainApiRoutes from "const/MainApiRoutes";

export default class DashboardActions {
  static FETCH_DASHBOARD_DATA_START = "dashboard/FETCH_DASHBOARD_DATA_START";

  static FETCH_DASHBOARD_DATA_DONE = "dashboard/FETCH_DASHBOARD_DATA_DONE";

  static FETCH_DASHBOARD_DATA_ERROR = "dashboard/FETCH_DASHBOARD_DATA_ERROR";

  static fetchDashboardData(byType) {
    return async(dispatch, getState) => {
      dispatch({ type: DashboardActions.FETCH_DASHBOARD_DATA_START });

      const selectedBusinessId = getSelectedBusinessId(getState());

      const { errors } = getTextsData(getState());

      const { BUSINESSES, DASHBOARD } = MainApiRoutes;

      const { cashFlow, documents } = await mainApi.get(`${BUSINESSES}/${selectedBusinessId + DASHBOARD}?byType=${byType}`);

      if (cashFlow && Array.isArray(cashFlow.byMonth) && documents && Array.isArray(documents.byMonth)) {
        dispatch({ type: DashboardActions.FETCH_DASHBOARD_DATA_DONE, payload: { data: { cashFlow, documents } } });

        return { cashFlow, documents };
      }
      dispatch({ type: DashboardActions.FETCH_DASHBOARD_DATA_ERROR });
      toast.error(errors.whileLoadingDashboardData);

      return null;
    };
  }
}
