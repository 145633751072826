import Utils from "./Utils";

export const init = ({ email, fullName }, { name: activeOrganizationName }) => {
  const { INTERCOME_APP_ID } = Utils.getProcessEnvVars();

  if (!INTERCOME_APP_ID) return;

  /* eslint-disable */
  window.intercomSettings = {
    api_base: "https://api-iam.intercom.io",
    app_id: INTERCOME_APP_ID,
    email,
    name: `${fullName} (${activeOrganizationName})`
  };
  (function() {
    var w = window; var ic = w.Intercom; if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", w.intercomSettings);
    } else {
      var d = document; var i = function () { i.c(arguments); }; i.q = [];
      i.c = function (args) { i.q.push(args); }; w.Intercom = i; var l = function () {
        var s = d.createElement("script");
        s.type = "text/javascript";
        s.async = 1;
        s.src = `https://widget.intercom.io/widget/${INTERCOME_APP_ID}`;
        var x = d.getElementsByTagName("script")[0]; x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === "complete") { l(); } else if (w.attachEvent) { w.attachEvent("onload", l); }
      else { w.addEventListener("load", l, false); }
    }
  })();
  /* eslint-enable */
};
