import { getSelectedBusinessId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { mainApi } from "api";
import { toast } from "react-toastify";
import MainApiRoutes from "const/MainApiRoutes";

export default class RoboticActions {
  static START_RPA_WORKFLOW_START = "robotic/START_RPA_WORKFLOW_START";

  static START_RPA_WORKFLOW_DONE = "robotic/START_RPA_WORKFLOW_DONE";

  static START_RPA_WORKFLOW_ERROR = "robotic/START_RPA_WORKFLOW_ERROR";

  static startRpaWorkflow(workflow) {
    return async(dispatch, getState) => {
      dispatch({ type: RoboticActions.START_RPA_WORKFLOW_START });

      const { BUSINESSES, RPA, START } = MainApiRoutes;

      const { errors } = getTextsData(getState());

      const selectedBusinessId = getSelectedBusinessId(getState());

      const { ok } = await mainApi.post(`${BUSINESSES}/${selectedBusinessId}${RPA}/${workflow}${START}`);

      if (ok) {
        dispatch({ type: RoboticActions.START_RPA_WORKFLOW_DONE });

        return true;
      }
      dispatch({ type: RoboticActions.START_RPA_WORKFLOW_ERROR });
      toast.error(errors.unknown);

      return false;
    };
  }
}
