import Css from "./style.module.scss";

import classNames from "classnames";

import React from "react";

const ModalFooter = ({ className, ...restProps }) => {
  return (
    <div className={classNames(Css.modalFooter, className)} {...restProps} />
  );
};

export default React.memo(ModalFooter);
