import CommentsActions from "actions/CommentsActions";

const {
  FETCH_COMMENTS_START,
  FETCH_COMMENTS_DONE,
  FETCH_COMMENTS_ERROR,
  FETCH_UNREAD_COMMENTS_START,
  FETCH_UNREAD_COMMENTS_DONE,
  FETCH_UNREAD_COMMENTS_ERROR,
  ADD_NEW_COMMENT_START,
  ADD_NEW_COMMENT_DONE,
  ADD_NEW_COMMENT_ERROR,
  DELETE_COMMENT_START,
  DELETE_COMMENT_DONE,
  DELETE_COMMENT_ERROR,
  MARK_ALL_AS_READ_START,
  MARK_ALL_AS_READ_DONE,
  MARK_ALL_AS_READ_ERROR
} = CommentsActions;

const initialState = {
  fetchingData: false,
  fetchingUnread: false,
  targetData: { type: null, id: null },
  unread: [],
  data: []
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_COMMENTS_START:
      return { ...state, fetchingData: !payload.backgroundUpdate, data: payload.clearList ? [] : state.data };

    case FETCH_UNREAD_COMMENTS_START:
      return { ...state, fetchingUnread: true };

    case FETCH_COMMENTS_DONE:
      return {
        ...state,
        fetchingData: false,
        targetData: { type: payload.targetType, id: payload.targetId },
        data: payload.comments,
        unread: state.unread.filter(({ targetType, targetId }) => {
          return targetType !== payload.targetType || targetId !== payload.targetId;
        })
      };

    case FETCH_UNREAD_COMMENTS_DONE:
      return {
        ...state,
        fetchingUnread: false,
        unread: payload.comments
      };

    case DELETE_COMMENT_START:
    case MARK_ALL_AS_READ_START:
      return { ...state, fetchingData: true };

    case ADD_NEW_COMMENT_START: {
      const { type: commentType, text, createdBy, commentId } = payload;

      return {
        ...state,
        fetchingData: !payload.backgroundUpdate,
        data: [
          { text, createdBy, id: commentId, readBy: [createdBy], type: commentType, createdAt: new Date() },
          ...state.data
        ]
      };
    }
    case ADD_NEW_COMMENT_DONE: {
      return {
        ...state,
        fetchingData: false,
        data: [payload.comment, ...state.data.slice(1)]
      };
    }
    case ADD_NEW_COMMENT_ERROR: {
      const newData = [...state.data];

      newData.shift();

      return { ...state, fetchingData: false, data: newData };
    }
    case DELETE_COMMENT_DONE:
      return { ...state, fetchingData: false, data: state.data.filter(({ id }) => id !== payload.commentId) };

    case MARK_ALL_AS_READ_DONE:
      return { ...state, fetchingData: false, unread: [] };

    case FETCH_COMMENTS_ERROR:
    case DELETE_COMMENT_ERROR:
    case MARK_ALL_AS_READ_ERROR:
      return { ...state, fetchingData: false };

    case FETCH_UNREAD_COMMENTS_ERROR:
      return { ...state, fetchingUnread: false };

    default:
      return state;
  }
};
