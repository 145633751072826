import MetaDataActions from "actions/MetaDataActions";

const {
  FETCH_CURRENCIES_LIST_START,
  FETCH_PRICES_LIST_START,
  FETCH_BANKS_LIST_START,
  FETCH_CURRENCIES_LIST_DONE,
  FETCH_PRICES_LIST_DONE,
  FETCH_BANKS_LIST_DONE,
  FETCH_CURRENCIES_LIST_ERROR,
  FETCH_PRICES_LIST_ERROR,
  FETCH_BANKS_LIST_ERROR
} = MetaDataActions;

const initialState = {
  fetchingCurrencies: false,
  fetchingPrices: false,
  fetchingBanks: false,
  currencies: [],
  prices: {},
  banks: []
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_CURRENCIES_LIST_START:
      return { ...state, fetchingCurrencies: true };
    case FETCH_PRICES_LIST_START:
      return { ...state, fetchingPrices: true };
    case FETCH_BANKS_LIST_START:
      return { ...state, fetchingBanks: true };
    case FETCH_CURRENCIES_LIST_DONE:
      return {
        ...state,
        currencies: payload.currencies,
        fetchingCurrencies: false
      };
    case FETCH_PRICES_LIST_DONE:
      return {
        ...state,
        prices: payload.prices,
        fetchingPrices: false
      };
    case FETCH_BANKS_LIST_DONE:
      return {
        ...state,
        banks: [...payload.banks].sort(({ name: bankA }, { name: bankB }) => bankA.localeCompare(bankB)),
        fetchingBanks: false
      };
    case FETCH_CURRENCIES_LIST_ERROR:
      return { ...state, fetchingCurrencies: false };
    case FETCH_PRICES_LIST_ERROR:
      return { ...state, fetchingPrices: false };
    case FETCH_BANKS_LIST_ERROR:
      return { ...state, fetchingBanks: false };
    default:
      return state;
  }
};
