import { FormInput } from "shards-react";
import Constants from "const/Constants";
import React, { useCallback, useEffect, useRef, useState } from "react";

const DebounceInput = ({ component: Component = FormInput, value: initialValue, onChange, onInputComplete, ...restProps }) => {
  const changeTimeoutIdRef = useRef();

  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleChange = useCallback((event) => {
    const { value: newValue } = event.target;

    setValue(newValue);

    if (onChange) onChange(event);
    clearTimeout(changeTimeoutIdRef.current);
    changeTimeoutIdRef.current = setTimeout(() => {
      if (onInputComplete) onInputComplete(newValue);
    }, Constants.TEXT_FIELD_DEBOUNCE_TIMEOUT);
  }, [onChange, onInputComplete]);

  return <Component {...restProps} value={value} onChange={handleChange} />;
};

export default React.memo(DebounceInput);
