import Css from "./style.module.scss";

import { FiRefreshCw } from "react-icons/fi";
import { getInstitutionsData, getLinkExpirationsInfoData } from "selectors/institutions";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import Alert from "nlib/ui/Alert";
import Constants from "const/Constants";
import InstitutionsActions from "actions/InstitutionsActions";
import React, { useCallback, useMemo } from "react";
import ReconnectAccountModal from "./lib/ReconnectAccountModal";
import Utils from "utils/Utils";
import moment from "moment";
import useShowModal from "hooks/useShowModal";

const { ACCOUNT_EXPIRATION_DAYS_WARNING } = Constants;

const LINK_STATUSES = {
  EXPIRES_SOON: "EXPIRES_SOON",
  EXPIRED: "EXPIRED"
};

const getLinkStatus = (expiresAt) => {
  if (!expiresAt) return LINK_STATUSES.EXPIRED;

  const momentDate = moment.utc(expiresAt);

  if (momentDate.isSameOrBefore(moment.utc())) return LINK_STATUSES.EXPIRED;

  if (momentDate.isSameOrBefore(moment.utc().add(ACCOUNT_EXPIRATION_DAYS_WARNING, "days"))) return LINK_STATUSES.EXPIRES_SOON;

  return null;
};

const GlobalNotifications = () => {
  const dispatch = useDispatch();

  const [
    reconnectAccountModalShown,
    showReconnectAccountModal,
    onReconnectAccountModalClose
  ] = useShowModal();

  const { uiTexts, messages } = useSelector(getTextsData);

  const linkExpirationsInfoData = useSelector(getLinkExpirationsInfoData);

  const institutionsData = useSelector(getInstitutionsData);

  const expiredLinks = useMemo(() => {
    return linkExpirationsInfoData.filter(({ expired, disconnected }) => expired || disconnected);
  }, [linkExpirationsInfoData]);

  const expireSoonLinks = useMemo(() => {
    return linkExpirationsInfoData.filter(({ expiresAt, expired, disconnected }) => !expired && !disconnected
      && getLinkStatus(expiresAt) === LINK_STATUSES.EXPIRES_SOON);
  }, [linkExpirationsInfoData]);

  const linksToReconnectLength = expireSoonLinks.length || expiredLinks.length;

  const message = useMemo(() => {
    const [
      expirationLinksMessageA,
      expirationLinksMessageB,
      expirationLinksMessageC
    ] = messages.expirationLinksMessages;

    if (!expiredLinks.length) {
      return Utils.replaceTextVars(expirationLinksMessageA, { count: expireSoonLinks.length });
    }
    if (!expireSoonLinks.length) {
      return Utils.replaceTextVars(expirationLinksMessageB, { count: expiredLinks.length });
    }

    return Utils.replaceTextVars(expirationLinksMessageC, {
      count: expiredLinks.length,
      additionalCount: expireSoonLinks.length
    });
  }, [
    messages.expirationLinksMessages,
    expiredLinks.length,
    expireSoonLinks.length
  ]);

  const handleClick = useCallback(async() => {
    const result = await showReconnectAccountModal();

    const linkData = result && Utils.arrayFind(linkExpirationsInfoData, "linkId", result);

    if (linkData) {
      const { institutionId, businessId } = linkData;

      const institutionData = Utils.arrayFindById(institutionsData, institutionId);

      if (institutionData) {
        const { code: institutionCode } = institutionData;

        const link = await dispatch(InstitutionsActions.getConnectingLink(institutionCode, businessId));

        if (link) window.location = link;
      }
    }
  }, [institutionsData, linkExpirationsInfoData, dispatch, showReconnectAccountModal]);

  return (
    <>
      {!!linksToReconnectLength && (
        <div className={Css.globalNotifications}>
          <Alert
            type={expiredLinks.length ? "error" : "warning"}
            message={message}
            onClick={handleClick}
            actionComponent={<a>
              <FiRefreshCw />
              <span>{uiTexts.reconnect}</span>
            </a>} />
        </div>
      )}
      {reconnectAccountModalShown && (
        <ReconnectAccountModal onClose={onReconnectAccountModalClose} />
      )}
    </>
  );
};

export default React.memo(GlobalNotifications);
