import { createSelector } from "reselect";
import { getUserData } from "selectors/user";
import UserRoles from "const/UserRoles";
import Utils from "utils/Utils";

export const checkOrganizationsFetching = ({ organizations: { fetchingData } }) => fetchingData;

export const checkUsersFetching = ({ organizations: { fetchingUsers } }) => fetchingUsers;

export const getOrganizationsData = createSelector(
  [({ organizations: { data } }) => data],
  (data = {}) => {
    const namesCount = {};

    return [...data]
      .sort((
        { name: nameA, superAdminAccess: superAdminAccessA },
        { name: nameB, superAdminAccess: superAdminAccessB }
      ) => {
        const ruleA = (+!superAdminAccessB) - (+!superAdminAccessA);

        const ruleB = nameA.localeCompare(nameB);

        return ruleA || ruleB;
      })
      .map(({ name, countryCode, superAdminAccess, ...restOrganization }) => {
        let counter = namesCount[name];

        if (counter) {
          namesCount[name] = ++counter;
          name = `${name} (${counter})`;
        } else namesCount[name] = 1;

        return {
          ...restOrganization,
          superAdminAccess,
          countryCode,
          name
        };
      });
  }
);

export const getActiveOrganization = createSelector(
  [getOrganizationsData],
  (data) => data.find(({ active }) => active)
);

export const getAllUsersData = createSelector(
  [
    ({ organizations: { users } }) => users,
    getActiveOrganization,
    getUserData
  ],
  (users, organization, user) => {
    const userRolesList = UserRoles.getIds();

    return [...users].sort((
      { sub: subA, role: roleA, fullName: fullNameA, guestUser: guestUserA, pendingInvite: pendingInviteA },
      { sub: subB, role: roleB, fullName: fullNameB, guestUser: guestUserB, pendingInvite: pendingInviteB }
    ) => {
      const ruleA = organization.superAdminAccess && fullNameA === user.fullName
        ? -1
        : (organization.superAdminAccess && fullNameB === user.fullName ? 1 : 0);

      const ruleB = organization.creatorId === subA ? -1 : (organization.creatorId === subB ? 1 : 0);

      const ruleC = pendingInviteA ? 1 : (pendingInviteB ? -1 : 0);

      const ruleD = guestUserA ? 1 : (guestUserB ? -1 : 0);

      const ruleE = userRolesList.indexOf(roleA) - userRolesList.indexOf(roleB);

      const ruleF = fullNameA.localeCompare(fullNameB);

      return ruleA || ruleB || ruleC || ruleD || ruleE || ruleF;
    });
  }
);

export const getActiveUsersData = createSelector(
  [getAllUsersData],
  (users) => {
    return users.filter(({ pendingInvite }) => !pendingInvite);
  }
);

export const getProjectName = createSelector(
  [getActiveOrganization],
  (organization) => {
    const { PROJECT_NAME } = Utils.getProcessEnvVars();

    return organization?.whiteLabel ? organization.name : PROJECT_NAME;
  }
);
