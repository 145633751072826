import Css from "./style.module.scss";

import { FiCheck } from "react-icons/fi";
import { getTextsData } from "selectors/texts";
import { getUserData } from "selectors/user";
import { useSelector } from "react-redux";
import Avatar from "lib/common/Avatar";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import React, { Fragment, useCallback, useMemo } from "react";
import classNames from "classnames";
import moment from "moment";

const { MENTION_TRIGGER_CHAR } = Constants;

const { COMMENT_TYPES: { NOTE } } = DataConstants;

const MENTION_MATCH_PATTERN = new RegExp(`(${MENTION_TRIGGER_CHAR}\\S* \\([^)]*\\))|(${MENTION_TRIGGER_CHAR}\\S*)`);

const Comment = (props) => {
  const {
    disabled,
    comment,
    className,
    usersEmails,
    onUserMention
  } = props;

  const { uiTexts } = useSelector(getTextsData);

  const userData = useSelector(getUserData);

  const commentDate = useMemo(() => {
    return moment.duration(moment.utc(comment.createdAt).diff(moment.utc())).humanize(true);
  }, [comment]);

  const { createdBy, text, type, readBy } = comment;

  const { email, fullName } = createdBy || {};

  const userName = fullName || email || uiTexts.unknown;

  const read = readBy.includes(userData.id);

  const handleMentionClick = useCallback((event) => {
    if (!email || disabled) return;

    const { mention } = event.target.dataset;

    onUserMention(mention);
  }, [disabled, email, onUserMention]);

  return (
    <div className={classNames(Css.comment, className, type === NOTE && Css.note)}>
      <Avatar className={Css.avatar} name={userName} />
      <div className={Css.content}>
        <div className={Css.info}>
          <div
            className={Css.name}
            data-mention={`${MENTION_TRIGGER_CHAR}${email + (fullName === email || !fullName ? "" : ` (${fullName})`)}`}
            onClick={handleMentionClick}>{userName}</div>
          <div className={Css.date}>{commentDate}</div>
          {type === NOTE && (
            <div className={Css.noteType}>
              <span>&bull;</span>
              <span>{uiTexts.note}</span>
            </div>
          )}
          {!read && <div className={Css.status} title={uiTexts.new}><FiCheck /></div>}
        </div>
        <div className={Css.text}>
          {text.split(MENTION_MATCH_PATTERN).filter((part) => !!part).map((part) => (
            <Fragment key={`${part}-${comment.id}`}>
              {MENTION_MATCH_PATTERN.test(part) && usersEmails.includes(part.slice(1))
                ? <span className={Css.mention} data-mention={part} onClick={handleMentionClick}>{part}</span>
                : <span>{part}</span>}
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default React.memo(Comment);
