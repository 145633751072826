import Css from "./style.module.scss";

import googleDrive from "assets/googleDrive.svg";

import { Button } from "shards-react";
import { bind, memoize } from "decko";
import { connect } from "react-redux";
import { getActiveOrganization } from "selectors/organizations";
import { getLanguage, getTextsData } from "selectors/texts";
import { toast } from "react-toastify";
import Constants from "const/Constants";
import GoogleActions from "actions/GoogleActions";
import React, { PureComponent } from "react";
import Utils from "utils/Utils";

const mapStateToProps = (state) => ({
  language: getLanguage(state),
  activeOrganization: getActiveOrganization(state),
  textsData: getTextsData(state)
});

const mapDispatchToProps = (dispatch) => ({
  exchangeCode: (...args) => dispatch(GoogleActions.exchangeCode(...args))
});

@connect(mapStateToProps, mapDispatchToProps)
class GoogleDrivePicker extends PureComponent {
  static SERVICE_NAME = "Google Drive";

  static FULL_ACCESS_SCOPE = "https://www.googleapis.com/auth/drive";

  static READ_ONLY_SCOPE = "https://www.googleapis.com/auth/drive.readonly";

  static GOOGLE_DOC_MIME_TYPE = "application/vnd.google-apps.document";

  static GOOGLE_SHEET_MIME_TYPE = "application/vnd.google-apps.spreadsheet";

  state = { apiLoaded: false, authToken: null };

  @memoize
  getFileDropMimeTypes() {
    return [
      ...(Object.values(Constants.DOCUMENT_FILE_TYPES).map(({ mimeType }) => mimeType)),
      GoogleDrivePicker.GOOGLE_DOC_MIME_TYPE,
      GoogleDrivePicker.GOOGLE_SHEET_MIME_TYPE
    ].join();
  }

  loadGoogleApi(name) {
    return new Promise((resolve) => {
      window.gapi.load(name, { callback: resolve });
    });
  }

  createPicker() {
    if (this.state.apiLoaded) {
      const { GOOGLE_APP_ID, GDRIVE_PICKER_API_KEY } = Utils.getProcessEnvVars();

      const { textsData: { uiTexts }, onlyFolders, onlyOneSelection, language, onFilesPicked } = this.props;

      const { authToken } = this.state;

      const { google: { picker: { DocsView, ViewId, Feature, Action, PickerBuilder } }, location } = window;

      const defaultView = new DocsView(onlyFolders ? ViewId.FOLDERS : null)
        .setLabel(uiTexts.myDrive)
        .setParent("root")
        .setOwnedByMe(true)
        .setIncludeFolders(true);

      const sharedWithMeView = new DocsView(onlyFolders ? ViewId.FOLDERS : null)
        .setLabel(uiTexts.sharedWithMe)
        .setOwnedByMe(false)
        .setIncludeFolders(true);

      const sharedDrivesView = new DocsView(onlyFolders ? ViewId.FOLDERS : null)
        .setLabel(uiTexts.sharedDrives)
        .setEnableDrives(true)
        .setIncludeFolders(true);

      if (onlyFolders) {
        defaultView.setSelectFolderEnabled(true);
        sharedWithMeView.setSelectFolderEnabled(true);
        sharedDrivesView.setSelectFolderEnabled(true);
      }

      const picker = new PickerBuilder()
        .setAppId(GOOGLE_APP_ID)
        .setDeveloperKey(GDRIVE_PICKER_API_KEY)
        .setOAuthToken(authToken)
        .setLocale(language)
        .setOrigin(location.origin)
        .addView(defaultView)
        .addView(sharedWithMeView)
        .addView(sharedDrivesView)
        .setCallback(({ action, docs }) => {
          if (action === Action.PICKED) onFilesPicked(docs, authToken);
        });

      if (onlyFolders) picker.setTitle(uiTexts.selectFolder);
      else {
        picker
          .setTitle(uiTexts.selectDocuments)
          .setSelectableMimeTypes(this.getFileDropMimeTypes());
      }

      if (!onlyOneSelection) picker.enableFeature(Feature.MULTISELECT_ENABLED);
      picker
        .build()
        .setVisible(true);
    }
  }

  componentDidMount() {
    Promise.all([
      this.loadGoogleApi("auth2"),
      this.loadGoogleApi("picker")
    ]).then(() => {
      this.setState({ apiLoaded: true });
    });
  }

  @bind
  handleClick() {
    (async() => {
      const { GDRIVE_CLIENT_ID } = Utils.getProcessEnvVars();

      if (this.state.authToken) {
        this.createPicker();
      } else {
        try {
          const { offlineAccess = false, exchangeCode } = this.props;

          if (offlineAccess) {
            const authInstance = window.gapi.auth2.init({
              client_id: GDRIVE_CLIENT_ID,
              scope: GoogleDrivePicker.FULL_ACCESS_SCOPE
            });

            const { code } = await authInstance.grantOfflineAccess();

            const authResult = await exchangeCode(code);

            this.handleGoogleAuthResult(authResult);
          } else {
            window.gapi.auth2.authorize(
              {
                client_id: GDRIVE_CLIENT_ID,
                scope: GoogleDrivePicker.READ_ONLY_SCOPE,
                immediate: false
              },
              this.handleGoogleAuthResult
            );
          }
        } catch (error) {
          this.handleGoogleAuthResult(error);
        }
      }
    })();
  }

  @bind
  handleGoogleAuthResult(authResult) {
    const { offlineAccess = false, onlySignIn = false, onSignIn } = this.props;

    const requiredScope = offlineAccess ? GoogleDrivePicker.FULL_ACCESS_SCOPE : GoogleDrivePicker.READ_ONLY_SCOPE;

    if (authResult && authResult.scope && authResult.scope.split(" ").includes(requiredScope) && !authResult.error) {
      this.setState({ authToken: authResult.access_token });
      if (onSignIn) onSignIn(authResult);
      if (!onlySignIn) this.createPicker();
    } else if (!authResult || authResult.error !== "popup_closed_by_user") {
      toast.error(this.props.textsData.errors.unknown);
    }
  }

  render() {
    const { textsData: { uiTexts }, className, text, disabled } = this.props;

    const title = Utils.replaceTextVars(
      uiTexts.uploadDocumentsFromService,
      { service: GoogleDrivePicker.SERVICE_NAME }
    );

    return (
      <div className={className}>
        <Button
          theme="light"
          title={title}
          className={Css.googleDrivePicker}
          disabled={disabled || !this.state.apiLoaded}
          onClick={this.handleClick}>
          <img src={googleDrive} alt={GoogleDrivePicker.SERVICE_NAME} />
          <span>{text || GoogleDrivePicker.SERVICE_NAME}</span>
        </Button>
      </div>
    );
  }
}

export default GoogleDrivePicker;
