import CommonCss from "lib/common/style.module.scss";

import Css from "./style.module.scss";

import { FiFileMinus, FiFilePlus, FiFileText } from "react-icons/fi";
import { connect } from "react-redux";
import { getTextsData } from "selectors/texts";
import DropZone from "react-dropzone";
import React, { PureComponent } from "react";
import classNames from "classnames";

const mapStateToProps = (state) => ({
  textsData: getTextsData(state)
});

@connect(mapStateToProps)
class FileDropZone extends PureComponent {
  static defaultProps = { accept: [] };

  render() {
    const {
      textsData: { uiTexts, messages },
      onlyOne,
      extensions = [],
      accept = [],
      disabled,
      className,
      placeholder,
      onDrop
    } = this.props;

    const acceptedExtensions = extensions.map((extension) => extension.substr(1).toUpperCase()).join(", ");

    return (
      <DropZone accept={accept.length ? accept : null} onDrop={onDrop}>
        {({ isDragActive: dragActive, getRootProps, getInputProps, acceptedFiles, fileRejections }) => {
          const [acceptedFile] = acceptedFiles;

          return (
            <div
              {...getRootProps()}
              disabled={disabled}
              className={classNames(Css.fileDropZone, CommonCss.flexCenter, className)}
              data-active={dragActive ? "" : undefined}
              data-accepted={acceptedFile ? "" : undefined}
              data-rejected={fileRejections.length ? "" : undefined}>
              <input {...getInputProps()} />
              {acceptedFile
                ? (acceptedFiles.length > 1 && !onlyOne
                  ? <div><FiFilePlus /><span>{`${uiTexts.filesSelected}: ${acceptedFiles.length}`}</span></div>
                  : <div><FiFilePlus /><span>{acceptedFile.name}</span></div>)
                : (fileRejections.length
                  ? <div><FiFileMinus /><span>{uiTexts.wrongFileType}</span></div>
                  : <>
                    {placeholder}
                    {!placeholder && <div>
                      <FiFileText />
                      <span>{messages.fileDropDescription}.</span>
                      <br /><br />
                      <span className={Css.supportedFormats}>
                        <span>{uiTexts.supportedFormats}:</span>
                        <br />
                        <b>{acceptedExtensions.length ? acceptedExtensions : uiTexts.any.toLowerCase()}</b>
                      </span>
                    </div>}
                  </>)}
            </div>
          );
        }}
      </DropZone>
    );
  }
}

export default FileDropZone;
