import Css from "./style.module.scss";

import { Button, ButtonGroup } from "shards-react";
import { bind } from "decko";
import React, { PureComponent } from "react";
import classNames from "classnames";

export default class ButtonRadioGroup extends PureComponent {
  constructor(props) {
    super(props);

    const { initialValue, options } = this.props;

    const selectedIndex = initialValue === undefined ? null : options.findIndex(({ value }) => value === initialValue);

    this.state = { selectedIndex };
  }

  @bind
  handleItemClick(event) {
    const { currentTarget: { dataset: { index } } } = event;

    const { options, onChange } = this.props;

    const selectedIndex = +index;

    const selectedItem = options[selectedIndex];

    if (selectedItem.unselectable) onChange(selectedItem.value, event);
    else {
      this.setState({ selectedIndex }, () => {
        onChange(selectedItem.value, event);
      });
    }
  }

  render() {
    const { options, disabled, vertical, size, outline, invalid, theme, className, itemClassName } = this.props;

    return (
      <ButtonGroup
        vertical={vertical}
        size={size}
        disabled={disabled}
        className={classNames(Css.buttonRadioGroup, className)}>
        {options.map(({ value, label, disabled: disabledItem }, index) => {
          return (
            <Button
              key={value}
              data-index={index}
              active={this.props.value ? this.props.value === value : this.state.selectedIndex === index}
              outline={outline}
              theme={invalid ? "danger" : theme}
              disabled={disabledItem}
              className={classNames(Css.button, itemClassName)}
              onClick={this.handleItemClick}>
              {label}
            </Button>
          );
        })}
      </ButtonGroup>
    );
  }
}
